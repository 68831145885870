import { Component, OnInit } from '@angular/core';
import { first, finalize } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

import { TransferDataService } from '../../services/transferData.service';
import { OrderService } from '../../services/order.service';
import { AlertService } from '../../services/alert.service';
import { Order } from 'src/app/models/order';

import { generate_order_pdf } from '../../pdf/genereta_pdf';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-card-transfer-payment',
  templateUrl: './transfer-payment.component.html',
  styleUrls: ['./transfer-payment.component.css']
})
export class TransferPaymentComponent implements OnInit {
  deposit = [];
  paymenteMethod = -1;
  phone = '';
  commission = 0;
  order: Order;
  creditCard = null;

  constructor(
    private transferDataService: TransferDataService,
    private router: Router,
    private orderService: OrderService,
    private alertService: AlertService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
  ) { }

  ngOnInit() {
    const operation = (this.transferDataService.data) ? this.transferDataService.data.operation || null : null;
    this.activatedRoute.queryParams.subscribe(params => {
      const purchaseId = params['c'];
      const error = params['e'];

      if (error) {
        this.alertService.error('order_error');
        this.router.navigate(['app/transfer']);
      } else if (purchaseId) {
        // procura ordem com id recebido e actualiza o seu estado caso necessario
        this.alertService.loading(true);
        const sub = this.orderService.getByPurchaseID(purchaseId)
          .pipe(finalize(() => {
            sub.unsubscribe();
            this.alertService.loading(false);
          }))
          .subscribe(data => this.order = data);
      } else {
        if (operation === 'transfer-payment') {
          this.deposit = this.transferDataService.data.deposit || [];
          this.paymenteMethod = this.transferDataService.data.paymenteMethod || -1;
          this.phone = this.transferDataService.data.phone || '';
          this.commission = this.transferDataService.data.commission || 0;
          this.creditCard = this.transferDataService.data.creditCard || null;

          this.generateOrder();
        } else {
          this.router.navigate(['app/transfer']);
        }
      }
    });
  }

  get orderID(): string {
    let ret = '';
    if (this.order) {
      ret = '' + this.order.purchase_id;
      ret = ret.slice(0, 3) + ' ' + ret.slice(3, 6) + ' ' + ret.slice(6, 9);
    }
    return ret;
  }

  generateOrder() {
    this.alertService.loading(true);

    let it;
    let order = { amount: 0, type: this.transferDataService.data.paymenteMethod, cards: [], phone: this.phone, creditCard_id: this.creditCard };

    for (it = 0; it < this.deposit.length; it++) {
      order.amount += this.deposit[it].value;
      order.cards.push({
        card_id: this.deposit[it].card.id,
        amount: this.deposit[it].value
      });
    }

    const sub = this.orderService.create(order)
      .pipe(finalize(() => {
        sub.unsubscribe();
        this.alertService.loading(false);
      }))
      .subscribe(
        data => {
          switch (data.type) {
            case 7:
              // pfs credit order
              console.log(data);
              break;
            case 5:
              // utrust
              if ('redirectUrl' in data) {
                window.open((data as any).redirectUrl, "_blank");
              }
              this.order = data;
              break;
            case 4:
              // maymoid
              const okUrl = window.location.origin + '/app/transferpayment?c=' + data.purchase_id;
              const koUrl = window.location.origin + '/app/transferpayment?e=true';
              window.location.href = environment.mymoid + '/tpv/?p=' + (data.phone || '') + '&urlok=' + okUrl + '&urlko=' + koUrl; // phone = shortcode
              break;
            default:
              this.order = data
              break;
          }
        },
        error => {
          // console.log(error)
          if ('extra' in error) {
            this.alertService.errorTransfer('transfer_error_v2', error.extra, error.error);
          } else if (error.error === 'invalid cards') {
            this.alertService.error('order_error');
          } else {
            this.alertService.error(error.error); //'it was not possible execute your order'
          }
          this.router.navigate(['app/transfer']);
        },
      );
  }

  // da para passar para componente
  exportPdf() {
    this.alertService.loading(true);
    generate_order_pdf(this.order, this.userService.authenticated_user);
    this.alertService.loading(false);
  }

}
