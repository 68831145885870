export class AuthToken {
    jwt: string;
    exp: number;

    constructor(obj?: any) {
        this.jwt = '';
        this.exp = -1;

        Object.assign(this, obj);
    }

    get expTime(): Date {
        if (this.exp > 0) {
            return new Date(this.exp * 1000);
        }

        return null;
    }

    isValid() {
        if (this.exp > 0) {
            const expDate = new Date(this.exp * 1000);
            const now = new Date();

            if (expDate.getTime() <= now.getTime()) {
                return false;
            }
        }

        return this.jwt !== '';
    }

    timeUntilExpires(): number {
        if (this.exp > 0) {
            const expDate = new Date(this.exp * 1000);
            const now = new Date();
            const remainingTime = expDate.getTime() - now.getTime();

            return (remainingTime < 0) ? 0 : remainingTime;
        }

        return 0;
    }
}