import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';

// layouts
import { AuthLayoutComponent } from './_layout/auth-layout/auth-layout.component';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { AppCardLayoutComponent } from './_layout/app-layout/card-layout/card-layout.component';

import { LoginComponent } from './intro/login/login.component';
import { RecoveryPasswordComponent } from '../app/intro/recovery-password/recovery-password.component';
import { CreateAccountComponent } from './intro/create-account/create-account.component';
import { CardComponent } from '../app/card/card.component';
import { TransactionsComponent } from '../app/card/transactions/transactions.component';
// import { InformationComponent } from '../app/user/information/information.component';
import { TransferComponent } from '../app/transfer/transfer.component';
import { TransferPaymentComponent } from '../app/transfer/transfer-payment/transfer-payment.component';
import { OrdersComponent } from '../app/orders/orders.component';
import { VerifyEmailComponent } from '../app/intro/verify-email/verify-email.component';
import { AboutCardRegisterComponent } from '../app/intro/about-card-register/about-card-register.component';
import { CardProfileComponent } from './card/card-profile/card-profile.component';
import { AccessInformationComponent } from './user/access-information/access-information.component';
import { ContactSupportComponent } from './intro/contact-support/contact-support.component';
import { ActivateCardComponent } from './intro/activate-card/activate-card.component';
import { BuyCardComponent } from './intro/buy-card/buy-card.component';
import { CardUpdatePremiumComponent } from './card/card-update-premium/card-update-premium.component';

const routes: Routes = [
  {
    path: 'app',
    canActivate: [AuthGuard],
    component: AppLayoutComponent,
    children: [
      {
        path: 'cards',
        component: AppCardLayoutComponent,
        children: [
          { path: 'cardprofile', component: CardProfileComponent },
          { path: 'carddetails', component: CardComponent },
          { path: 'transactions', component: TransactionsComponent },
          { path: 'accessinformation', component: AccessInformationComponent },
          { path: 'cardupdatepremium', component: CardUpdatePremiumComponent }
        ]
      },
      { path: 'transfer', component: TransferComponent },
      { path: 'transferpayment', component: TransferPaymentComponent },
      // { path: 'userinfo', component: InformationComponent },
      { path: 'orders', component: OrdersComponent },
    ]
  },

  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'recoverypassword', component: RecoveryPasswordComponent },
      { path: 'requestpassword', redirectTo: 'createaccount' },
      { path: 'createaccount', component: CreateAccountComponent },
      { path: 'verifyemail', component: VerifyEmailComponent },
      { path: 'contactsupport', component: ContactSupportComponent },
      { path: 'aboutcardregister', component: AboutCardRegisterComponent },
      { path: 'activatecard', component: ActivateCardComponent },
      { path: 'buycard', component: BuyCardComponent },
      { path: '', redirectTo: 'login', pathMatch: 'full' },
    ]
  },

  // otherwise redirect to home
  // { path: '**', redirectTo: 'dashboard' }
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
