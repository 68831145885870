<form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="my-form-input">
        <label for="email">{{ 'email' | translate }} <span class="red">*</span></label>
        <input id="email" type="text" placeholder="{{ 'email' | translate }}" formControlName="email" required>
    </div>

    <div class="my-form-input">
        <label for="password">{{ 'password' | translate }} <span class="red">*</span></label>
        <input id="password" type="password" placeholder="{{ 'password' | translate }}" formControlName="password"
            required>
    </div>

    <a class="forgot-link" routerLink='/recoverypassword'>{{ 'forgot_password' | translate }}</a>

    <div class="btn-container">
        <button class="app-button app-button-login" mat-raised-button color="warn">Login</button>
        <div style="width: 15px;"></div>
        <a routerLink='/createaccount' class="app-button app-button-create-account" mat-raised-button
            color="warn">{{ 'create_account' | translate }}</a>
    </div>

    <div class="pdf-link-container">
        <a class="forgot-link pdf-link" target="_blank" href='{{ pdfLink() }}'>
            {{ 'create_account_helper' | translate }}
        </a>
    </div>
</form>