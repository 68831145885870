export default {
  '4_digits_card_number': 'last 4 digits of your card ',
  '20 001 to 50 000 EUR': '20,001€ to 50,000€',
  '50 001 to 75 000 EUR': '50,001€ to 75,000€',
  '050_error_message': 'Your account is being reviewed, please contact support for more information.',
  '75 001 to 100 000 EUR': '75,001€ to 100,000€',
  '100 001 to 250 000 EUR': '100,001€ to 250,000€',
  '250 001 to 500 000 EUR': '250,001€ to 500,000€',
  '500 001 to 1M EUR': '500,001€ to 1,000,000€',
  '501 to 1000 EUR': '501€ to 1,000€',
  '1001 to 1500 EUR': '1,001€ to 1,500€',
  '1501 to 2000 EUR': '1,501€ to 2,000€',
  '2001 to 3000 EUR': '2,001€ to 3,000€',
  'about-register-card_1': 'Activate your SPARK card by sending an SMS to one of the numbers below with the word PIN and the last 8 digits of your card.',
  'about-register-card_2': 'Portugal: +351 927 944 818',
  'about-register-card_2-1': 'Spain: +34 600 67 07 07',
  'about-register-card_3': 'You will receive an SMS confirming the activation of your card and your PIN code, which will be used at any ATM or purchase.',
  'access_information': 'Access information',
  'account_will_be_removed': 'If you do not confirm your email and activate your account, it will be deleted after 24 hours.',
  'activate card': 'Activate card',
  'active': 'Active',
  'active the card': 'Activate your card before the registration!',
  'add': 'Add',
  'add_new_account': '+ add new account',
  'add_new_card': 'Add New Card',
  'address': 'Address',
  'Agriculture': 'Agriculture',
  'already registered': 'This card is already registered.',
  'amount': 'Amount',
  'amount_to_be_loaded': 'Total amount to be loaded',
  'api_internal_error': 'An error has occurred. Please contact support.',
  'approved': 'Aproved',
  'Arts / Culture / Sport': 'Arts / Culture / Sport',
  'back_desc': 'Spanish DNI, EU National ID, EU Driving License',
  'back_doc': 'Back of your ID document',
  'back_to_login': 'Back to Login',
  'back_to_step_1': 'Back to previous step',
  'bad file': 'Corrupted file.',
  'Bad Request': 'An error has occurred.',
  'Banking / Insurance / Finance / Auditing': 'Banking / Insurance / Finance / Auditing',
  'buy': 'Buy',
  'buy card': 'Buy card',
  'cancel': 'Cancel',
  // 'cant save on external database': 'An internal error was occurred. Please contact the support.',
  'cant save on external database': 'Server temporarily unavailable. Please contact the support.',
  'card activated with success': 'The card was active.',
  'card blocked by api': 'This card is blocked. Please contact the support.',
  'card id or card number not exist': 'Some of the data entered is not correct, try again.',
  'card not activated': 'This card is not active',
  'card number': 'Card Number',
  'Card Purpose': 'Card Purpose',
  'card_activation_title': 'Como activar cartão',
  'card_add_success': 'A new card was added.',
  'card_already_registered': 'The card is already registered on the platform, associated with another account, you must log in to the account with the email:',
  'card_credentials': 'Create individual card access',
  'card_details': 'Card Information',
  'card_holder': 'SPARK ID',
  //novos2
  'card_number': 'Nombre de la tarjeta',
  'cert_doc': 'Proof of address',
  'cert_doc_desc': 'Certificate of Empadronamiento, Bank Statement less than 3 months old, Utility Bill (telephone, gas, electricity, water) less than 3 months old',
  'choose_amount': 'Select an amount',
  'choose_payment_type': 'Choose a payment method',
  'city': 'City',
  'close': 'Close',
  'confirm': 'Confirm',
  'confirm_password': 'Confirm Password',
  'Construction / Publicworks': 'Construction / Publicworks',
  'consult_pin': 'Check PIN',
  'contact support': 'Please contact support.',
  'continue with registration': 'Continue with registration',
  'country': 'Country',
  'Craftwork / Trade': 'Craftwork / Trade',
  'create': 'Create',
  'create_account': 'Create Account',
  'create_account_helper': 'Client Area Guide',
  'created with incomplete data': 'The account was been registered. However, the system didn\'t download all your transactions list.',
  'credentials': 'Login credentials',
  'credit_card': 'Credit Card',
  'credit_card_processing_message': 'Credit card process might take 10 to 15 minutes.',
  'currency': 'Currency',
  'detalhe_cartao': 'Card Details',
  'Director / Owner': 'Director / Owner',
  'dob': 'Date of Birth',
  'documents': 'Documents',
  'edit': 'Edit',
  'Education': 'Education',
  'email': 'Email',
  'email / card_holder': 'Email or SPARK ID',
  'email not the same previous saved': 'This email are not the same as the email registered on this card details.',
  'email_already_registered': 'An account already exists with this email. Please login to enter.',
  'email_enviado': 'The email has been sent.',
  'email_exists': 'An account already exists with this email. Please login to enter.',
  'email_not_same_as_in_card': 'The card is already registered with a different email, use the same email or contact our support.',
  'email_verification_desc': 'You need to verify your email in order to continue.',
  'email_verification_title': 'Verify Email',
  'Employee / Worker': 'Employee / Worker',
  'Employment Status': 'Employment Status',
  'end_date': 'End date',
  'entity': 'Entity',
  'erro': 'error',
  'error': 'Error',
  'Estate': 'Estate',
  'Executive': 'Executive',
  'export': 'Export',
  'export_csv': 'Export csv',
  'export_pdf': 'Export PDF',
  'fee': 'Fee',
  'file in review': 'This file is in review.',
  'file was saved': 'This file was successfully saved.',
  'filter': 'Filter',
  'firstname': 'First Name',
  'Food industry / Work from home / Hospitality / Tourism': 'Food industry / Work from home / Hospitality / Tourism',
  'forgot_password': 'Forgot your password?',
  'form_error': 'The form contains errors',
  'four_digits': 'last 4 digits of your card number',
  'front_doc': 'Front of your ID document',
  'front_doc_desc': 'Spanish DNI, EU National ID, EU Driving License, Passport',
  // novas
  'fullname': 'Full Name',
  'Gamble or bet online': 'Gamble or bet online',
  'help': 'Document Guide',
  'hide': 'Hide',
  'history': 'Transaction history',
  'info_about_card': 'Card information',
  'invalid address': 'Invalid address.',
  'invalid card': 'Invalid card.',
  'invalid card user': 'It was not possible to add the card to user.',
  'invalid code': 'Invalid verification code',
  'invalid email': 'This email is not valid or is already taken.',
  'invalid kyc': 'Your KYC is incomplete. Please complete your KYC to upgrade your card to SPARK Premium.',
  'invalid order': 'Invalid order.',
  'invalid params': 'Invalid parameters',
  'invalid user': 'Invalid user',
  'invalid_dob': 'The date of birth is invalid. The minimum required age is 18 years old.',
  'invalid_form_dates': 'The dates are not correct.',
  'invalid_phone': 'Invalid phone number',
  'it was not possible add cards to order': 'It was not possible add the card to order.',
  'it was not possible change the email': 'An error occured when updating your email.',
  'it was not possible execute your order': 'it was not possible to finish your order.',
  'it was not possible save file': 'It was not possible save the file',
  'kyc_level': 'SPARK Level',
  // 'kyc_not_complete_message': 'Your KYC is incomplete. Please complete your KYC to upgrade your card to SPARK Premium.',
  'kyc_not_complete_message': 'Your card loading is limited to 2,500€ per year.',
  'last login': 'Last login',
  'lastname': 'Surname',
  'LESS THAN 20 000 EUR': 'Less than 20,000€',
  'LESS THAN 500 EUR': 'Less than 500€',
  'limit orders': 'You reached your daily charging limit. You are able to make a maximum of 3 loadings a day.',
  'load_card': 'Load Card',
  'load_card_order': 'Load',
  'loading_fee': 'Loading fee',
  'lock': 'Lock',
  'lock_card': 'Lock Card',
  'lock_card_1_msg': 'Click the button below to temporarily block your card. This will prevent your card from being used in any way.',
  'lock_card_2_msg': 'You will be able to unblock your card at any time.',
  'locked': 'Locked',
  'login': 'login',
  'logout': 'Logout',
  'lost_card': 'Report card as lost',
  'lost_card_msg': 'Click the button below to permanently block your card in case it was lost or stolen. Once you have blocked your card contact customer service in order to obtain a replacement card.',
  'Manager': 'Manager',
  'Manufacturing / Maintenance': 'Manufacturing / Maintenance',
  'Medical / Paramedical': 'Medical / Paramedical',
  'middlename': 'Middlename',
  'Monthly Income': 'Monthly Income',
  'MORE THAN 1M EUR': 'More than 1,000,000€',
  'MORE THAN 3000 EUR': 'More than 3,000€',
  'my_cards': 'My Cards',
  'name': 'Name',
  'nationaliry': 'Nationality',
  'new_account_step_1': 'New account step 1',
  'new_account_step_2': 'New account step 2',
  'new_account_step_3': 'New account step 3',
  'new_account_step_4': 'New account step 4',
  'new_to_upload_1_file': 'You need to upload at least 1 file',
  'newsletter': 'Newsletter',
  'next': 'Next',
  // new form
  'NIF / NIE': 'NIF / NIE',
  'no': 'No',
  'not_active': 'Not Active',
  'Occupation': 'Occupation',
  'optional_company_name': 'Company Name (opcional)',
  'order was created but not saved on the system': 'An error occurred when creating an order in the system. Please contact support.',
  'order_error': "it was not possible finish your order. Please make sure the amount of selected cards didn't reach the maximum allowed for your profile.",
  'order_id': 'Order ID',
  'order_list_label': 'Transaction Value / Balance',
  'other_amount': 'Other amount',
  'other_amount2': 'Insert other amount',
  'password': 'Password',
  'password_chards': 'Your password must be at least 8 characters including: lowercase, UPPERCASE, numbers, and symbols',
  'password_min_length': 'The password must have more than 6 characters.',
  'pay': 'Pay',
  'Pay in store': 'Pay in store',
  'Pay my bills': 'Pay my bills',
  'pdf_comissao': 'Commission',
  // PDF
  'pdf_data': 'Data',
  'pdf_entidade': 'Entity',
  'pdf_mensagem': 'Message',
  'pdf_montante': 'Amount',
  'pdf_ordem': 'Order',
  'pdf_referencia': 'Reference',
  'pdf_referencia e payshop': 'Reference / Payshop',
  'pdf_resultado': 'Status',
  'pdf_saldo': 'Balance',
  'pdf_Spark ID': 'SPARK ID',
  'pdf_tipo': 'Type',
  'pendente': 'pending',
  'Personal Expenses': 'Personal expenses',
  'Personal expenses': 'Personal expenses',
  'personal_information': 'Personal Information',
  'phone': 'Phone',
  'Politician / Elected Member of Parliament': 'Politician / Elected Member of Parliament',
  'privacy_policy': 'Privacy Policy',
  'profile': 'Profile',
  'profiles': 'Profiles',
  'Public servant / Police / Military': 'Public servant / Police / Military',
  'Receipt of income from business': 'Receipt of income from business',
  'Receive my salary': 'Receive my salary',
  'Receive my social welfare payments': 'Receive my social welfare payments',
  'recover': 'Recover',
  'recovery_password': 'Password recovery',
  'recovery_password_error': 'It was not possible to update your password,',
  'recovery_password_success': 'Your password was updated.',
  'reference': 'Reference',
  'removecard': 'Remove card',
  'renew_session': 'For security reasons your session will expire in 20 seconds. Do you want to renew it?',
  'requesT_recovery_password_error': 'It was not possible to complete your request,',
  'requesT_recovery_password_success': 'Your requested was successful. Please check your email and follow the instructions to recover your password',
  'resend_email': 'Resend email',
  'resume': 'Resume',
  'Retiree': 'Retiree',
  'save': 'Save',
  'Save money': 'Save money',
  'search': 'Search',
  'secundary_id': 'Card ID number',
  'Self employed': 'Self employed',
  'Send or Receive Money transfer': 'Send or Receive Money transfer',
  'send_pin_msg': 'An SMS with the PIN will be sended to the card\'s registered phone number',
  'send_pin_msg': 'An SMS with the PIN will be sended to the card\'s registered phone number',
  'send_pin_success': 'Your PIN was sent to the card\'s registered phone number.',
  'Services / IT': 'Services / IT',
  'session_expired': 'For security reasons your session has expired.',
  'show': 'Show',
  'Social Security / NGO': 'Social Security / NGO',
  'Spain': 'Spain',
  'special-error-1': 'An error has occurred, please contact our support.',
  'special-error-2': 'Customer support available from:',
  'special-error-3': 'Monday to Friday',
  'special-error-4': '9:00 AM to 6:00 PM CET.',
  'special-error-5': '(Closed on national holidays)',
  'special-error-6': 'Email: info@tarjetaspark.es',
  'special-error-7': 'Spain:',
  'special-error-8': 'Portugal:',
  'special-error-create-account-1': 'Dear customer, we detected a problem with your SPARK card registration data.',
  'special-error-create-account-2': 'For this reason, we request that you send us a copy of your personal identification document, as well as proof of residence with less than 3 months to the email: info@cartaospark.pt.',
  'special-error-create-account-3': 'As soon as we receive the requested documents, we will proceed to validate and validate your account. If you do not send the documents to us within the next 7 days, the card will be blocked.',
  'special-error-create-account-4-1': 'IDENTIFICATION DOCUMENT: ',
  'special-error-create-account-4-2': 'Passport or citizen card issued by a European Union country, driving license issued by a European Union country.',
  'special-error-create-account-5-1': 'PROOF OF ADDRESS: ',
  'special-error-create-account-5-2': 'Invoices for public services such as (telephone, gas, electricity, water) issued with less than 3 months or bank statement with less than 3 months.',
  'special-error-create-account-6': 'Thank you for choosing to join the SPARK Card and we look forward to your documents.',
  'start_date': 'Begin date',
  'state': 'State',
  'statement date': 'Statement Date',
  'stolen_card': 'Report card as stolen',
  'stolen_card_msg': 'Click the button below to permanently block your card in case it was lost or stolen. Once you have blocked your card contact customer service in order to obtain a replacement card.',
  'Student': 'Student',
  'submit': 'Submit',
  'sucesso': 'success',
  'sync_transactions_error': 'Transaction synchronization with your card failed.',
  'terms_and_conditions': 'Terms and Conditions',
  'this field are invalid': 'This field is not correctly filled out.',
  'total': 'Total',
  // server errors
  'total number of cards exceeded': 'Total number of cards exceeded.',
  'total_amount': 'Total amount',
  'tranfer': 'Transaction',
  'transactions': 'Transactions',
  'transfer_error_v2': 'An error occurred while trying to load the card. Only [AMOUNT] € can be charged to the [CARID] card.',
  'Travel Expenses': 'Travel expenses',
  'Travel expenses': 'Travel expenses',
  'Unemployed': 'Unemployed',
  'Unknown Error': 'An error has occurred. Please contact support.',
  'unlock': 'Unlock',
  'unlock_card': 'Unlock Card',
  'unlock_msg': 'Click the button below to reactivate your card\'s use.',
  'update_card_status_success': 'The card status was updated successfully.',
  'updated_at': 'Updated at',
  'updated_card_name_error': 'It was not possible updated your card name.',
  'updated_card_name_success': 'The name of your card was updated successfully.',
  'updated_password_error': 'It was not possible to update your password.',
  'updated_password_success': 'Your password was updated.',
  'upload_error': 'Upload documents failed.',
  'upload_success': 'We received your documents and will contact you between 24-72 hrs.',
  'validate_date': 'Expiry date',
  'Valor / Comissão': 'Amount / Commission',
  'verification_code': 'Verification code',
  // 'verify kyc or parameters': 'Please check the profile section and make sure everything is complete.',
  'verify kyc or parameters': 'The maximum amount authorized for each credit card loading is € 250, with a maximum of 3 recharges per day.',
  'verify_email': 'Verify Email',
  'waiting': 'Waiting',
  'wrong_credentials': 'Invalid email or password.',
  'wrong_password_and_confirm_password': 'The password and confirm password fields are not the same',
  'yes': 'Yes',
  'you will receive 1 pin on phone': 'In a moment you will receive the pin on the phone number placed.',
  'YYYY-MM-DD': 'YYYY-MM-DD',
  'zipcode': 'Zip Code',
  'upload_document_message_1': 'Estimado cliente,',
  'upload_document_message_2': 'te comunicamos que, en cumplimiento con la normativa legal, nos encontramos mejorando nuestra plataforma KYC para subir tu SPARK a nivel premium. Por ello, lamentamos informarte de que no podemos verificar documentos en este momento.',
  'upload_document_message_3': 'Muy pronto podrás disfrutar de un mejor servicio que permitirá la verificación de documentos en un período muy corto de tiempo. Agradecemos tu paciencia mientras actualizamos el sistema y lamentamos los inconvenientes que esta circunstancia pueda ocasionarte. ',
  'upload_document_message_4': 'Equipo SPARK',
  'premium_level': 'Premium Level',
  'update_to_premium': 'Update to Premium',
  'document_number': 'Document number',
  'document_type': 'Citizen ID Card',
  'document_expiry_date': 'Expiration date',
  'bi': 'Citizen ID Card',
  'passport': 'Passport',
  'driving_license': 'Driving license',
  'start_validation_process': 'Start validation process',
  'qrcode_form_title': 'To upgrade to Premium the process is:',
  'qrcode_form_desc1': '1 - Insert your citizien document information',
  'qrcode_form_desc2': '2 - Scan QR Code with your mobile phone',
  'qrcode_form_desc3': '3 - Run a validation process of taking picture of document, selfie and move your face to the camera',
  'qrcode_form_desc4': '4 - Finish the process and wait some minutes for the validation',
  'Temporary employee': 'Temporary employee',
  'Not applicable': 'Not applicable',
  'Cryptocurrency': 'Cryptocurrency',
  'For use abroad': 'For use abroad',
  'Gaming or Betting on-line': 'Gaming or Betting on-line',
  'Point of Sale purchases': 'Point of Sale purchases',
  'Receive income from my business activity': 'Receive income from my business activity',
  'Savings': 'Savings',
  'Send or receive money transfers': 'Send or receive money transfers',
  'Business Expenses': 'Business Expenses',
  'Corporate Account Less than 50K': 'Corporate Account Less than 50000€',
  'Corporate Account 50K - 150K': 'Corporate Account 50K - 150000€',
  'Corporate Account Above 150K': 'Corporate Account Above 150000€',
  'countryOfIssuance': 'Country Of Issuance',
  'qr_minimum_requirements_title': 'Minimum connection requirements:',
  'qr_minimum_requirements_desc1': 'Webcam and microphone.',
  'qr_minimum_requirements_desc2': 'Wifi or 4G/5G Internet connection.',
  'qr_minimum_requirements_desc3': 'Browser: Chrome, Firefox, Edge, Safari.',
  'qr_minimum_requirements_desc4': 'Close other applications.',
  'upgrade_premium_error': 'Please review all personal information entered. If you have any problems, send an email to info@tarjetaspark.es.',
  'upgrade_premium_error_050': 'Your account is under review. If you have any problems, send an email to info@tarjetaspark.es.',
  'WAITING_FOR_REVIEW': 'Your account is under review. If you have any problems, send an email to info@tarjetaspark.es.',
  'REVIEW_NOT_APPROVED': 'Your account was not validated due to incorrect or inconsistent data. If you have any problems, send an email to info@tarjetaspark.es.',
  'AUTO_INVALID': 'Your account was not validated due to incorrect or inconsistent data. If you have any problems, send an email to info@tarjetaspark.es.',
  'ERROR': 'Your account was not validated due to incorrect or inconsistent data. If you have any problems, send an email to info@tarjetaspark.es.',
  'AUTO_INVALID_NAME': 'The name entered could not be validated. If you have any problems, send an email to info@tarjetaspark.es.',
  'AUTO_INVALID_DOB': 'The entered date of birth could not be validated. If you have any problems, send an email to info@tarjetaspark.es.',
  'AUTO_INVALID_NAMEDOB': 'It was not possible to validate the name and date of birth entered. If you have any problems, send an email to info@tarjetaspark.es.',
  'NO_ID_UPLOADED': 'The necessary data to complete the KYC process was not submitted. If you have any problems, send an email to info@tarjetaspark.es.',
  'MODAL_CODE_TEXT': 'Please check your email box with the validation code, if you do not receive it, check your SPAM.',
  'Account is currently under KYC review': 'Account is currently under KYC review',
  'KYC is approved': 'Account approved',
  'User needs to resubmit perhaps trying from a different device or ID document or different lighting area': 'User needs to resubmit perhaps trying from a different device or ID document or different lighting area',
  'User has reached the limit of cards per this ID document': 'User has reached the limit of cards per this ID document.',
  'Not returned for video KYC': 'Not returned for video KYC.',
  'Not applicable for video KYC': 'Not applicable for video KYC.',
  'User needs to finalise and complete an upload': 'User needs to finalise and complete an upload.',
  'citizien document information': 'Citizien document information',
  'here': 'here',
}
