import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupCodeComponent } from 'src/app/modals/popup-code/popup-code.component';
import { AlertService } from 'src/app/services/alert.service';
import { finalize } from 'rxjs/operators';
import { isEmpty as _isEmpty } from 'lodash';

const POPUP_CODE_WIDTH = '400px';

@Component({
    selector: 'app-access-information',
    templateUrl: './access-information.component.html',
    styleUrls: ['./access-information.component.css']
})
export class AccessInformationComponent implements OnInit {
    @ViewChild('inputEmail') inputEmail: ElementRef;
    @ViewChild('inputPassword') inputPassword: ElementRef;

    editingEmail = false;
    editingPassword = false;
    user = null;

    password = '';
    passwordStatus = 0;

    constructor(
        private userService: UserService,
        private alertService: AlertService,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        this.user = this.userService.authenticated_user;
    }

    // ------
    // events
    // ------

    verifyPassword(): void {
        if (_isEmpty(this.password)) {
            this.passwordStatus = 0;
        } else if (!this.userService.isStrValidAsPassword(this.password)) {
            this.passwordStatus = 1;
        } else {
            this.passwordStatus = 2;
        }
    }

    onInputKeydown(event, type): void {
        if (event.key === 'Enter') {
            if (type == 'email') {
                this.onEmailOperationBtnPress();
            } else {
                this.onPasswordOperationBtnPress();
            }
        } else {
            this.verifyPassword();
        }
    }

    onEmailOperationBtnPress(): void {
        if (this.editingEmail) {
            const email = (this.inputEmail.nativeElement) ? this.inputEmail.nativeElement.value : '';

            if (!_isEmpty(email) && this.user.username !== email) {
                const dialogRef = this.dialog.open(PopupCodeComponent, {
                    width: POPUP_CODE_WIDTH,
                });

                dialogRef.afterClosed().subscribe(result => {
                    this.editingEmail = false;
                    if (result) {
                        this.alertService.loading(true);

                        const sub = this.userService.update_email(email, result)
                            .pipe(finalize(() => {
                                this.alertService.loading(false);
                                this.editingEmail = false;
                                sub.unsubscribe();
                            }))
                            .subscribe(
                                data => this.user.username = email,
                                error => this.alertService.error(error.error),
                            );
                    }
                });
            } else {
                this.editingEmail = false;
            }
        } else {
            this.editingEmail = true;
            setTimeout(() => this.inputEmail.nativeElement.focus(), 500);
        }
    }

    onPasswordOperationBtnPress(): void {
        if (this.editingPassword) {
            if (!_isEmpty(this.password) && this.passwordStatus == 2) {
                const dialogRef = this.dialog.open(PopupCodeComponent, {
                    width: POPUP_CODE_WIDTH,
                });

                dialogRef.afterClosed().subscribe(result => {
                    if (result) {
                        this.alertService.loading(true);

                        const sub = this.userService.update_password(this.password, result)
                            .pipe(finalize(() => {
                                this.alertService.loading(false);
                                this.editingEmail = false;
                                sub.unsubscribe();
                            }))
                            .subscribe(
                                data => { },
                                error => this.alertService.error(error.error),
                            );
                    }

                    this.editingPassword = false;
                    this.password = '';
                });
            } else {
                this.password = '';
                this.editingPassword = false;
            }
        } else {
            this.editingPassword = true;
            setTimeout(() => this.inputPassword.nativeElement.focus(), 500);
        }
    }

}