<div *ngIf="showPopup == true" class="container-background">
    <div class="click-container" (click)="onClosePopup()"></div>

    <div class="containerV1">
        <div class="contentV1">
            <div class="title">
                <div *ngIf="op == 'changepin'">{{ 'consult_pin' | translate }}</div>
                <div *ngIf="op == 'lockorunlockcard' && (card.status == 'no_active' || card.status == 'unlocked')">
                    {{ 'lock' | translate }}</div>
                <div *ngIf="op == 'lockorunlockcard' && card.status !== 'no_active' && card.status !== 'unlocked'">
                    {{ 'unlock' | translate }}</div>
                <div *ngIf="op == 'lost'">{{ 'lost_card' | translate }}</div>
                <div *ngIf="op == 'stolen'">{{ 'stolen_card' | translate }}</div>
                <div *ngIf="op == 'addcard'">{{ 'add_new_card' | translate }}</div>
                <div *ngIf="op == 'removecard'">{{ 'removecard' | translate }}</div>
            </div>

            <div class="card-container" *ngIf="op != 'addcard'">
                <app-box-card [card]="card"></app-box-card>
            </div>

            <div class="card-container-add" *ngIf="op == 'addcard'">
                <app-card-popup-add-card (onFinish)="onClosePopup()"></app-card-popup-add-card>
            </div>

            <div class="description">
                <div *ngIf="op == 'changepin'">
                    {{ 'send_pin_msg' | translate }} <b>{{ card.phone }}</b>
                    <!-- O aviso-PIN será enviado para o dispositivo móvel que está vinculado a este cartão.
                    Você deve usá-lo para fazer compras nas lojas ou retirar dinheiro em caixas eletrônicos. -->
                </div>
                <div *ngIf="op == 'lockorunlockcard' && (card.status == 'no_active' || card.status == 'unlocked')"
                    style="color: #000000">
                    {{ 'lock_card_1_msg' | translate }}<br>
                    <br>
                    <b>{{ 'lock_card_2_msg' | translate }}</b>
                </div>
                <div *ngIf="op == 'lockorunlockcard' && card.status !== 'no_active' && card.status !== 'unlocked'"
                    style="color: #000000">
                    {{ 'unlock_msg' | translate }}
                </div>
                <div *ngIf="op == 'lost'">
                    {{ 'lost_card_msg' | translate }}
                </div>
                <div *ngIf="op == 'stolen'">
                    {{ 'stolen_card_msg' | translate }}
                </div>
            </div>
        </div>

        <div class="btn-containerV1">
            <button class="btn-cancel" mat-raised-button color="warn"
                (click)="onClosePopup()">{{ 'cancel' | translate }}</button>
            <button class="btn-confirm" mat-raised-button color="warn"
                (click)="onConfirm()">{{ 'confirm' | translate }}</button>
        </div>
    </div>
</div>