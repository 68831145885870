import { CardInfo } from './card_info';
import { CardPriv } from './card_priv';

export class Card {
    id: string;
    basecurrency: string;
    cardnumber: string;
    style: string;
    tier: string;
    type: string;
    status: string;
    phonecardNumber: string;
    expirationDate: string;
    name: string;
    availBal: number = 0;
    phone: string;
    statusdownloadtranscations: number;
    lastdownloadtransactions: string;
    fraud: boolean;
    lock_kyc: boolean;
    kyc_error_code: string;
    kyc_redirect_url: string;
    kyc_error_message: string;
    kyc_response_status: string;
    
    //  V0
    info: CardInfo;
    priv: CardPriv;

    constructor(obj?: any) {
        this.id = '';
        this.fraud = false;

        Object.assign(this, obj);

        if (obj) {
            this.id = '' + obj.id;

            if ('info' in obj) {
                this.info = new CardInfo(obj.info);
            }

            if ('priv' in obj) {
                this.priv = new CardPriv(obj.priv);
            }
        }
    }

    get idFormated() {
        return this.id;//(this.id.match(/.{1,3}/g) || []).join(' ');
    }

    get tierAsNumber(): number {
        try {
            return parseInt(this.tier);
        } catch (er) {
            return 0;
        }
    }

    isExpired(): boolean {
        const actualYear = (new Date()).getFullYear();
        const expYear = toInteger(this.expirationDate);
        return actualYear >= expYear;
    }
}

const toInteger = (str) => {
    try {
        return parseInt(str);
    } catch (err) {
        return 2100;
    }
}