<ng-container *ngIf="!isSpecialType()">
    <div *ngIf="fixedMessage.visible" class="container-fixed">
        <div class="sub-container-fixed {{ (fixedMessage.link) ? 'link' : '' }}" (click)="jumpToLink()">
            <div class="tag-img">
                <img src="assets//images//warning.png">
            </div>
            <span class="text">{{ fixedMessage.text | translate }}</span>
        </div>
    </div>

    <div *ngIf="message" class="container">
        <div class="a-container a-{{ message.type }}">
            <div class="tag-img">
                <img *ngIf="message.type == 'success'" src="assets//images//success.png">
                <img *ngIf="message.type == 'error'" src="assets//images//error.png">
                <img *ngIf="message.type == 'warning'" src="assets//images//warning.png">
            </div>
            <div class="text">
                {{ displayMessage(message.text) }}
                {{ message.extra || '' }}
            </div>
            <div class="close" (click)="onClosePress()">X</div>
        </div>
    </div>
</ng-container>

<div class="loading-container" *ngIf="loadingVisible">
    <mat-spinner class="mat-spinner-color" color="warn"></mat-spinner>
</div>

<ng-container *ngIf="isSpecialType()">
    <div class="container-fixed-type-1">
        <div class="container-fixed-type-1-background"></div>
        <div class="container-fixed-type-1-container">
            <ng-container *ngIf="specialType == 'email_not_same_as_in_card'">
                <div class="container-fixed-type-1-text-title container-fixed-type-1-space">
                    {{ 'email_not_same_as_in_card' | translate }}
                </div>
            </ng-container>
            <ng-container *ngIf="specialType == ''">
                <div class="container-fixed-type-1-text-title container-fixed-type-1-space">
                    {{ 'special-error-1' | translate }}
                </div>
                <div class="container-fixed-type-1-text-title">
                    {{ 'special-error-2' | translate }}
                </div>
                <div>
                    {{ 'special-error-3' | translate }}
                </div>
                <div>
                    {{ 'special-error-4' | translate }}
                </div>
                <div>
                    {{ 'special-error-5' | translate }}
                </div>
                <div class="container-fixed-type-1-space">
                    {{ 'special-error-6' | translate }}
                </div>
            </ng-container>
            <div class="container-fixed-type-1-container-contacts">
                <div>
                    <span class="container-fixed-type-1-text-title">
                        {{ 'special-error-7' | translate }}
                    </span>
                    <br>
                    +34 91 29 39 789
                </div>
                <div>
                    <span class="container-fixed-type-1-text-title">
                        {{ 'special-error-8' | translate }}
                    </span>
                    <br>
                    +351 707 500 101
                </div>
            </div>
            <div class="container-fixed-type-1-close-button" (click)="type=0">
                <span>
                    x
                </span>
            </div>
        </div>
    </div>
</ng-container>