import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { Commission } from '../models/commission';

@Injectable({
  providedIn: 'root',
})
export class CommissionService {
  basePath: string;

  constructor(private http: HttpClient) {
    this.basePath = environment.baseUrl;
  }

  list() {
    return this.http.get<Commission[]>(`${this.basePath}/commissions`).pipe(map(res => res.map(c => new Commission(c))))
  }
}
