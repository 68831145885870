import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { isEmpty as _isEmpty } from "lodash";
import { Subscription } from "rxjs";
import { finalize, first } from "rxjs/operators";
import { PopupCodeComponent } from "src/app/modals/popup-code/popup-code.component";
import { Address } from "src/app/models/address";
import { Card } from "src/app/models/card";
import { DOCUMENT_TYPES, Profile } from "src/app/models/profile";
import { User } from "src/app/models/user";
import { AlertService } from "src/app/services/alert.service";
import { ProfileService } from "src/app/services/profile.service";
import { TransferDataService } from "src/app/services/transferData.service";
import { UserService } from "src/app/services/user.service";
import { countryList, nationalityList } from "src/app/models/countries";
import { CountryISO, SearchCountryField } from "ngx-intl-tel-input";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";

import {
  EMPLOYMENTSTATUS,
  OCCUPATION,
  CARDPURPOSE,
  MONTHLYINCOME,
  ESTATE,
} from "src/app/utils/extra-fields";

const POPUP_CODE_WIDTH = "400px";

export const MY_FORMATS2 = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-card-profile",
  templateUrl: "./card-profile.component.html",
  styleUrls: ["./card-profile.component.css"],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS2 },
  ],
})
export class CardProfileComponent implements OnInit, OnDestroy {
  @ViewChild("inputEmail") inputEmail: ElementRef;
  @ViewChild("inputPhone") inputPhone: ElementRef;
  @ViewChild("inputFirstname") inputFirstname: ElementRef;
  @ViewChild("inputMiddlename") inputMiddlename: ElementRef;
  @ViewChild("inputLastname") inputLastname: ElementRef;
  @ViewChild("inputDocumentNumber") inputDocumentNumber: ElementRef;
  @ViewChild("inputDocumentExpiryDate") inputDocumentExpiryDate: ElementRef;

  @ViewChild("inputAddress") inputAddress: ElementRef;
  @ViewChild("inputCity") inputCity: ElementRef;
  @ViewChild("inputZipCode") inputZipCode: ElementRef;

  @ViewChild("front_id") front_id: ElementRef;
  @ViewChild("back_id") back_id: ElementRef;
  @ViewChild("comercial_id") comercial_id: ElementRef;

  subscriptions: Subscription[] = [null, null];

  user: User;
  profile: Profile;
  mainAddress: Address;
  card: Card;

  // -- profile
  // editingEmail = false;
  // editingPhone = false;
  // editingFirstname = false;
  // editingLastname = false;
  // editingNationality = false;

  // -- address
  // editingAddress = false;
  // editingCity = false;
  // editingZipCode = false;

  nationalityUpdated = null;
  countryUpdated = null;
  cardPurposeUpdated = null;
  employmentStatusUpdated = null;
  occupationUpdated = null;
  monthlyIncomeUpdated = null;
  estateUpdated = null;
  countryOfIssuanceUpdated = null;
  documentTypeUpdated = null;

  files = { front_id: "", back_id: "", comercial_id: "" };
  filesResults = { front_id: null, back_id: null, comercial_id: null };
  fileControlType0 = null;
  fileControlType1 = null;
  fileControlType2 = null;

  countries = countryList;
  nationalityL = nationalityList;

  isEdit = false;

  employmentStatus = EMPLOYMENTSTATUS;
  occupation = OCCUPATION;
  cardPurpose = CARDPURPOSE;
  monthlyIncome = MONTHLYINCOME;
  estate = ESTATE;

  phoneModelValue = null;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.Portugal,
    CountryISO.Spain,
    CountryISO.UnitedKingdom,
  ];

  get country() {
    const { country = "" } = this.mainAddress || {};

    return _isEmpty(country) || country == "null" ? "-" : country;
  }

  get phoneSetted() {
    const { e164Number = "" } = this.phoneModelValue || {};

    return (e164Number || "").replace("+", "00");
  }

  get documentTypesAvailable() {
    if (this.documentTypeUpdated === "Spain") {
      return [{ label: "bi", value: "Id" }];
    }

    return DOCUMENT_TYPES;
  }

  get selectedDocumentType() {
    const { document_type = null } = this.profile || {};
    const { label = null } = DOCUMENT_TYPES.find((dt) => dt.value === document_type) || {};

    return label;
  }

  get documentExpirationDate() {
    const { document_expiry_date = null } = this.profile || {};
    const [firstHalf] = (document_expiry_date || "").split("T");

    return firstHalf || "----";
  }

  get isSavedAvailable() {
    const {
      firstname,
      lastname,
      nationality,
      phone,
      email,
      card_purpose,
      employment_status,
      occupation,
      monthly_income,
      estate,
      // middlename,
      country_of_issuance,
      document_type,
      document_number,
      document_expiry_date,
    } = this.profile || {};

    const { address, city, zipcode, country } = this.mainAddress || {};

    // -- profile
    const firstNameEdited = this.isNotEqual(firstname, this.inputFirstname);
    // const middlenameEdited = this.isNotEqual(middlename, this.inputMiddlename);
    const lastNameEdited = this.isNotEqual(lastname, this.inputLastname);
    const nationalityEdited = nationality !== this.nationalityUpdated;
    const phoneEdited = this.isPhoneEquals(phone); //this.isNotEqual(phone, this.inputPhone);
    const emailEdited = this.isNotEqual(email, this.inputEmail);
    const cardPurposeEdited = card_purpose !== this.cardPurposeUpdated;
    const employmentStatusEdited = employment_status !== this.employmentStatusUpdated;
    const occupationEdited = occupation !== this.occupationUpdated;
    const monthlyIncomeEdited = monthly_income !== this.monthlyIncomeUpdated;
    const estateEdited = estate !== this.estateUpdated;
    const countryOfIssuanceEdited = country_of_issuance !== this.countryOfIssuanceUpdated;
    const documentTypeEdited = document_type !== this.documentTypeUpdated;
    const documentNumberEdited = this.isNotEqual(document_number, this.inputDocumentNumber);

    const documentExpiryDateEdited = this.isNotEqual(
      document_expiry_date,
      this.inputDocumentExpiryDate
    );

    // -- address
    const addressEdited = this.isNotEqual(address, this.inputAddress);
    const cityEdited = this.isNotEqual(city, this.inputCity);
    const zipCodeEdited = this.isNotEqual(zipcode, this.inputZipCode);
    const countryEdited = country !== this.countryUpdated;

    return (
      estateEdited ||
      firstNameEdited ||
      lastNameEdited ||
      nationalityEdited ||
      phoneEdited ||
      emailEdited ||
      cardPurposeEdited ||
      employmentStatusEdited ||
      occupationEdited ||
      monthlyIncomeEdited ||
      addressEdited ||
      cityEdited ||
      zipCodeEdited ||
      countryEdited ||
      // middlenameEdited ||
      countryOfIssuanceEdited ||
      documentTypeEdited ||
      documentNumberEdited ||
      documentExpiryDateEdited
    );
  }

  constructor(
    private transferDataService: TransferDataService,
    private alertService: AlertService,
    private userService: UserService,
    private profileService: ProfileService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadUser();
    this.subscriptions[0] = this.userService.onUserChange().subscribe((m) => this.loadUser());
    this.subscriptions[1] = this.transferDataService
      .onDataUpdate()
      .subscribe((m) => this.loadUser());
  }

  ngOnDestroy() {
    if (this.subscriptions.length > 0) {
      this.subscriptions.forEach((s) => {
        if (s) {
          s.unsubscribe();
        }
      });
    }
  }

  loadUser() {
    this.user = this.userService.authenticated_user;
    if (this.user && this.transferDataService.data && this.transferDataService.data.card) {
      this.card = this.transferDataService.data.card;
      this.profile = this.user.searchProfileForCardId(this.card.id);
      this.mainAddress = this.profile.getMainAddress();

      const {
        nationality = null,
        card_purpose = null,
        employment_status = null,
        occupation = null,
        monthly_income = null,
        estate = null,
        country_of_issuance = null,
        document_type = null,
        // phone = null,
      } = this.profile || {};

      this.nationalityUpdated = nationality;
      this.cardPurposeUpdated = card_purpose;
      this.employmentStatusUpdated = employment_status;
      this.occupationUpdated = occupation;
      this.monthlyIncomeUpdated = monthly_income;
      this.estateUpdated = estate;
      // this.phoneModelValue = phone.replace("00", "+");
      this.countryOfIssuanceUpdated = country_of_issuance;
      this.documentTypeUpdated = document_type;

      const { country = null } = this.mainAddress || {};

      this.countryUpdated = country;
    }
  }

  displayKycLabel(): string {
    if (!this.profile) {
      return "-";
    }

    switch (this.profile.kyc) {
      case 1:
        return "Plus";
      case 2:
        return "Premium";
      default:
        return "-";
    }
  }

  pdfLink(): string {
    switch (localStorage.getItem("language")) {
      case "es":
        return "/assets/pdfs/guia_documentos_es.pdf";
      case "en":
        return "/assets/pdfs/guia_documentos_en.pdf";
      default:
        return "/assets/pdfs/guia_documentos_pt.pdf";
    }
  }

  isNotEqual(originalValue, input: ElementRef): boolean {
    const { nativeElement = null } = input || {};
    const { value = null } = nativeElement || {};

    return value !== null && originalValue !== value;
  }

  isPhoneEquals(originalValue) {
    const { e164Number = "" } = this.phoneModelValue || {};

    return e164Number === "" || originalValue === this.phoneSetted;
  }

  // Events

  // @deprecated
  onInputKeydown(event = null) {
    // if (!event || event.key === "Enter") {
    //   this.onOperationBtnPress();
    // }
  }

  onOperationBtnPress() {
    const {
      firstname,
      lastname,
      nationality,
      phone,
      email,
      card_purpose,
      employment_status,
      occupation,
      monthly_income,
      estate,
      // middlename,
      country_of_issuance,
      document_type,
      document_number,
      document_expiry_date,
    } = this.profile || {};

    const { address, city, zipcode, country } = this.mainAddress || {};

    // -- profile
    const firstNameEdited = this.isNotEqual(firstname, this.inputFirstname);
    // const middlenameEdited = this.isNotEqual(middlename, this.inputMiddlename);
    const lastNameEdited = this.isNotEqual(lastname, this.inputLastname);
    const nationalityEdited = nationality !== this.nationalityUpdated;
    const phoneEdited = !this.isPhoneEquals(phone); //this.isNotEqual(phone, this.inputPhone);
    const emailEdited = this.isNotEqual(email, this.inputEmail);
    const cardPurposeEdited = card_purpose !== this.cardPurposeUpdated;
    const employmentStatusEdited = employment_status !== this.employmentStatusUpdated;
    const occupationEdited = occupation !== this.occupationUpdated;
    const monthlyIncomeEdited = monthly_income !== this.monthlyIncomeUpdated;
    const estateEdited = estate !== this.estateUpdated;
    const countryOfIssuanceEdited = country_of_issuance !== this.countryOfIssuanceUpdated;
    const documentTypeEdited = document_type !== this.documentTypeUpdated;
    const documentNumberEdited = this.isNotEqual(document_number, this.inputDocumentNumber);

    const documentExpiryDateEdited = this.isNotEqual(
      document_expiry_date,
      this.inputDocumentExpiryDate
    );

    // -- address
    const addressEdited = this.isNotEqual(address, this.inputAddress);
    const cityEdited = this.isNotEqual(city, this.inputCity);
    const zipCodeEdited = this.isNotEqual(zipcode, this.inputZipCode);
    const countryEdited = country !== this.countryUpdated;

    if (
      estateEdited ||
      firstNameEdited ||
      lastNameEdited ||
      nationalityEdited ||
      phoneEdited ||
      emailEdited ||
      cardPurposeEdited ||
      employmentStatusEdited ||
      occupationEdited ||
      monthlyIncomeEdited ||
      addressEdited ||
      cityEdited ||
      zipCodeEdited ||
      countryEdited ||
      // middlenameEdited ||
      countryOfIssuanceEdited ||
      documentTypeEdited ||
      documentNumberEdited ||
      documentExpiryDateEdited
    ) {
      let updateObject = {};

      if (documentExpiryDateEdited) {
        updateObject = {
          ...updateObject,
          document_expiry_date: this.inputDocumentExpiryDate.nativeElement
            ? this.inputDocumentExpiryDate.nativeElement.value
            : "",
        };
      }

      if (documentNumberEdited) {
        updateObject = {
          ...updateObject,
          document_number: this.inputDocumentNumber.nativeElement
            ? this.inputDocumentNumber.nativeElement.value
            : "",
        };
      }

      if (documentTypeEdited) {
        updateObject = {
          ...updateObject,
          document_type: this.documentTypeUpdated || "",
        };
      }

      if (countryOfIssuanceEdited) {
        updateObject = {
          ...updateObject,
          countryOfIssuance: this.countryOfIssuanceUpdated || "",
        };
      }

      if (firstNameEdited) {
        updateObject = {
          ...updateObject,
          firstname: this.inputFirstname.nativeElement
            ? this.inputFirstname.nativeElement.value
            : "",
        };
      }

      // if (middlenameEdited) {
      //   updateObject = {
      //     ...updateObject,
      //     middlename: this.inputMiddlename.nativeElement
      //       ? (this.inputMiddlename.nativeElement.value || "").slice(0, 1)
      //       : "",
      //   };
      // }

      if (lastNameEdited) {
        updateObject = {
          ...updateObject,
          lastname: this.inputLastname.nativeElement ? this.inputLastname.nativeElement.value : "",
        };
      }

      if (nationalityEdited) {
        updateObject = {
          ...updateObject,
          nationality: this.nationalityUpdated || "",
        };
      }

      if (phoneEdited) {
        updateObject = {
          ...updateObject,
          phone: this.phoneSetted,
          //phone: this.inputPhone.nativeElement ? this.inputPhone.nativeElement.value : "",
        };
      }

      if (emailEdited) {
        updateObject = {
          ...updateObject,
          email: this.inputEmail.nativeElement ? this.inputEmail.nativeElement.value : "",
        };
      }

      if (cardPurposeEdited) {
        updateObject = {
          ...updateObject,
          card_purpose: this.cardPurposeUpdated || "",
        };
      }

      if (employmentStatusEdited) {
        updateObject = {
          ...updateObject,
          employment_status: this.employmentStatusUpdated || "",
        };
      }

      if (occupationEdited) {
        updateObject = {
          ...updateObject,
          occupation: this.occupationUpdated || "",
        };
      }

      if (monthlyIncomeEdited) {
        updateObject = {
          ...updateObject,
          monthly_income: this.monthlyIncomeUpdated || "",
        };
      }

      if (estateEdited) {
        updateObject = {
          ...updateObject,
          estate: this.estateUpdated || "",
        };
      }

      if (addressEdited) {
        updateObject = {
          ...updateObject,
          address: this.inputAddress.nativeElement ? this.inputAddress.nativeElement.value : "",
        };
      }

      if (cityEdited) {
        updateObject = {
          ...updateObject,
          city: this.inputCity.nativeElement ? this.inputCity.nativeElement.value : "",
        };
      }

      if (zipCodeEdited) {
        updateObject = {
          ...updateObject,
          zipcode: this.inputZipCode.nativeElement ? this.inputZipCode.nativeElement.value : "",
        };
      }

      if (countryEdited) {
        updateObject = {
          ...updateObject,
          country: this.countryUpdated || "",
        };
      }

      const dialogRef = this.dialog.open(PopupCodeComponent, {
        width: POPUP_CODE_WIDTH,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.alertService.loading(true);

          const sub = this.profileService
            .updateCardProfile(this.profile.id, updateObject, result)
            .pipe(
              finalize(() => {
                this.alertService.loading(false);
                this.isEdit = false;
                sub.unsubscribe();
                location.reload();
              })
            )
            .subscribe(
              (data) => {
                if (firstNameEdited) {
                  this.profile.firstname = this.inputFirstname.nativeElement
                    ? this.inputFirstname.nativeElement.value
                    : "";
                }

                // if (middlenameEdited) {
                //   this.profile.middlename = this.inputMiddlename.nativeElement
                //     ? this.inputMiddlename.nativeElement.value
                //     : "";
                // }

                if (lastNameEdited) {
                  this.profile.lastname = this.inputLastname.nativeElement
                    ? this.inputLastname.nativeElement.value
                    : "";
                }

                if (nationalityEdited) {
                  this.profile.nationality = this.nationalityUpdated || "";
                }

                if (phoneEdited) {
                  this.profile.phone = this.phoneSetted;
                  // this.profile.phone = this.inputPhone.nativeElement
                  //   ? this.inputPhone.nativeElement.value
                  //   : "";
                }

                if (emailEdited) {
                  this.profile.email = this.inputEmail.nativeElement
                    ? this.inputEmail.nativeElement.value
                    : "";
                }

                if (cardPurposeEdited) {
                  this.profile.card_purpose = this.cardPurposeUpdated || "";
                }

                if (employmentStatusEdited) {
                  this.profile.employment_status = this.employmentStatusUpdated || "";
                }

                if (occupationEdited) {
                  this.profile.occupation = this.occupationUpdated || "";
                }

                if (monthlyIncomeEdited) {
                  this.profile.monthly_income = this.monthlyIncomeUpdated || "";
                }

                if (estateEdited) {
                  this.profile.estate = this.estateUpdated || "";
                }

                if (addressEdited) {
                  this.mainAddress.address = this.inputAddress.nativeElement
                    ? this.inputAddress.nativeElement.value
                    : "";
                }

                if (cityEdited) {
                  this.mainAddress.city = this.inputCity.nativeElement
                    ? this.inputCity.nativeElement.value
                    : "";
                }

                if (zipCodeEdited) {
                  this.mainAddress.zipcode = this.inputZipCode.nativeElement
                    ? this.inputZipCode.nativeElement.value
                    : "";
                }

                if (countryEdited) {
                  this.mainAddress.country = this.countryUpdated || "";
                }

                if (countryOfIssuanceEdited) {
                  this.profile.country_of_issuance = this.countryOfIssuanceUpdated || "";
                }

                if (documentTypeEdited) {
                  this.profile.document_type = this.documentTypeUpdated || "";
                }

                if (documentNumberEdited) {
                  this.profile.document_number = this.inputDocumentNumber.nativeElement
                    ? this.inputDocumentNumber.nativeElement.value
                    : "";
                }

                if (documentExpiryDateEdited) {
                  this.profile.document_expiry_date = this.inputDocumentExpiryDate.nativeElement
                    ? this.inputDocumentExpiryDate.nativeElement.value
                    : "";
                }
              },
              (error) => this.alertService.error(error.error)
            );
        } else {
          this.isEdit = false;
        }
      });
    } else {
      this.isEdit = false;
    }
  }

  // @deprecated
  onInputAddressKeydown(event = null) {
    // if (!event || event.key === "Enter") {
    //   this.onAddressOperationBtnPress();
    // }
  }

  onAddressOperationBtnPress() {
    const { address, city, zipcode, country } = this.mainAddress || {};

    const addressEdited = this.isNotEqual(address, this.inputAddress);
    const cityEdited = this.isNotEqual(city, this.inputCity);
    const zipCodeEdited = this.isNotEqual(zipcode, this.inputZipCode);
    const countryEdited = country !== this.countryUpdated;

    if (addressEdited || cityEdited || zipCodeEdited || countryEdited) {
      let updateObject = {};

      // save email
      if (addressEdited) {
        updateObject = {
          ...updateObject,
          address: this.inputAddress.nativeElement ? this.inputAddress.nativeElement.value : "",
        };
      }

      if (cityEdited) {
        updateObject = {
          ...updateObject,
          city: this.inputCity.nativeElement ? this.inputCity.nativeElement.value : "",
        };
      }

      if (zipCodeEdited) {
        updateObject = {
          ...updateObject,
          zipcode: this.inputZipCode.nativeElement ? this.inputZipCode.nativeElement.value : "",
        };
      }

      if (countryEdited) {
        updateObject = {
          ...updateObject,
          country: this.countryUpdated || "",
        };
      }

      const dialogRef = this.dialog.open(PopupCodeComponent, {
        width: POPUP_CODE_WIDTH,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.alertService.loading(true);

          const sub = this.profileService
            .updateCardAddress(this.profile.id, updateObject, result)
            .pipe(
              finalize(() => {
                this.alertService.loading(false);
                this.isEdit = false;
                sub.unsubscribe();
              })
            )
            .subscribe(
              (data) => {
                if (addressEdited) {
                  this.mainAddress.address = this.inputAddress.nativeElement
                    ? this.inputAddress.nativeElement.value
                    : "";
                }

                if (cityEdited) {
                  this.mainAddress.city = this.inputCity.nativeElement
                    ? this.inputCity.nativeElement.value
                    : "";
                }

                if (zipCodeEdited) {
                  this.mainAddress.zipcode = this.inputZipCode.nativeElement
                    ? this.inputZipCode.nativeElement.value
                    : "";
                }

                if (countryEdited) {
                  this.mainAddress.country = this.countryUpdated || "";
                }
              },
              (error) => this.alertService.error(error.error)
            );
        } else {
          this.alertService.loading(false);
          this.isEdit = false;
        }
      });
    } else {
      this.alertService.loading(false);
      this.isEdit = false;
    }
  }

  // KYC

  onUploadPress(type: number) {
    if (this.profile.kyc == 2) {
      return;
    }

    switch (type) {
      case 1:
        if (this.fileControlType1 && this.fileControlType1.status == 0) {
          // em espera nao faz nada
          return;
        }
        this.back_id.nativeElement.click();
        break;
      case 2:
        if (this.fileControlType2 && this.fileControlType2.status == 0) {
          // em espera nao faz nada
          return;
        }
        this.comercial_id.nativeElement.click();
        break;
      default:
        if (this.fileControlType0 && this.fileControlType0.status == 0) {
          // em espera nao faz nada
          return;
        }
        this.front_id.nativeElement.click();
        break;
    }
  }

  onUploadFile(event, type) {
    if (event.target.files.length <= 0) {
      return;
    }

    switch (type) {
      case 1:
        this.files.back_id = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          this.filesResults.back_id = event.target.result;
        };
        break;
      case 2:
        this.files.comercial_id = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          this.filesResults.comercial_id = event.target.result;
        };
        break;
      default:
        this.files.front_id = event.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (event: any) => {
          this.filesResults.front_id = event.target.result;
        };
        break;
    }
  }

  isValidToSubmit() {
    if (
      (this.files.front_id !== "" && this.fileControlType0 == null) ||
      (this.fileControlType0 != null && this.fileControlType0.status !== 0) ||
      (this.files.back_id !== "" && this.fileControlType1 == null) ||
      (this.fileControlType1 != null && this.fileControlType1.status !== 0) ||
      (this.files.comercial_id !== "" && this.fileControlType2 == null) ||
      (this.fileControlType2 != null && this.fileControlType2.status !== 0)
    ) {
      return true;
    }
    return false;
  }

  onSubmitKyc() {
    if (!this.isValidToSubmit()) {
      return;
    }

    let files = [];

    // create response
    if (this.files.front_id !== "") {
      files.push({
        type: 0,
        file: this.files.front_id,
      });
    }

    if (this.files.back_id !== "") {
      files.push({
        type: 1,
        file: this.files.back_id,
      });
    }

    if (this.files.comercial_id !== "") {
      files.push({
        type: 2,
        file: this.files.comercial_id,
      });
    }

    if (files.length == 0) {
      this.alertService.error("new_to_upload_1_file");
    } else {
      const formData = new FormData();

      for (let it = 0; it < files.length; it++) {
        formData.append("files[" + it + "][type]", files[it].type);
        formData.append("files[" + it + "][file]", files[it].file);
      }

      this.alertService.loading(true);
      this.profileService
        .upload_files(formData, this.profile.id)
        .pipe(first())
        .pipe(finalize(() => this.alertService.loading(false)))
        .subscribe(
          (data) => {
            this.alertService.success("upload_success");
            this.userService.refreshUser();
          },
          (error) => this.alertService.error("upload_error")
        );
    }
  }
}
