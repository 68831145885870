import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';
import { CardService } from 'src/app/services/card.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-form-user-step0',
    templateUrl: './form-user-step0.component.html',
    styleUrls: ['./form-user-step0.component.css'],
})
export class FormUserStep0Component implements OnInit {
    @Input() showButton = true;
    @Input() showName = false;
    @Output() onValidCard = new EventEmitter<any>();
    verifyCardForm: FormGroup;

    constructor(
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private cardService: CardService,
        private router: Router
    ) { }

    ngOnInit() {
        this.verifyCardForm = this.formBuilder.group({
            name: [''], // nao colocar aqui o required senao na criacao quando o showName = false estoura
            cardholder: ['', Validators.required],
            cardnumber: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
        });
    }

    get vf() {
        return this.verifyCardForm.controls;
    }

    verifyCardSubmit() {
        if (this.verifyCardForm.invalid) {
            this.alertService.error('form_error');
            return;
        }

        this.reCaptchaV3Service.execute(environment.recaptcha, 'login', (token) => {
            this.alertService.loading(true);

            const sub = this.cardService.verifyAndReturnKyc(this.vf.cardholder.value, this.vf.cardnumber.value, token)
                .pipe(finalize(() => {
                    sub.unsubscribe();
                    this.alertService.loading(false);
                }))
                .subscribe(
                    (data: any) => {
                        if ('kyc' in data && 'email' in data) {
                            const kyc = '' + data.kyc;
                            const email = data.email;
                            const alreadyRegistered = ('alreadyRegistered' in data) ? data.alreadyRegistered : false;
                            this.onValidCard.emit({ alreadyRegistered: alreadyRegistered, email: email, typeKyc: ('' + kyc), name: this.vf.name.value, cardHolderId: this.vf.cardholder.value, cardNumber: this.vf.cardnumber.value })
                        }
                    },
                    error => {
                        switch (error.code) {
                            case 403:
                                if (typeof (error.error) === 'object' && 'extraText' in error.error) {
                                    const domain = error.error.extraText.split('@');
                                    this.alertService.errorWithExtraMessage('card_already_registered', error.error.extraText.substring(0, 3) + '****@' + ((domain.length > 1) ? domain[1] : ''));
                                } else {
                                    this.alertService.error('card_already_registered');
                                }
                                this.router.navigate(['/login']);
                                break;
                            case 501:
                                this.onValidCard.emit(null);
                                this.alertService.error('card not activated');
                                break;
                            default:
                                this.onValidCard.emit(null);
                                this.alertService.specialError();
                        }
                    }
                );
        }, {
            useGlobalDomain: false
        });
    }
}