import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-card-register',
  templateUrl: './about-card-register.component.html',
  styleUrls: ['../intro.component.css', './about-card-register.component.css']
})
export class AboutCardRegisterComponent implements OnInit {
    constructor() { }

    ngOnInit() {}
}
