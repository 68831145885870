<div class="main-container">

  <div class="left-container">
    <div class="left-container-cell left-container-cell-normal" *ngFor="let userS of list_users">
      {{ (userS.firstname || '') + ' ' + (userS.lastname || '') }}
    </div>

    <!-- <div class="left-container-cell left-container-cell-selected" style="font-weight: bold;" (click)="showAddUsers = true">
            {{ 'add_new_account' | translate }}
        </div> -->
  </div>

  <div class="m-container">
    <div class="m-container-column">
      <div class="title">{{ 'personal_information' | translate }}</div>

      <div class="container">
        <div class="container-row">
          <div class="container-row-left-cell">{{ 'kyc_level' | translate }}</div>
          <div class="container-row-right-cell">{{ displayKycLabel() }}</div>
        </div>
        <div class="container-row">
          <div class="container-row-left-cell">{{ 'name' | translate }}</div>
          <div class="container-row-right-cell">{{ profile.firstname + ' ' + profile.lastname }}</div>
        </div>
        <div class="container-row">
          <div class="container-row-left-cell">{{ 'address' | translate }}</div>
          <div class="container-row-right-cell">{{ address.address || '----' }}</div>
        </div>
        <div class="container-row">
          <div class="container-row-left-cell">{{ 'city' | translate }}</div>
          <div class="container-row-right-cell">{{ address.city || '----' }}</div>
        </div>
        <div class="container-row">
          <div class="container-row-left-cell">{{ 'zipcode' | translate }}</div>
          <div class="container-row-right-cell">{{ address.zipcode || '----' }}</div>
        </div>
        <div class="container-row">
          <div class="container-row-left-cell">{{ 'country' | translate }}</div>
          <div class="container-row-right-cell">{{ address.country || '----' }}</div>
        </div>
        <div class="container-row">
          <div class="container-row-left-cell">{{ 'phone' | translate }}</div>
          <div class="container-row-right-cell">{{ profile.phone || '----' }}</div>
        </div>
        <div class="container-row">
          <div class="container-row-left-cell">E-mail</div>
          <div class="container-row-right-cell">
            <div *ngIf="!editingEmail">
              {{ profile.email || '----' }}
            </div>
            <div *ngIf="editingEmail" class="my-form-input {{ (profile.email == '') ? 'my-form-input-error' : '' }}">
              <input #inputEmail type="email" id="email" placeholder="Email" value="{{ profile.email }}"
                (keydown)="onEmailInputKeydown($event)">
            </div>
          </div>
          <div class="container-row-right-btn" (click)="onEmailOperationBtnPress()">
            <span *ngIf="!editingEmail">{{ 'edit' | translate }}</span>
            <span *ngIf="editingEmail">{{ 'save' | translate }}</span>
          </div>
        </div>
        <div class="container-row">
          <div class="container-row-left-cell">{{ 'optional_company_name' | translate }}</div>
          <div class="container-row-right-cell"> {{ profile.p_company_name || '----' }}</div>
        </div>
      </div>
    </div>

    <div class="m-container-divider"></div>

    <div class="m-container-column" *ngIf="kycUserProfile != 2">
      <div class="title">{{ 'documents' | translate }}</div>

      <div class="d-container">
        <div class="d-container-row">
          <div class="upload-container-column">
            <div class="upload-container-column-title">{{ 'front_doc' | translate }}</div>
            <div class="upload-container-column-description">
              {{ 'front_doc_desc' | translate }}
            </div>
          </div>
          <div
            class="upload-container-column-img {{ (fileControlType0 != null) ? 'upload-container-column-img-' + fileControlType0.status : '' }}"
            (click)="onUploadPress(0)">
            <div *ngIf="fileControlType0 == null">
              <img *ngIf="filesResults.front_id == null" src="assets/images/upload.png">
              <img *ngIf="filesResults.front_id != null" src="{{ filesResults.front_id }}" class="upload-img-loaded">
            </div>
            <div *ngIf="fileControlType0 != null"> {{ statusText(fileControlType0.status) | translate }} </div>
          </div>

          <input #front_id type="file" id="front_id" class="kyc-input-files" (change)="onUploadFile($event, 0)"
            accept="image/x-png,image/png,image/jpeg" />
        </div>

        <div class="d-container-row">
          <div class="upload-container-column">
            <div class="upload-container-column-title">{{ 'back_doc' | translate }}</div>
            <div class="upload-container-column-description">
              {{ 'back_doc_desc' | translate }}
            </div>
          </div>
          <div
            class="upload-container-column-img {{ (fileControlType1 != null) ? 'upload-container-column-img-' + fileControlType1.status : '' }}"
            (click)="onUploadPress(1)">
            <div *ngIf="fileControlType1 == null">
              <img *ngIf="filesResults.back_id == null" src="assets/images/upload.png">
              <img *ngIf="filesResults.back_id != null" src="{{ filesResults.back_id }}" class="upload-img-loaded">
            </div>
            <div *ngIf="fileControlType1 != null"> {{ statusText(fileControlType1.status) | translate }} </div>
          </div>
          <input #back_id type="file" id="back_id" class="kyc-input-files" (change)="onUploadFile($event, 1)"
            accept="image/x-png,image/png,image/jpeg" />
        </div>

        <div class="d-container-row">
          <div class="upload-container-column">
            <div class="upload-container-column-title">{{ 'cert_doc' | translate }}</div>
          </div>
          <div
            class="upload-container-column-img {{ (fileControlType2 != null) ? 'upload-container-column-img-' + fileControlType2.status : '' }}"
            (click)="onUploadPress(2)">
            <div *ngIf="fileControlType2 == null">
              <img *ngIf="filesResults.comercial_id == null" src="assets/images/upload.png">
              <img *ngIf="filesResults.comercial_id != null" src="{{ filesResults.comercial_id }}"
                class="upload-img-loaded">
            </div>
            <div *ngIf="fileControlType2 != null"> {{ statusText(fileControlType2.status) | translate }} </div>
          </div>
          <input #comercial_id type="file" id="comercial_id" class="kyc-input-files" (change)="onUploadFile($event, 2)"
            accept="image/x-png,image/png,image/jpeg" />
        </div>

        <div class="d-container-row-divider"></div>

        <div class="d-container-row-msg-error-container">
          <div *ngIf="fileControlType0 != null" class="d-container-row-msg-error">
            {{ fileControlType0.errormessage | translate }}</div>
          <div *ngIf="fileControlType1 != null" class="d-container-row-msg-error">
            {{ fileControlType1.errormessage | translate }}</div>
          <div *ngIf="fileControlType2 != null" class="d-container-row-msg-error">
            {{ fileControlType2.errormessage | translate }}</div>
        </div>

        <div class="submit-button-container" *ngIf="profile.kyc != 2">
          <div class="submit-button {{ (!isValidToSubmit()) ? 'submit-button-locked' : '' }}" (click)="onSubmitKyc()">
            <span>{{ 'submit' | translate }}</span>
            <div class="submit-button-img-container">
              <img src="assets/images/arrow_2.png">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<!-- <div class="support-container">
    <div class="support-container-subcontainer">
        <div>
            Portugal +351 707 500 101
        </div>
        <div> 
            España +34 91 29 00203
        </div>
        <div>
            <a href="mailto:info@tarjetaspark.es" target="_top">info@tarjetaspark.es</a>
        </div>
    </div>
    <div class="flag-container">
        <app-flag flag="pt" [selected]="selectedFlag == 'pt'" (onPress)="selectLanguage('pt')"></app-flag>
        <div style="margin-right:15px;"></div>
        <app-flag flag="es" [selected]="selectedFlag == 'es'" (onPress)="selectLanguage('es')"></app-flag>
        <div style="margin-right:15px;"></div>
        <app-flag flag="en" [selected]="selectedFlag == 'en'" (onPress)="selectLanguage('en')"></app-flag>
    </div>
</div>
-->
<div class="form-user-container" *ngIf="showAddUsers">
  <div class="form-user-content">
    <app-form-user (onUserCreated)="onUserCreated()"></app-form-user>
  </div>
</div>