export class File {
    id: number;
    name: string;
    extension: string;
    type: number;
    status: number;
    errormessage: string;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}