import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  basePath: string;

  constructor(private http: HttpClient) {
    this.basePath = environment.baseUrl;
  }

  update_email(email: string, profile_id: number, code: string) {
    return this.http.post<any>(`${this.basePath}/profiles/email`, {
      email: email,
      profile_id: profile_id,
      code: code,
    });
  }

  upload_files(formData, profile_id: number) {
    formData.append("profile_id", profile_id);
    return this.http.post<any>(`${this.basePath}/files/upload`, formData);
  }

  updateCardProfile(id: number, profileParams, code: string) {
    return this.http.post(`${this.basePath}/profiles/updateprofile`, {
      id: id,
      ...profileParams,
      code,
    });
  }

  updateCardAddress(id: number, addressParams, code: string) {
    return this.http.post(`${this.basePath}/profiles/updateprofileaddress`, {
      id: id,
      ...addressParams,
      code,
    });
  }
}
