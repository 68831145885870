<h1 mat-dialog-title class="title">{{ "verification_code" | translate }}</h1>

<div class="mainContainer" mat-dialog-content>
  <div class="mainContainer--text">
    {{ 'MODAL_CODE_TEXT' | translate }}
  </div>

  <input placeholder="{{ 'verification_code' | translate }}" [(ngModel)]="code" />

  <span *ngIf="errorVisible" class="error-span">{{ "this field are invalid" | translate }}</span>
</div>

<div mat-dialog-actions class="btnContainer">
  <button
    mat-button
    class="app-button app-button-login"
    mat-raised-button
    (click)="okClick()"
    cdkFocusInitial
  >
    {{ "confirm" | translate }}
  </button>

  <button mat-button class="ok" [mat-dialog-close]="false">{{ "cancel" | translate }}</button>
</div>
