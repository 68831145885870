<div class="m-container">

    <div class="c-container" *ngFor="let d of deposit; let di = index">
        <div class="c-container-close" *ngIf="deposit.length > 1" (click)="onCardRemove(di)">
            <img src="assets/images/close.png" alt="close" />
        </div>

        <div class="c-container-box-card-container">
            <app-box-card [card]="d.card"></app-box-card>
        </div>

        <div class="c-container-divider"></div>

        <div class="c-container-column">
            <div class="title">{{ 'choose_amount' | translate }}</div>

            <div class="list-amounts">
                <div class="box-amound {{ (d.value == v) ? 'box-amound-selected' : '' }}"
                    *ngFor="let v of [50, 100, 200, 250];" (click)="d.value = v; d.input_value = '';">
                    € {{ v }}
                </div>
            </div>

            <!-- <div class="my-form-input">
                <label for="input_value">{{ 'other_amount' | translate }}</label>
                <input id="input_value" type="number" placeholder="Outra quantia" value="{{d.input_value}}"
                    (input)="onInputValueChange($event, d)">
            </div> -->
        </div>
    </div>

    <div class="sub-container">
        <div class="add-new-button" *ngIf="listCards.length > 0">
            <div (click)="showListCards = !showListCards">+ {{ 'add_new_card' | translate }}</div>
            <app-transfer-card-list [cards]="listCards" [onCardSelectCallback]="onAddCardsCallback.bind(this)"
                *ngIf="showListCards"></app-transfer-card-list>
        </div>

        <div class="total-container">
            <div class="title-total"> {{ 'total' | translate }} </div>
            <div class="label-total"> € {{ total.toFixed(2) }} </div>
            <div class="fee-total"> ({{ 'fee' | translate }} € {{ fee.toFixed(2) }}) </div>
        </div>

        <div class="sub-container-divider"></div>

        <div class="payments-container">
            <div class="payments-title">
                {{ 'choose_payment_type' | translate }}
            </div>
            <div class="payments-list">
                <div class="payments-list-box-container" *ngIf="isPaymentAvailable(0) && countryCode == 'PT'">
                    <div class="payments-list-box {{ (paymenteMethod == 0) ? 'payments-list-box-selected' : '' }}"
                        (click)="onSelectPaymentMethod(0)">
                        <img *ngIf="(paymenteMethod != 0)" src="assets/images/payments/normal/mb_way_normal.png"
                            width="62" height="31" alt="mb" />
                        <img *ngIf="(paymenteMethod == 0)" src="assets/images/payments/white/mb_way_white.png"
                            width="62" height="31" alt="mb" />
                    </div>
                    <div class="payments-list-box-text">MB way</div>
                </div>
                <div class="payments-list-box-container" *ngIf="isPaymentAvailable(1)">
                    <div class="payments-list-box {{ (paymenteMethod == 1) ? 'payments-list-box-selected' : '' }}"
                        (click)="onSelectPaymentMethod(1)">
                        <img *ngIf="(paymenteMethod != 1)" src="assets/images/payments/normal/transfer_normal.png"
                            width="41" height="23" alt="mb" />
                        <img *ngIf="(paymenteMethod == 1)" src="assets/images/payments/white/transfer_white.png"
                            width="41" height="23" alt="mb" />
                    </div>
                    <div class="payments-list-box-text">{{ 'tranfer' | translate }}</div>
                </div>
                <div class="payments-list-box-container" *ngIf="isPaymentAvailable(2) && countryCode == 'PT'">
                    <div class="payments-list-box {{ (paymenteMethod == 2) ? 'payments-list-box-selected' : '' }}"
                        (click)="onSelectPaymentMethod(2)">
                        <img *ngIf="(paymenteMethod != 2)" src="assets/images/payments/normal/mb_normal.png" width="34"
                            height="39" alt="mb" />
                        <img *ngIf="(paymenteMethod == 2)" src="assets/images/payments/white/mb_white.png" width="34"
                            height="39" alt="mb" />
                    </div>
                    <div class="payments-list-box-text">Multibanco</div>
                </div>
                <div class="payments-list-box-container" *ngIf="isPaymentAvailable(3) && countryCode == 'PT'">
                    <div class="payments-list-box {{ (paymenteMethod == 3) ? 'payments-list-box-selected' : '' }}"
                        (click)="onSelectPaymentMethod(3)">
                        <img *ngIf="(paymenteMethod != 3)" src="assets/images/payments/normal/payshop_normal.png"
                            width="34" height="39" alt="payshop" />
                        <img *ngIf="(paymenteMethod == 3)" src="assets/images/payments/white/payshop_white.png"
                            width="34" height="39" alt="payshop" />
                    </div>
                    <div class="payments-list-box-text">Payshop</div>
                </div>
                <!--
                <div class="payments-list-box-container" *ngIf="isPaymentAvailable(4)">
                    <div class="payments-list-box {{ (paymenteMethod == 4) ? 'payments-list-box-selected' : '' }}"
                        (click)="onSelectPaymentMethod(4)">
                        <img *ngIf="(paymenteMethod != 4)" src="assets/images/payments/normal/mymoid.png" width="44"
                            height="49" alt="mymoid" />
                        <img *ngIf="(paymenteMethod == 4)" src="assets/images/payments/white/mymoid.png" width="44"
                            height="49" alt="mymoid" />
                    </div>
                    <div class="payments-list-box-text">{{ 'credit_card' | translate }}</div>
                </div>
                <div class="payments-list-box-container" *ngIf="isPaymentAvailable(5)">
                    <div class="payments-list-box {{ (paymenteMethod == 5) ? 'payments-list-box-selected' : '' }}"
                        (click)="onSelectPaymentMethod(5)">
                        <img *ngIf="(paymenteMethod != 5)" src="assets/images/payments/white/utrust.png" width="44"
                            height="49" alt="utrust" />
                        <img *ngIf="(paymenteMethod == 5)" src="assets/images/payments/white/utrust.png" width="44"
                            height="49" alt="utrust" />
                    </div>
                    <div class="payments-list-box-text">UTrust</div>
                </div>
                -->
                <div class="payments-list-box-container" *ngIf="isPaymentAvailable(6)">
                    <div class="payments-list-box {{ (paymenteMethod == 7) ? 'payments-list-box-selected' : '' }}"
                        (click)="onSelectPaymentMethod(7)">
                        <img *ngIf="(paymenteMethod != 7)" src="assets/images/payments/normal/mymoid.png" width="44"
                            height="49" alt="credit card" />
                        <img *ngIf="(paymenteMethod == 7)" src="assets/images/payments/white/mymoid.png" width="44"
                            height="49" alt="credit card" />
                    </div>
                    <div class="payments-list-box-text">{{ 'credit_card' | translate }}</div>
                </div>
            </div>
        </div>

        <!-- <div class="creditCardsContainer" *ngIf="paymenteMethod == 4">
            <div class="creditCard {{ (creditCardIdSelected == 0) ? 'creditCard-selected' : '' }}"
                (click)="creditCardIdSelected = 0">
                Novo cartão
            </div>
        </div> -->

        <div class="submit-button {{ isSubmitButtonLocked() ? 'submit-button-locked' : '' }}"
            (click)="onConfirmPress()">
            <span>{{ 'confirm' | translate }}</span>
            <div class="submit-button-img-container">
                <img src="assets/images/arrow_2.png">
            </div>
        </div>
    </div>

</div>