import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { CardPopupService } from "../../../services/card-popup.service";
import { TransferDataService } from "../../../services/transferData.service";

import { MatDialog } from "@angular/material/dialog";
import { CARD_SELECTED } from "src/app/helpers/localstorage-utils";
import { Translate } from "src/app/locales/translate";
import { Card } from "src/app/models/card";
import { AuthenticationService } from "src/app/services/authentication.service";
import { User } from "../../../models/user";
import { UserService } from "../../../services/user.service";

const MENUS_ADMINISTRATOR = [
  { label: "consult_pin", link: "changepin" },
  { label: "lockorunlockcard", link: "lockorunlockcard" },
  { label: "logout", link: "logout" },
  // { label: 'lost_card', link: 'lost' },
  // { label: 'stolen_card', link: 'stolen' },
];

@Component({
  selector: "app-card-layout-layout",
  templateUrl: "./card-layout.component.html",
  styleUrls: ["./card-layout.component.css"],
})
export class AppCardLayoutComponent implements OnInit, OnDestroy {
  private userSubscription: Subscription;
  private transferData: Subscription;

  menus = [
    // { label: 'profile', link: 'cards/cardprofile' },
    { label: "access_information", link: "cards/accessinformation" },
    { label: "card_details", link: "cards/carddetails" },
    { label: "history", link: "cards/transactions" },
  ];

  menusAdministrator = [];

  user: User = new User();
  availableCards: Card[]; // todos os cards disponiveis nos profiles
  cardSelected: Card; // selected card

  selectCard = ""; // id card selected
  menuSelected = "";
  mobileList = false; // lista de opcoes do cartao
  emptyCards = []; // controla quantos cartoes de placeholder devemos adicionar

  cardListModeMobile = false; // que tipo de lista deve apresentar
  showAllCards = false; // no caso do mobile mostra a lista com todos os cartoes

  get isUpgradeCardAvailable() {
    this.user = this.userService.authenticated_user;
    const { profile = null, isUpgradePremiumAvailable } = this.user || {};
    const { kyc = null } = profile || {};

    return isUpgradePremiumAvailable; //&& kyc !== 2;
  }

  constructor(
    private titleService: Title,
    private router: Router,
    private userService: UserService,
    private transferDataService: TransferDataService,
    private cardPopupService: CardPopupService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.updateModeCardList();

    const translate = new Translate();
    this.titleService.setTitle(translate.transform("my_cards"));

    this.loadUser();

    this.userSubscription = this.userService
      .onUserChange()
      .subscribe((modified) => this.loadUser());

    this.transferData = this.transferDataService.onDataUpdate().subscribe((modified) => {
      const data = this.transferDataService.data;
      if (data.op === "refreshMenu") {
        this.refreshMenu();
      }
    });
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.transferData.unsubscribe();
  }

  loadUser() {
    this.user = this.userService.authenticated_user;

    if (!this.user) {
      this.authenticationService.logout();
    } else {
      this.availableCards = this.user.allCards;
      this.selectCard = localStorage.getItem(CARD_SELECTED) || ""; // ID
      this.cardSelected = this.user.searchCardById(this.selectCard);
      this.loadCardMenu();
    }
  }

  loadCardMenu() {
    const length = this.user ? this.user.totalCards() : 0;

    if (!this.cardSelected && length > 0) {
      this.cardSelected = this.availableCards[0];
      this.selectCard = this.cardSelected.id;
      localStorage.setItem(CARD_SELECTED, this.selectCard);
    }

    this.emptyCards = length < 4 ? new Array(4 - length).fill(0) : [];

    if (this.user.isAdministrator()) {
      this.menusAdministrator =
        this.cardSelected &&
        (this.cardSelected.isExpired() || this.cardSelected.status == "disabled") &&
        length > 1
          ? MENUS_ADMINISTRATOR.slice(0, MENUS_ADMINISTRATOR.length - 1)
              .concat({ label: "removecard", link: "removecard" })
              .concat(
                MENUS_ADMINISTRATOR.slice(
                  MENUS_ADMINISTRATOR.length - 1,
                  MENUS_ADMINISTRATOR.length
                )
              )
          : MENUS_ADMINISTRATOR;

      if (this.cardSelected.fraud === true) {
        this.menusAdministrator = this.menusAdministrator.filter(
          (m) => m.label != "lockorunlockcard"
        );
      }
    } else {
      this.menusAdministrator = [{ label: "logout", link: "logout" }];
    }

    this.refreshMenu();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.updateModeCardList();
  }

  // GETS

  get unlockedCardSelected(): boolean {
    const card = this.cardSelected;
    return card && card.status === "unlocked";
  }

  // METHODS

  updateModeCardList() {
    this.cardListModeMobile = !(window.innerWidth > 540);
  }

  toggleMobileList() {
    this.mobileList = true;
  }

  onCardSelect(card: Card) {
    this.cardSelected = card;
    this.selectCard = card.id;
    this.showAllCards = false;
    localStorage.setItem(CARD_SELECTED, this.selectCard);
    this.loadCardMenu();
    //this.refreshMenu();
  }

  onEditNamePress(card: Card) {
    this.onMenuSelect("cards/carddetails", { editCardName: true });
  }

  onEmptyCardPress() {
    this.cardPopupService.showPopup("addcard", null);
    const translate = new Translate();
    this.titleService.setTitle(translate.transform("add_new_card"));
  }

  onMenuSelect(menu, queryParams = {}) {
    const unlocked = this.unlockedCardSelected;

    if (!unlocked && (menu.link == "lost" || menu.link == "stolen")) {
      return;
    }

    this.menuSelected = menu;

    let changeUrl = true;

    switch (menu) {
      case "logout":
        this.userService.clear();
        this.authenticationService.logout();
        this.router.navigate(["/login"]).then(() => {
          // Reload Angular to refresh components and prevent old data from loading up for a
          // another user after login. This especially applies lazy loading cases.
          location.reload();
        });
        break;
      case "cards/cardprofile":
      case "cards/carddetails":
      case "cards/transactions":
      case "cards/cardupdatepremium":
        this.transferDataService.data = { card: this.cardSelected };
        break;
      case "changepin":
      case "lockorunlockcard":
      case "lost":
      case "stolen":
      case "removecard":
        this.cardPopupService.showPopup(menu, this.cardSelected);
        changeUrl = false;
        break;
      default:
        break;
    }

    if (changeUrl) {
      this.router.navigate(["app/" + menu], { queryParams: queryParams });
    }

    if (this.mobileList) {
      this.mobileList = false;
    }
  }

  // para actualizar os submenus sempre que existe 1 refresh
  // ou o menu principal é alterado
  // para que o refresh funcione correctamente temos de guardar o ultimo cartao selecionado
  refreshMenu() {
    if (!this.selectCard || this.selectCard == "") {
      return;
    }

    switch (this.router.url) {
      case "/app/cards/accessinformation":
        this.onMenuSelect("cards/accessinformation");
        break;
      case "/app/cards/cardprofile":
        this.onMenuSelect("cards/cardprofile");
        break;
      case "/app/cards/carddetails":
        this.onMenuSelect("cards/carddetails");
        break;
      case "/app/cards/transactions":
        this.onMenuSelect("cards/transactions");
        break;
      case "/app/cards/cardupdatepremium":
        this.onMenuSelect("cards/cardupdatepremium");
        break;
      default:
        break;
    }
  }

  handleOnPopupKycClick(): void {
    // const { lock_kyc = false, kyc_redirect_url = null } =
    //   this.cardSelected || {};

    // const dialogRef = this.dialog.open(PopupKycQrCodeComponent, {
    //   width: "420px",
    //   data: {
    //     cardHolderId: this.selectCard,
    //     mode:
    //       lock_kyc && kyc_redirect_url && kyc_redirect_url !== ""
    //         ? "QRCODE"
    //         : "FORM",
    //     redirectUrl: kyc_redirect_url || "",
    //   },
    // });

    this.onMenuSelect("cards/cardupdatepremium");

    // dialogRef.afterClosed().subscribe((result) => {});
  }
}
