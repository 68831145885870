import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-popup-export',
    templateUrl: './popup-export.component.html',
    styleUrls: ['./popup-export.component.css']
})
export class PopupExportComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<PopupExportComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() { }

    // events

    onClosePress(action: string): void {
        this.dialogRef.close(action);
    }
}
