import { Profile } from "./profile";
import { Order } from "./order";
import { Card } from "./card";

export class User {
  id: number;
  username: string;
  password: string;
  permission: string; // 0 - normal || 1 - Administrator
  status: number = -1;
  user_id: number; // associacao
  profiles: Profile[]; // others profiles with cards
  profile: Profile; // mainProfile
  assoc: Profile;
  orders: Order[];
  lastlogin: Date;
  isUpgradePremiumAvailable: boolean;

  get type() {
    return this.permission == "1" ? "Administrator" : "Normal";
  }

  get cards() {
    return this.profiles
      ? this.profiles.reduce((acc, p) => {
          acc = acc.concat(p.cards || []);
          return acc;
        }, [])
      : [];
  }

  get cardsUnlocked() {
    let cardsU = [];

    if (this.profile) {
      cardsU = cardsU.concat(this.profile.cardsUnlocked);
    }

    if (this.profiles) {
      cardsU = this.profiles.reduce((acc, p) => {
        acc = acc.concat(p.cardsUnlocked || []);
        return acc;
      }, cardsU);
    }
    return cardsU;
  }

  get allProfiles(): Profile[] {
    let profiles = [];

    if (this.profile) {
      profiles = profiles.concat(this.profile);
    }

    if (this.profiles) {
      profiles = profiles.concat(this.profiles);
    }

    return profiles;
  }

  get allCards(): Card[] {
    let cards = [];

    if (this.profile) {
      cards = cards.concat(this.profile.cards);
    }

    if (this.profiles) {
      cards = this.profiles.reduce((acc, p) => {
        acc = acc.concat(p.cards);
        return acc;
      }, cards);
    }

    return cards;
  }

  constructor(obj?: any) {
    Object.assign(this, obj);

    this.profiles = [];
    this.assoc = new Profile();
    this.orders = [];

    if (obj) {
      if ("profile" in obj) {
        this.profile = new Profile(obj.profile);
      }

      if ("profiles" in obj) {
        this.profiles = (obj.profiles || []).map((p) => new Profile(p));
      }

      if ("assoc" in obj) {
        this.assoc = new Profile(obj.assoc);
      }

      if ("orders" in obj) {
        let it;
        for (it = 0; it < obj.orders.length; it++) {
          this.orders.push(new Order(obj.orders[it]));
        }
      }
    }
  }

  isAdministrator() {
    return this.permission == "1";
  }

  // verifica qual é o profile com o cartao dado
  searchProfileForCardId(cardId): Profile {
    let profile = null;

    if (this.profile && this.profile.searchCardById(cardId)) {
      profile = this.profile;
    }

    if (!profile && this.profiles) {
      for (let i = 0; i < this.profiles.length; i++) {
        if (this.profiles[i].searchCardById(cardId)) {
          profile = this.profiles[i];
          i = this.profiles.length;
        }
      }
    }

    return profile;
  }

  searchCardById(id): Card {
    let card: Card = this.profile ? this.profile.searchCardById(id) : null;

    if (!card && this.profiles) {
      for (let i = 0; i < this.profiles.length; i++) {
        card = this.profiles[i].searchCardById(id);
        if (card) {
          i = this.profiles.length;
        }
      }
    }

    return card;
  }

  totalCards(): number {
    let total = 0;

    if (this.profile) {
      total = this.profile.cards.length;
    }

    if (this.profiles) {
      total += this.profiles.reduce((acc, p) => p.cards.length + acc, 0);
    }

    return total;
  }

  // se existir algum profile onde o kyc > 0 entao pode carregar cartoes
  canLoadCards(): boolean {
    if (this.profile && this.profile.kyc >= 1) {
      return true;
    }

    if (this.profiles) {
      for (let profile of this.profiles) {
        if (profile.kyc >= 1) {
          return true;
        }
      }
    }

    return false;
  }
}
