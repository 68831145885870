export class OrderCard {
    id: number;
    amount: number;
    message: string;
    status: number;
    order_id: number;
    inserted_at: string;
    updated_at: string;
    card_id: number;
    card_name: string;
    fee: number;
    balance: number;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}