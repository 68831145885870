import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-contact-support',
    templateUrl: './contact-support.component.html',
    styleUrls: ['../intro.component.css', './contact-support.component.css']
})
export class ContactSupportComponent implements OnInit, OnDestroy {
    private userSubscription: Subscription;
    timeoutEvent;

    typeError = 0; // 0 - nao aparece texto | 1 - contact support | 3 - 050 account error

    constructor(
        private router: Router,
        private userService: UserService,
    ) { }

    ngOnInit() {
        this.loadUser();
        this.userSubscription = this.userService.onUserChange().subscribe(modified => this.loadUser());
        this.userService.refreshUser();
    }

    ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        if (this.timeoutEvent) {
            clearTimeout(this.timeoutEvent);
        }
    }

    loadUser() {
        const user = this.userService.authenticated_user;

        if (user.status === 3) {
            this.typeError = 3;
        } else if (user.status != -1 && user.status !== 3) {
            this.router.navigate(['/app/cards/carddetails']);
        } else {
            this.typeError = 1;
            if (this.timeoutEvent) {
                clearTimeout(this.timeoutEvent);
            }

            this.timeoutEvent = setTimeout(() => this.userService.refreshUser(), 5000);
        }
    }
}
