<div class="mainContainer" mat-dialog-content>
    <button mat-raised-button class="my-button" (click)="onClosePress('csv')">
        CSV
    </button>
    <button mat-raised-button class="my-button" (click)="onClosePress('pdf')">
        PDF
    </button>
</div>
<div mat-dialog-actions class="btnContainer">
    <button mat-button class="ok" [mat-dialog-close]="false">{{'close' | translate}}</button>
</div>