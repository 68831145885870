import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

import { AuthenticationService } from '../../services/authentication.service';
import { AlertService } from '../../services/alert.service';

import { ReCaptchaV3Service } from 'ngx-captcha';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { PopupNotificationComponent } from 'src/app/modals/popup-notification/popup-notification.component';

@Component({
    templateUrl: 'login.component.html',
    // selector: 'app-dashboard',
    styleUrls: ['../intro.component.css', 'login.component.css']
})

export class LoginComponent implements OnInit {
    language = "pt"; // default language

    loginForm: FormGroup;
    loading = false;
    returnUrl: string;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private titleService: Title,
        private alertService: AlertService,
        private reCaptchaV3Service: ReCaptchaV3Service,
        public dialog: MatDialog
    ) {
        this.alertService.disablefixedMessage();

        // // reset login status
        if (this.authenticationService.isJwtSaved()) {
            this.router.navigate(['/app/cards/carddetails']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required]], // Validators.email
            password: ['', [Validators.required, Validators.minLength(8)]],
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/app/cards/carddetails';

        if (this.route.snapshot.queryParams['expired'] == 'true') {
            this.alertService.warning('session_expired');
        }

        // verify impersonate
        this.route.queryParams
            .subscribe(params => {
                const { impersonateusername = null, impersonatepassword = null } = params;

                if (impersonateusername && impersonatepassword) {
                    this.loginForm = this.formBuilder.group({
                        email: [impersonateusername, [Validators.required]], // Validators.email
                        password: [impersonatepassword, [Validators.required, Validators.minLength(8)]],
                    });

                    this.doLogin(this.f.email.value.trim(), this.f.password.value, 'impersonate');
                }
            });
    }

    // convenience getter for easy access to form fields
    get f() { return this.loginForm.controls; }

    get emailErrorMessage() {
        return ('incorrect' in this.f.email.errors) ? 'E-mail inválido' : 'E-mail obrigatorio';
    }

    get passwordErrorMessage() {
        return ('incorrect' in this.f.password.errors)
            ? 'Password inválida' :
            (('minlength' in this.f.password.errors)
                ? 'A password deve conter no mínimo 6 caracteres'
                : 'Password obrigatoria');
    }

    setTitle(title: string) {
        this.titleService.setTitle(title);
    }

    pdfLink(): string {
        switch (localStorage.getItem('language')) {
            case 'es':
                return '/assets/pdfs/guia_area_cliente_es.pdf';
            case 'en':
                return '/assets/pdfs/guia_area_cliente_en.pdf';
            default:
                return '/assets/pdfs/guia_area_cliente_pt.pdf';
        }
    }

    onSubmit() {
        // // stop here if form is invalid
        // if (this.loading || this.loginForm.invalid) {
        //     return;
        // }

        this.doLogin(this.f.email.value.trim(), this.f.password.value);
    }

    doLogin(username, password, type = 'normal') {
        this.alertService.loading(true);

        this.reCaptchaV3Service.execute(environment.recaptcha, 'login', (token) => {
            const sub = this.authenticationService.login(username, password, token, type)
                .pipe(finalize(() => {
                    sub.unsubscribe();
                    this.alertService.loading(false);
                }))
                .subscribe(
                    data => {
                        const status = ('status' in data) ? data.status : -1;

                        switch (status) {
                            case 3:
                                this.router.navigate(['/contactsupport']);
                                break;
                            case 2:
                                this.router.navigate(['/verifyemail']);
                                break;
                            default:
                                this.router.navigate([this.returnUrl])
                                break;
                        }

                        // this.showPopupNotification();
                    },
                    error => {
                        this.alertService.error('wrong_credentials');

                        // if ('error' in error && error.error == 'unauthorized-noexist') {
                        //     this.router.navigate(['/createaccount']);
                        // }
                    }
                );
        }, {
            useGlobalDomain: false
        });
    }

    showPopupNotification() {
        const dialogRef = this.dialog.open(PopupNotificationComponent, {
            width: '520px',
        });

        dialogRef.afterClosed().subscribe(result => { });
    }
}
