import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { PopupKycQrCodeComponent } from "src/app/modals/popup-kyc-qrcode/popup-kyc-qrcode.component";
import { Card } from "src/app/models/card";
import { AlertService } from "src/app/services/alert.service";
import { CardService } from "src/app/services/card.service";
import { TransferDataService } from "src/app/services/transferData.service";
import { UserService } from "src/app/services/user.service";
import { finalize } from "rxjs/operators";

const isEmptyString = (value) => value === null || value === undefined || value === "";

@Component({
  selector: "app-card-update-premium",
  templateUrl: "./card-update-premium.component.html",
  styleUrls: ["./card-update-premium.component.css"],
})
export class CardUpdatePremiumComponent implements OnInit, OnDestroy {
  private dataSubscription: Subscription;

  card: Card;

  get cardProfile() {
    const user = this.userService.authenticated_user;

    if (user) {
      return user.searchProfileForCardId(this.card.id);
    }

    return null;
  }

  get isAlertVisible() {
    const { kyc_error_code = null, kyc_error_message = null } = this.card || {};

    return (
      (kyc_error_code !== null && kyc_error_code !== "") ||
      (kyc_error_message !== null && kyc_error_message !== "")
    );
  }

  get hasKyc050Error() {
    const { kyc_error_code = null, kyc_error_message = null } = this.card || {};

    return (
      kyc_error_code === "050" ||
      kyc_error_message === "WAITING_FOR_REVIEW" ||
      kyc_error_message === "REVIEW_NOT_APPROVED"
    );
  }

  get isStartDisabled() {
    if (this.hasKyc050Error) {
      return true;
    }

    const {
      country_of_issuance = null,
      document_type = null,
      document_expiry_date = null,
      document_number = null,
    } = this.cardProfile || {};

    return (
      isEmptyString(country_of_issuance) ||
      isEmptyString(document_type) ||
      isEmptyString(document_number) ||
      isEmptyString(document_expiry_date)
    );
  }

  get errorReason() {
    const { kyc_response_status = null, kyc_error_message = null } = this.card || {};

    if (this.hasKyc050Error) {
      return "upgrade_premium_error_050";
    }

    switch (kyc_response_status) {
      case "0":
      case "4":
      case "5":
      case "6":
      case "7":
      case "17":
        return "Account is currently under KYC review";
      case "1":
      case "3":
        return "KYC is approved";
      case "2":
      case "12":
        return "User needs to resubmit perhaps trying from a different device or ID document or different lighting area";
      case "9":
        return "User has reached the limit of cards per this ID document";
      case "10":
      case "11":
        return "Not returned for video KYC";
      case "13":
      case "18":
      case "19":
        return "Not applicable for video KYC";
      case "14":
        return "User needs to finalise and complete an upload";
      default:
        return kyc_error_message;
    }
  }

  // prettier-ignore
  constructor(
    private alertService: AlertService,
    private userService: UserService,
    private cardService: CardService,
    private transferDataService: TransferDataService, 
    private router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.card =
      this.transferDataService.data && this.transferDataService.data.card
        ? this.transferDataService.data.card
        : new Card();

    this.dataSubscription = this.transferDataService.onDataUpdate().subscribe((modified) => {
      this.card = this.transferDataService.data.card || new Card();
    });
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }

  // --- events

  hereClick() {
    this.transferDataService.data = { card: this.card };
    this.router.navigate(["app/cards/carddetails"], { queryParams: {} });
  }

  handleOnPopupKycClick() {
    const { lock_kyc = false, id = null } = this.card || {};

    if (lock_kyc) {
      return;
    }

    this.alertService.loading(true);

    let language = "PT";

    switch (localStorage.getItem("language")) {
      case "es":
        language = "ES";
      case "en":
        language = "EN";
      default:
        language = "PT";
    }

    const subscription = this.cardService
      .requestKycDocumentsUploadAddressSimple(id, language)
      .pipe(
        finalize(() => {
          subscription.unsubscribe();
          this.alertService.loading(false);
        })
      )
      .subscribe(
        (address: string) => {
          this.dialog.open(PopupKycQrCodeComponent, {
            width: "860px",
            data: {
              cardHolderId: id,
              mode: "QRCODE",
              redirectUrl: address,
            },
          });
        },
        (error) => {
          console.log("error", error);

          this.alertService.error("erro ao requesitar address");
        }
      );

    // const { lock_kyc = false, kyc_redirect_url = null, id = null } = this.card || {};

    // // const mode = lock_kyc && kyc_redirect_url && kyc_redirect_url !== "" ? "QRCODE" : "FORM";
    // const mode = lock_kyc ? "QRCODE" : "FORM";

    // this.dialog.open(PopupKycQrCodeComponent, {
    //   width: mode === "QRCODE" ? "860px" : "420px",
    //   data: {
    //     cardHolderId: id,
    //     mode,
    //     redirectUrl: kyc_redirect_url || "",
    //   },
    // });
  }
}
