<div *ngIf="showMe" class="background" [style.height.px]="divHeight"></div>
<div *ngIf="showMe" class="container">

  <div class="sub-container">
    <div class="content">
      <div class="title">{{ 'resume' | translate }}</div>

      <div class="content-row" *ngFor="let d of deposit">
        <div class="content-row-left-column">{{ d.card.name }}</div>
        <div class="content-row-right-column">
          <div class="content-row-right-column-row">
            <span class="content-row-right-column-row-title">{{ 'total_amount' | translate }} :</span>
            <span class="content-row-right-column-row-value">{{ (d.value).toFixed(2) }} €</span>
          </div>

          <div class="content-row-right-column-row">
            <span class="content-row-right-column-row-title">{{ 'loading_fee' | translate }} :</span>
            <span class="content-row-right-column-row-value">-{{ commission.toFixed(2) }} €</span>
          </div>

          <div class="content-row-right-column-row">
            <span class="content-row-right-column-row-title">{{ 'amount_to_be_loaded' | translate }} :</span>
            <span class="content-row-right-column-row-value">{{ (d.value - commission).toFixed(2) }} €</span>
          </div>
        </div>
      </div>

      <!-- <div class="content-row" *ngIf="paymenteMethod == 4">
        <div class="content-row-left-column">Cartao escolhido</div>
        <div class="content-row-right-column">Novo</div>
      </div> -->

      <div class="content-total-main">
        <div *ngIf="paymenteMethod == 0" class="content-total-number my-form-input">
          <label for="input_value">{{ 'phone' | translate }}</label>
          <input id="input_value" type="phone" placeholder="{{ 'phone' | translate }}" value="{{ phone }}"
            (input)="onInputValueChange($event)">
        </div>
        <div class="content-total">
          <div class="content-total-title">{{ 'total' | translate }}</div>
          <div class="content-total-descr">€ {{ total.toFixed(2) }}</div>
        </div>
      </div>

      <div *ngIf="paymenteMethod == 4" class="message-order-processing-time-container">
        {{ 'credit_card_processing_message' | translate }}
      </div>
    </div>

    <div class="btn-container">
      <button class="btn-cancel" mat-raised-button color="warn"
        (click)="onClosePopup()">{{ 'cancel' | translate }}</button>
      <button class="btn-confirm" mat-raised-button color="warn"
        (click)="onConfirm()">{{ 'confirm' | translate }}</button>
    </div>
  </div>

</div>