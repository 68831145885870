import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, timeout } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Order } from '../models/order';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  basePath: string;

  constructor(private http: HttpClient) {
    this.basePath = environment.baseUrl;
  }

  listBtweenDates(startDate: string, endDate: string) {
    return this.http.get<Order[]>(`${this.basePath}/orders`, { params: { start_date: startDate, end_date: endDate } }).pipe(map(res => res.map(o => new Order(o))));
  }

  list() {
    return this.http.get<Order[]>(`${this.basePath}/orders`)
      .pipe(map(res => {
        let it;
        let ret = [];
        for (it = 0; it < res.length; it++) {
          ret.push(new Order(res[it]));
        }
        return ret;
      }));
  }

  listByCardIdBtweenDates(card_id: string, startDate: string, endDate: string) {
    const obj = { id: card_id, start_date: startDate, end_date: endDate };
    return this.http.post<Order[]>(`${this.basePath}/cards/orders`, obj).pipe(map(res => res.map(o => new Order(o))));
  }

  listByCardId(card_id: string) {
    const obj = { id: card_id };
    return this.http.post<Order[]>(`${this.basePath}/cards/orders`, obj).pipe(map(res => res.map(o => new Order(o))));
  }

  listByCardIdBtweenDatesExport(card_id: string, startDate: string, endDate: string, export_type: string) {
    const obj = { id: card_id, start_date: startDate, end_date: endDate, export: export_type, language: localStorage.getItem('language') }
    return this.http.post(`${this.basePath}/cards/orders`, obj, { responseType: 'blob' }).pipe(timeout(60000));
  }

  listByCardIdExport(card_id: string, export_type: string) {
    const obj = { id: card_id, export: export_type, language: localStorage.getItem('language') };
    return this.http.post(`${this.basePath}/cards/orders`, obj, { responseType: 'blob' }).pipe(timeout(60000));
  }

  create(order) {
    return this.http.post<Order>(`${this.basePath}/orders/new`, { order: order }).pipe(map(res => new Order(res)));
  }

  pdf(id) {
    return this.http.get(`${this.basePath}/orders/${id}/pdf`, { responseType: 'blob' }).pipe(timeout(60000));
  }

  export_as(type) {
    return this.http.get(`${this.basePath}/orders/as/${type}?language=` + localStorage.getItem('language'), { responseType: 'blob' }).pipe(timeout(60000));
  }

  csv() {
    return this.http.get(`${this.basePath}/orders/pdf`, { responseType: 'blob' }).pipe(timeout(60000));
  }

  pdfs() {
    return this.http.get(`${this.basePath}/orders/pdfs`, { responseType: 'blob' }).pipe(timeout(60000));
  }

  getByPurchaseID(pId) {
    return this.http.get<Order>(`${this.basePath}/orders/purchaseid/` + pId, {}).pipe(map(res => new Order(res)));
  }

  upload_proof(order_id, formData) {
    return this.http.post<any>(`${this.basePath}/orders/${order_id}/uploadproof`, formData);
  }
}
