<form [formGroup]="userForm" (ngSubmit)="verifyUserSubmit()" autocomplete="off">
    <div *ngIf="typeKyc == '0'">
        <div class="my-form-input {{ (firstname.errors?.pattern) ? 'my-form-input-error' : '' }}">
            <label for="firstname">{{ 'firstname' | translate }} <span class="red">*</span></label>
            <input id="firstname" maxlength="20" placeholder="{{ 'firstname' | translate }}" formControlName="firstname"
                [pattern]="namePattern" required>
        </div>

        <!-- <div class="my-form-input {{ (middlename.errors?.pattern) ? 'my-form-input-error' : '' }}">
            <label for="middlename">{{ 'middlename' | translate }}</label>
            <input id="middlename" maxlength="1" placeholder="{{ 'middlename' | translate }}" formControlName="middlename"
                [pattern]="name1Pattern">
        </div> -->

        <div class="my-form-input {{ (lastname.errors?.pattern) ? 'my-form-input-error' : '' }}">
            <label for="lastname">{{ 'lastname' | translate }} <span class="red">*</span></label>
            <input id="lastname" maxlength="10" placeholder="{{ 'lastname' | translate }}" formControlName="lastname"
                [pattern]="name1Pattern" required>
        </div>

        <div class="my-form-input {{ (phone.errors?.pattern) ? 'my-form-input-error' : '' }}">
            <label for="phone">{{ 'phone' | translate }} <span class="red">*</span></label>
            <input id="phone" placeholder="{{ 'phone' | translate }}" formControlName="phone" [pattern]="phonePattern"
                required>
        </div>

        <div class="my-form-input" style="max-width: 313px;">
            <label for="nationaliry">{{ 'nationaliry' | translate }} <span class="red">*</span></label>
            <select id="nationaliry" formControlName="nationaliry" style="max-width: 313px;">
                <option *ngFor="let c of countries" [value]="c">{{c}}</option>
            </select>
        </div>

        <div class="my-form-input input-phone {{ (dob.errors?.pattern) ? 'my-form-input-error' : '' }}">
            <label for="dob">{{ 'dob' | translate }} <span class="red">*</span></label>
            <div class="my-date-form">
                <input matInput [matDatepicker]="pickerDob" id="dob" placeholder="DD-MM-YYYY"
                    formControlName="dob" required>
                <mat-datepicker-toggle matSuffix [for]="pickerDob"></mat-datepicker-toggle>
                <mat-datepicker #pickerDob></mat-datepicker>
            </div>
        </div>
    </div>

    <div class="my-form-input" *ngIf="showCredentials">
        <label for="email">E-mail <span class="red">*</span></label>
        <ng-container *ngIf="emailAlreadyComplete()">
            <div class="emailDiv">
                {{ displayEmail() }}
            </div>
        </ng-container>

        <ng-container *ngIf="!emailAlreadyComplete()">
            <input id="email" type="email" placeholder="E-mail" formControlName="email" maxlength="40" required
                (change)="wrongEmail = 0" [class]="(wrongEmail == 1) ? 'input-invalid' : ''" [pattern]="emailPattern">
        </ng-container>
    </div>

    <ng-container *ngIf="!alreadyRegistered">
        <div class="my-form-input" *ngIf="showCredentials">
            <label for="password">Password <span class="red">*</span></label>
            <input id="password" type="password" placeholder="Password" formControlName="password" required
                (change)="onPasswordChange()">
            <div class="password-helper password-helper-{{wrongPassword}}">
                {{ 'password_chards' | translate }}
            </div>
        </div>

        <div class="my-form-input" *ngIf="showCredentials">
            <label for="confirmpassword">{{ 'confirm_password' | translate }} <span class="red">*</span></label>
            <input id="confirmpassword" type="password" placeholder="{{ 'confirm_password' | translate }}"
                formControlName="confirmpassword" required (change)="onPasswordChange()">
        </div>
    </ng-container>

    <div class="btn-container" *ngIf="showButton">
        <!-- {{ (typeKyc=='0') ? 'next' : 'create_account' | translate }} -->
        <button class="app-button" mat-raised-button color="warn">{{ 'next' | translate }}</button>
    </div>
</form>