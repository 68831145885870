<div class="route-title">
    <div class="flag-container">
        <app-flag flag="es" [selected]="selectedFlag == 'es'" (onPress)="selectLanguage('es')"></app-flag>
        <div style="margin-right:15px;"></div>
        <app-flag flag="en" [selected]="selectedFlag == 'en'" (onPress)="selectLanguage('en')"></app-flag>
        <div style="margin-right:15px;"></div>
        <app-flag flag="pt" [selected]="selectedFlag == 'pt'" (onPress)="selectLanguage('pt')"></app-flag>
    </div>

    <span>{{ title | translate }}</span>

    <div class="mcContainer">
        <img src="assets/images/mc_symbol.svg" alt="mc_symbol" />
    </div>
    <div class="route-title-bar"></div>
</div>

<div class="logo">
    <img src="assets/images/logo_ready.png" alt="logo">
</div>

<div class="activate-card" *ngIf="showActivateAccount">
    <!-- <a routerLink="/buycard" class="app-button app-button-create-account" mat-raised-button color="warn">Buy card</a> -->
    <div class="divider"></div>
    <a routerLink="/activatecard" class="app-button app-button-create-account" mat-raised-button color="warn">{{
        'activate card' | translate }}</a>
</div>

<div class="content-container {{ (isAboutCardRegister()) ? 'content-container-about-card-register' :'' }}">
    <router-outlet></router-outlet>
</div>

<div class="bottomLinkContainer" *ngIf="bottomLinkText != 'create_account'">
    <a routerLink='{{ bottomLink }}' class="bottom-link" (click)="onBackPress()">{{ bottomLinkText | translate }}</a>
</div>

<div class="reCAPTCHA-terms">
    This site is protected by reCAPTCHA and the Google
    <a href="https://policies.google.com/privacy">Privacy Policy</a> and
    <a href="https://policies.google.com/terms">Terms of Service</a> apply.
</div>