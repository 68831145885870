<h1 mat-dialog-title class="title">{{ 'verification_code' | translate }}</h1>
<div class="mainContainer" mat-dialog-content>
    <div class="subtitle">
        {{ 'enter_the_6_digit_code_generated' | translate }}
    </div>

    <div class="form-input-container {{ (errorVisible) ? 'my-form-input-error' : '' }}">
        <input placeholder="{{ 'verification_code' | translate }}" [(ngModel)]="code" (keyup)="verifyPhoneCode()">
        <div class="error-span">
            <ng-container *ngIf="errorVisible">
                {{ errorMessage | translate }}
            </ng-container>
        </div>
    </div>
</div>

<!-- mat-dialog-actions -->
<div class="btnContainer">
    <button mat-button class="ok" [mat-dialog-close]="false">{{'cancel' | translate}}</button>
    <button mat-button class="app-button app-button-login" mat-raised-button (click)="okClick()"
        cdkFocusInitial>{{'confirm' | translate}}</button>
</div>