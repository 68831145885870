<div class="card-hor-list" *ngIf="!cardListModeMobile">
  <!-- cards -->
  <div
    class="card-hor-list-card-separator {{ emptyCards.length == 0 ? '' : 'card-margin' }}"
    *ngFor="let card of availableCards; let i = index"
  >
    <app-box-card
      [style]="selectCard == card.id ? 'card-container-selected' : ''"
      [card]="card"
      (onCardPress)="onCardSelect(card)"
      (onEditNamePress)="onEditNamePress(card)"
    ></app-box-card>
  </div>

  <!-- placeholders -->
  <div
    class="empty-card card-hor-list-card-separator {{
      it < emptyCards.length - 1 ? 'card-margin' : ''
    }}"
    *ngFor="let elem of emptyCards; let it = index"
    style="cursor: pointer"
    (click)="onEmptyCardPress()"
  >
    <div class="placeholder-card">
      <img src="assets/images/card_placeholder.png" alt="placeholder" />
      <img src="assets/images/card_plus.png" alt="placeholder-plus" class="card-plus" />
    </div>
  </div>
</div>

<div class="mobile-list-cards" *ngIf="cardListModeMobile && cardSelected != null">
  <app-box-card
    style="card-container-selected"
    [card]="cardSelected"
    showOptions="true"
    (onOptionPress)="toggleMobileList()"
  ></app-box-card>

  <div class="mobile-fixed-list" *ngIf="mobileList">
    <div class="mobile-fixed-list-container">
      <div
        *ngFor="let menu of menus"
        class="left-container-cell {{
          menuSelected == menu.link ? 'left-container-cell-selected' : ''
        }}"
        (click)="onMenuSelect(menu.link)"
      >
        {{ menu.label | translate }}
      </div>

      <div
        *ngFor="let menu of menusAdministrator"
        class="left-container-cell {{
          menuSelected == menu.link ? 'left-container-cell-selected' : ''
        }}"
        (click)="onMenuSelect(menu.link)"
      >
        <span *ngIf="menu.link == 'lockorunlockcard' && unlockedCardSelected">{{
          "lock_card" | translate
        }}</span>
        <span *ngIf="menu.link == 'lockorunlockcard' && !unlockedCardSelected">{{
          "unlock_card" | translate
        }}</span>
        <span *ngIf="menu.link != 'lockorunlockcard'">
          {{ menu.label | translate }}
        </span>
      </div>
    </div>
  </div>

  <div class="card-expand-list" (click)="showAllCards = true">
    <img src="assets/images/expand_list.png" alt="show all" class="card-expand-list-img" />
  </div>

  <div class="mobile-list-all-cards" *ngIf="showAllCards">
    <div *ngFor="let card of availableCards; let i = index">
      <app-box-card
        [pressEffect]="true"
        [card]="card"
        (onCardPress)="onCardSelect(card)"
      ></app-box-card>
    </div>

    <div class="empty-card" (click)="onEmptyCardPress()">
      <div class="placeholder-card">
        <img src="assets/images/card_placeholder.png" alt="placeholder" />
        <img src="assets/images/card_plus.png" alt="placeholder-plus" class="card-plus" />
      </div>
    </div>
  </div>
</div>

<div class="m-container" *ngIf="cardSelected != null">
  <div class="left-container" *ngIf="!cardListModeMobile">
    <button
      *ngIf="isUpgradeCardAvailable"
      class="c-button"
      mat-raised-button
      color="warn"
      (click)="handleOnPopupKycClick()"
    >
      {{ "update_to_premium" | translate }}
    </button>

    <div
      *ngFor="let menu of menus"
      class="left-container-cell {{
        menuSelected == menu.link ? 'left-container-cell-selected' : ''
      }}"
      (click)="onMenuSelect(menu.link)"
    >
      {{ menu.label | translate }}
    </div>

    <div
      *ngFor="let menu of menusAdministrator"
      class="left-container-cell {{
        menuSelected == menu.link ? 'left-container-cell-selected' : ''
      }}"
      (click)="onMenuSelect(menu.link)"
    >
      <span *ngIf="menu.link == 'lockorunlockcard' && unlockedCardSelected">{{
        "lock_card" | translate
      }}</span>
      <span *ngIf="menu.link == 'lockorunlockcard' && !unlockedCardSelected">{{
        "unlock_card" | translate
      }}</span>
      <span *ngIf="menu.link != 'lockorunlockcard'">
        {{ menu.label | translate }}
      </span>
    </div>
  </div>

  <div class="right-contaner">
    <router-outlet></router-outlet>
  </div>
</div>
