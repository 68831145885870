<div class="card-container {{ style }}">
    <div class="card-container-normal {{ (pressEffect) ? 'ripple' : '' }}" (click)="cardPress()">
        <div class="card-name-container">
            <span class="card-name">{{ card?.name }}</span>
            <div class="card-name-container-btn-container" (click)="editNamePress()">
                <img src="assets/images/edit.png" />
            </div>
        </div>
        <span class="ard-number">Spark ID: {{ card.idFormated }}</span>
        <span class="card-value">€ {{ card?.availBal?.toFixed(2) }}</span>
        <div class="card-container-row">
            <span class="card-number" style="color: #FFC800;"
                *ngIf="card.status === 'no_active'">{{ 'not_active' | translate }}</span>
            <span class="card-number" style="color: #16C629;" *ngIf="card.status === 'unlocked'">{{ kycLabel() }}</span>
            <span class="card-number" style="color: #ED0A0A;"
                *ngIf="card.status !== 'no_active' && card.status !== 'unlocked'">{{ 'locked' | translate }}</span>
            <img src="assets/images/logo_ready.png" class="card-logo-img" alt="logo">
        </div>
    </div>
    <div class="card-container-locked" *ngIf="card.status !== 'unlocked'" (click)="cardPress()"></div>
    <div class="card-op-container">
        <img src="assets/images/lock.png" class="card-container-locked-img" alt="lock"
            *ngIf="card.status !== 'unlocked'">
        <div class="option-container" *ngIf="showOptions" (click)="optionPress()">
            <img src="assets/images/option.png" class="card-container-locked-img-option" alt="op">
        </div>
    </div>
</div>