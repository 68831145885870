<div class="mainContainer" mat-dialog-content>
  <form *ngIf="mode === 'FORM'" [formGroup]="form" autocomplete="off">
    <div class="my-form-input">
      <label for="country_of_issuance"
        >{{ "countryOfIssuance" | translate }} <span class="red">*</span></label
      >
      <select id="country_of_issuance" formControlName="countryOfIssuance">
        <option *ngFor="let c of countries" [value]="c">
          {{ c }}
        </option>
      </select>
    </div>

    <div class="my-form-input">
      <label for="document_type"
        >{{ "document_type" | translate }} <span class="red">*</span></label
      >
      <select id="document_type" formControlName="documentType">
        <option *ngFor="let dt of documentTypesAvailable" [value]="dt.value">
          {{ dt.label | translate }}
        </option>
      </select>
    </div>

    <div class="my-form-input">
      <label for="documentNumber">
        {{ "document_number" | translate }} <span class="red">*</span>
      </label>

      <input
        id="documentNumber"
        placeholder="{{ 'document_number' | translate }}"
        formControlName="documentNumber"
        required
      />
    </div>

    <div class="my-form-input">
      <label for="documentExpiryDate"
        >{{ "document_expiry_date" | translate }} <span class="red">*</span></label
      >
      <div class="my-date-form" style="max-width: 330px !important">
        <input
          matInput
          [matDatepicker]="picker"
          id="documentExpiryDate"
          placeholder="DD-MM-YYYY"
          formControlName="documentExpiryDate"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </div>
    </div>
  </form>

  <!-- qr-form -->

  <ng-container *ngIf="mode === 'QRCODE' && qrCodeUrl !== ''">
    <div class="mainContainer--qrcode-container">
      <div class="mainContainer--qrcode-container__left">
        <div class="mainContainer--qrcode-container__left--title">
          {{ "qrcode_form_title" | translate }}
        </div>

        <div class="mainContainer--qrcode-container__left--text">
          {{ "qrcode_form_desc1" | translate }}
        </div>

        <div class="mainContainer--qrcode-container__left--text">
          {{ "qrcode_form_desc2" | translate }}
        </div>

        <div class="mainContainer--qrcode-container__left--text">
          {{ "qrcode_form_desc3" | translate }}
        </div>

        <div class="mainContainer--qrcode-container__left--text">
          {{ "qrcode_form_desc4" | translate }}
        </div>
      </div>

      <div class="mainContainer--qrcode-container__right">
        <qrcode
          [qrdata]="qrCodeUrl"
          [width]="330"
          [margin]="0"
          [errorCorrectionLevel]="'M'"
        ></qrcode>

        <div class="mainContainer--link">
          <a [href]="qrCodeUrl" target="_blank">{{ qrCodeUrl }}</a>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div mat-dialog-actions class="btnContainer {{ mode === 'QRCODE' ? 'btnContainer-qrform' : '' }}">
  <ng-container *ngIf="mode === 'FORM'">
    <button mat-button class="ok" [mat-dialog-close]="false">
      {{ "cancel" | translate }}
    </button>

    <button
      [disabled]="form.invalid"
      mat-button
      class="app-button app-button-login next-button"
      mat-raised-button
      (click)="onNext()"
      cdkFocusInitial
    >
      {{ "next" | translate }}
    </button>
  </ng-container>

  <button *ngIf="mode === 'QRCODE'" mat-button class="ok-full" [mat-dialog-close]="false">
    {{ "close" | translate }}
  </button>
</div>
