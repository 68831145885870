import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Card } from 'src/app/models/card';
import { Translate } from 'src/app/locales/translate';

@Component({
  selector: 'app-box-card',
  templateUrl: './box-card.component.html',
  styleUrls: ['./box-card.component.css']
})
export class BoxCardComponent implements OnInit {
  @Input() pressEffect = false;
  @Input() card: Card;
  @Input() style = '';
  @Input() showOptions = false;
  @Output() onCardPress = new EventEmitter<void>();
  @Output() onOptionPress = new EventEmitter<void>();
  @Output() onEditNamePress = new EventEmitter<void>();

  constructor() { }

  ngOnInit() { }

  cardPress() {
    this.onCardPress.emit();
  }

  optionPress() {
    this.onOptionPress.emit();
  }

  kycLabel(): string {
    if (this.card) {
      switch (this.card.tier) {
        case '1':
          return 'Plus'
        case '2':
          return 'Premium';
        default:
          const translationPipe: Translate = new Translate();
          return translationPipe.transform('active');
      }
    }
  }

  editNamePress() {
    this.onEditNamePress.emit();
  }
}