import { Component, OnInit, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { OrderService } from '../services/order.service';
import { AlertService } from '../services/alert.service';

import { Order } from '../models/order';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import * as moment from 'moment';
import { first, finalize } from 'rxjs/operators';

import { generate_history_pdf } from '../pdf/genereta_pdf';
import { UserService } from '../services/user.service';
import { Card } from '../models/card';

const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
export const MY_FORMATS2 = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.css'],
    providers: [
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS2 },
    ],
})
export class OrdersComponent implements OnInit {
    orders: Order[];
    originalList: Order[];
    startDate;
    endDate;

    filterListVisible = false;
    exportPopup = false;

    constructor(
        private orderService: OrderService,
        private alertService: AlertService,
        private title: Title,
        private userService: UserService
    ) { }

    ngOnInit() {
        const endDate: Date = new Date();
        const startDate: Date = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate());

        this.startDate = moment(startDate);
        this.endDate = moment(endDate);

        this.title.setTitle('Histórico de transações');

        this.downloadData();
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if ((this.filterListVisible || this.exportPopup) && event.keyCode === 27) {
            this.filterListVisible = false;
            this.exportPopup = false;
        }
    }

    searchChanged(event) {
        const value = event.target.value;
        let order: Order;
        // let card: Card;
        let it;
        let amount;
        let entity;
        let ref;
        let ref_ps;
        let purchase_id;
        let updated_at;
        // // let itc;

        let datePipe = new DatePipe('en-US');

        if (value === '') {
            this.orders = this.originalList;
            return;
        }

        let ordersFiltered = []; // melhor so atribuir no fim senao o angular vai esta sermpre a actualizar a view
        for (it = 0; it < this.originalList.length; it++) {
            order = this.originalList[it];
            amount = '' + order.amount;
            entity = order.entity || '';
            ref = order.ref || '';
            ref_ps = order.ref_ps || '';
            purchase_id = ('' + order.purchase_id) || '';
            updated_at = (order.updated_at) ? datePipe.transform(order.updated_at, 'dd/MM/yyyy hh:mm:ss') : '';

            if (
                amount.indexOf(value) > -1 ||
                entity.indexOf(value) > -1 ||
                ref.indexOf(value) > -1 ||
                ref_ps.indexOf(value) > -1 ||
                purchase_id.indexOf(value) > -1 ||
                updated_at.indexOf(value) > -1
                // order.status === value
                // order.type === value
            ) {
                ordersFiltered.push(order);
            } else {
                // for(itc=0; itc < order.cards.length; itc++) {
                //     card = order.cards[itc];
                // }
            }
        }

        this.orders = ordersFiltered;
    }

    exportAs(type) {
        if (this.orders.length == 0) {
            return;
        }

        this.alertService.loading(true);
        this.orderService.export_as(type)
            .pipe(first())
            .pipe(finalize(() => this.alertService.loading(false)))
            .subscribe(
                x => {
                    // It is necessary to create a new blob object with mime-type explicitly set
                    // otherwise only Chrome works like it should
                    const newBlob = new Blob([x], {
                        type: (type == 'csv') ? 'text/csv' : 'application/pdf'
                    });

                    // IE doesn't allow using a blob object directly as link href
                    // instead it is necessary to use msSaveOrOpenBlob
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(newBlob);
                        return;
                    }

                    // For other browsers: 
                    // Create a link pointing to the ObjectURL containing the blob.
                    const data = window.URL.createObjectURL(newBlob);

                    var link = document.createElement('a');
                    link.href = data;
                    link.download = (type == 'csv') ? 'spark.csv' : 'application/pdf';
                    // this is necessary as link.click() does not work on the latest firefox
                    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

                    setTimeout(function () {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        window.URL.revokeObjectURL(data);
                        link.remove();
                    }, 100);

                    this.filterListVisible = false;
                    this.exportPopup = false;
                },
                error => this.alertService.error(error.error)
            );
    }

    exportPdf() {
        generate_history_pdf(this.orders, this.userService.authenticated_user, new Card(), this.startDate, this.endDate);
    }

    onDateInputChange(event, isStartDate) {
        if (isStartDate) {
            this.startDate = event.target.value;
        } else {
            this.endDate = event.target.value;
        }
    }

    onFilter() {
        this.downloadData();
    }

    downloadData() {
        const startDate = this.startDate.format(MOMENT_DATE_FORMAT);
        const endDate = this.endDate.format(MOMENT_DATE_FORMAT);

        this.alertService.loading(true);
        this.orderService.listBtweenDates(startDate, endDate)
            .pipe(first())
            .pipe(finalize(() => {
                this.alertService.loading(false);
                this.filterListVisible = false;
            }))
            .subscribe(
                data => {
                    this.originalList = (data || []).filter(o => o.status != 2);
                    this.orders = this.originalList;
                },
                error => this.alertService.error(error.error)
            );
    }

}