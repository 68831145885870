<div class="container">
    <div class="left-col">
        <div>
            {{ 'active the card' | translate }}
        </div>
        <div>
            {{ 'about-register-card_1' | translate }}
        </div>
        <div>
            <!-- {{ 'about-register-card_2' | translate }}
            <div style="height: 10px;"></div> -->
            {{ 'about-register-card_2-1' | translate }}
        </div>
        <div>
            {{ 'about-register-card_3' | translate }}
        </div>
    </div>
    <div class="right-col">
        <img src="assets//images/about_card.png" alt="about_card" class="about-image">
    </div>
</div>

<!-- <button class="button-ok" mat-raised-button color="warn">Ok</button> -->