import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ValidationService {
    basePath: string;

    constructor(private http: HttpClient) {
        this.basePath = environment.baseUrl;
    }

    generateValidationCode(language: string) {
        return this.http.post(`${this.basePath}/generatecode`, { language: language });
    }
}
