// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://staging.sparkcards.pt/api/v1',
  authKey: 'f5fd9070-6da5-4075-9543-2101230a5b9f',
  // baseUrl: 'https://staging.sparkcards.pt/api/v1',
  //authKey: 'c734e36c-00a8-4771-b873-0f7b2b2a5298',
  recaptcha: '6Lf0WPcfAAAAAIBhuh3sHcJHENIYvI50ksKJ7Zlb',
  // baseUrl: 'http://192.168.1.80:4010/api/v1', //'http://localhost:4200',
  // baseUrl: 'http://localhost:4010/api/v1', //'http://localhost:4200',
  // authKey: 'c734e36c-00a8-4771-b873-0f7b2b2a5298',
  // recaptcha: '6LdeK5oUAAAAAFLey5pJDwzOaiBvE25bvqkFW-fi',
  // recaptcha: '6LcYGboUAAAAAG-HsfLcG3Zb5uZx6mAeYIk7LMlA',
  mymoid: 'https://sandbox.mymoid.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
