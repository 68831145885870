export default {
  '4_digits_card_number': 'últimos 4 números de su tarjeta',
  '20 001 to 50 000 EUR': '20.001€ a 50.000€',
  '50 001 to 75 000 EUR': '50.001€ a 75.000€',
  '050_error_message': 'Su cuenta está siendo revisada, comuníquese con soporte para obtener más información.',
  '75 001 to 100 000 EUR': '75.001€ a 100.000€',
  '100 001 to 250 000 EUR': '100.001€ a 250.000€',
  '250 001 to 500 000 EUR': '250.001€ a 500.000€',
  '500 001 to 1M EUR': '500.001€ a 1.000.000€',
  '501 to 1000 EUR': '501€ a 1.000€',
  '1001 to 1500 EUR': '1.001€ a 1.500€',
  '1501 to 2000 EUR': '1.501€ a 2.000€',
  '2001 to 3000 EUR': '2.001€ a 3.000€',
  'about-register-card_1': 'Active su tarjeta SPARK enviando un SMS a uno de los números a continuación con la palabra PIN y el últimos 8 dígitos de su tarjeta.',
  'about-register-card_2': 'Portugal: +351 927 944 818',
  'about-register-card_2-1': 'España: +34 600 67 07 07',
  'about-register-card_3': 'Recibirá un SMS confirmando la activación de su tarjeta y su código PIN, que necesitará para hacer compras o para operaciones en los cajeros automáticos.',
  'access_information': 'Informacion de acceso',
  'account_will_be_removed': 'Si no confirma su correo electrónico y activa su cuenta, se eliminará después de 24 horas.',
  'activate card': 'Activar tarjeta',
  'active': 'Activo',
  'active the card': 'Activa tu tarjeta antes de registrarte!',
  'add': 'Añadir',
  // novas
  'add_new_account': '+ añadir nueva cuenta',
  'add_new_card': 'Añadir nueva tarjeta',
  'address': 'Dirección',
  'Agriculture': 'Agricultura',
  'already registered': 'Esta tarjeta ya está registrada.',
  'amount': 'Cantidad',
  'amount_to_be_loaded': 'Monto total a recargar',
  'api_internal_error': 'Se ha producido un error con su solicitud. Por favor contacta con el soporte.',
  'approved': 'Aprobado',
  'Arts / Culture / Sport': 'Arte / Cultura / Deporte',
  'back_desc': 'DNI español, Identificación de la Unión Europea, Carné de Conducir de la Unión Europea',
  'back_doc': 'Parte posterior del documento de identificación',
  'back_to_login': 'Volver a Iniciar sesión',
  'back_to_step_1': 'Volver al paso anterior',
  'bad file': 'El archivo no se admite.',
  'Bad Request': 'Se ha producido un error con su solicitud.',
  'Banking / Insurance / Finance / Auditing': 'Banca / Seguros / Finanzas / Auditoría',
  'buy': 'Comprar',
  'buy card': 'Comprar tarjeta',
  'cancel': 'Cancelar',
  // 'cant save on external database' : 'Seha producido un error interno. Por favor contacta con el soporte.',
  'cant save on external database': 'Servidor temporalmente no disponible. Por favor contacta con el soporte.',
  'card activated with success': 'The card was active.',
  'card blocked by api': 'Esta tarjeta se encuentra bloqueada para su uso. Por favor contacta con el soporte.',
  'card id or card number not exist': 'Algunos de los datos ingresados no son correctos, inténtalo de nuevo.',
  'card not activated': 'Esta tarjeta no está activa.',
  'card number': 'Número de tarjeta',
  'Card Purpose': 'Uso de la tarjeta',
  'card_activation_title': 'Cómo habilitar la tarjeta',
  'card_add_success': 'Se añadió una nueva tarjeta.',
  'card_already_registered': 'La tarjeta ya está registrada en la plataforma, asociada con otra cuenta, debe iniciar sesión en la cuenta con el correo electrónico:',
  'card_credentials': 'Crear acceso de tarjeta individual',
  'card_details': 'Datos de la tarjeta',
  'card_holder': 'SPARK ID',
  //novos2
  'card_number': 'Número de tarjeta',
  'cert_doc': 'Comprobante de domicilio',
  'cert_doc_desc': 'Certificado de Empadronamiento, Extracto Bancario inferior a 3 meses o Factura de suministros (teléfono, gas, electricidad, agua) inferior a 3 meses',
  'choose_amount': 'Elegir el importe',
  'choose_payment_type': 'Seleccione el método de pago',
  'city': 'Ciudad',
  'close': 'Cerrar',
  'confirm': 'Confirmar',
  'confirm_password': 'Confirmar contraseña',
  'Construction / Publicworks': 'Construcción / Trabajos públicos',
  'consult_pin': 'Consultar PIN',
  'contact support': 'Por favor entra en contacto con el soporte.',
  'continue with registration': 'Continue with registration',
  'country': 'País',
  'Craftwork / Trade': 'Craftwork / Trade',
  'create': 'Crear',
  'create_account': 'Crear Cuenta',
  'create_account_helper': 'Guía de Área de Cliente',
  'created with incomplete data': 'The account was been registered. However, the system didn\'t download all your transactions list.',
  'credentials': 'Claves de acceso',
  'credit_card': 'Credit Card',
  'credit_card_processing_message': 'El proceso de la tarjeta de crédito puede demorar de 10 a 15 minutos.',
  'currency': 'Moneda',
  'detalhe_cartao': 'Datos de la tarjeta',
  'Director / Owner': 'Director / Dueño',
  'dob': 'Fecha de nacimiento',
  'documents': 'Documentos',
  'edit': 'Editar',
  'Education': 'Educación',
  'email': 'Correo Electrónico',
  'email / card_holder': 'Email or SPARK ID',
  'email not the same previous saved': 'El correo electrónico no es el mismo registrado en los datos de la tarjeta.',
  'email_already_registered': 'Ya existe una cuenta con este correo electrónico. Inicia sesión para ingresar.',
  'email_enviado': 'El email fue reenviado con éxito.',
  'email_exists': 'Ya existe una cuenta con este correo electrónico. Inicia sesión para ingresar.',
  'email_not_same_as_in_card': 'La tarjeta ya está registrada con un correo electrónico diferente, use el mismo correo electrónico o póngase en contacto con nuestro soporte.',
  'email_verification_desc': 'Para continuar debe comprobar su correo electrónico.',
  'email_verification_title': 'Verificación de correo electrónico',
  'Employee / Worker': 'Empleado',
  'Employment Status': 'Estado laboral',
  'end_date': 'Fecha de finalización',
  'entity': 'Entidad',
  'erro': 'declinado',
  'error': 'Error',
  'Estate': 'Patrimonio',
  'Executive': 'Ejecutivo',
  'export': 'Exportar',
  'export_csv': 'Exportar csv',
  'export_pdf': 'Exportar PDF',
  'fee': 'Comisión',
  'file in review': 'El archivo se encuentra en evaluación',
  'file was saved': 'Este archivo se ha guardado correctamente.',
  'filter': 'filtro',
  'firstname': 'Nombre',
  'Food industry / Work from home / Hospitality / Tourism': 'Alimentación / Trabajo desde casa / Hospitalidad / Turismo',
  'forgot_password': '¿Has olvidado tu contraseña?',
  'form_error': 'El formulario contiene errores',
  'four_digits': '4 últimos dígitos de su tarjeta',
  'front_doc': 'Parte frontal del documento de identidad',
  'front_doc_desc': 'DNI español, Identificación de la Unión Europea, Carné de Conducir de la Unión Europea o Pasaporte',
  'fullname': 'Nombre completo',
  'Gamble or bet online': 'Apuestas por internet',
  'help': 'Guía de Documentos',
  'hide': 'Ocultar',
  'history': 'Historial de transacciones',
  'info_about_card': 'Información sobre la tarjeta',
  'invalid address': 'Dirección inválida.',
  'invalid card': 'Tarjeta inválida.',
  'invalid card user': 'Nose ha podido añadir la tarjeta al usuario.',
  'invalid code': 'Código de verificación invalido',
  'invalid email': 'El correo electrónico es inválido o ya existe.',
  'invalid kyc': 'Su identidad no se ha podido confirmar por completo. Por favor complete el proceso para poder usar todas las funcionalidades.',
  'invalid order': 'Orden inválida.',
  'invalid params': 'Parámetros incorrectos.',
  'invalid user': 'Usuario inválido.',
  'invalid_dob': 'La fecha de nacimiento no es válida. La edad mínima requerida es de 18 años.',
  'invalid_form_dates': 'Las fechas no son correctas.',
  'invalid_phone': 'Número de teléfono inválido',
  'it was not possible add cards to order': 'No fue posible agregar la tarjeta a la orden.',
  'it was not possible change the email': 'No se pudo actualizar el correo electrónico.',
  'it was not possible execute your order': 'No se pudo ejecutar la orden.',
  'it was not possible save file': 'No se pudo guardar el archivo',
  'kyc_level': 'Nivel SPARK',
  // 'kyc_not_complete_message' : 'Su KYC no esta completo. Por favor complete el KYC para subir su tarjeta al nivel SPARK Premium.',
  'kyc_not_complete_message': 'Las recargas a tu tarjeta están limitadas a 2.500€ por año.',
  'last login': 'Último acceso',
  'lastname': 'Apellidos',
  'LESS THAN 20 000 EUR': 'Menos de 20.000€',
  'LESS THAN 500 EUR': 'Menos de 500€',
  'limit orders': 'Has alcanzado el límite del número de recargas al día. Sólo puedes realizar 3 recargas al día.',
  'load_card': 'Recargar Tarjeta',
  'load_card_order': 'Recarga',
  'loading_fee': 'Comisión de recarga',
  'lock': 'Bloquear',
  'lock_card': 'Bloquear la tarjeta',
  'lock_card_1_msg': 'Pulsa el botón de abajo para bloquear temporalmente su tarjeta SPARK. Esta acción impide que tu tajeta pueda ser utilizada en cualquier transacción',
  'lock_card_2_msg': 'Podrás desbloquear la tarjeta en cualquier momento desde este menú.',
  'locked': 'Bloqueado',
  'login': 'Iniciar sesión',
  'logout': 'Cerrar sesión',
  'lost_card': 'Reportar la tarjeta como perdida',
  'lost_card_msg': 'Pulsa el boton de abajo para bloquear su tarjeta permanentemente en caso de pérdida o robo. Una vez realizado esta acción contacta con el servicio de atención al cliente para reemplazar tu tarjeta.',
  'Manager': 'Gestor',
  'Manufacturing / Maintenance': 'Manufacturing / Mantenimiento',
  'Medical / Paramedical': 'Médico / Paramédico',
  'middlename': 'Segundo nombre',
  'Monthly Income': 'Ingreso mensual',
  'MORE THAN 1M EUR': 'Más de 1.000.000€',
  'MORE THAN 3000 EUR': 'Más de 3.000€',
  'my_cards': 'Mis Tarjetas',
  'name': 'Nombre',
  'nationaliry': 'Nacionalidad',
  'new_account_step_1': 'Nueva cuenta paso 1',
  'new_account_step_2': 'Nueva cuenta paso 2',
  'new_account_step_3': 'Nueva cuenta paso 3',
  'new_account_step_3': 'NUEVA CUENTA PASO 3',
  'new_account_step_4': 'Nueva cuenta paso 4',
  'new_to_upload_1_file': 'Debes subir al menos un archivo',
  'newsletter': 'Newsletter',
  'next': 'Siguiente',
  // new form
  'NIF / NIE': 'NIF / NIE',
  'no': 'No',
  'not_active': 'No Activo',
  'Occupation': 'Actividad laboral',
  'optional_company_name': 'Nombre de la empresa (opcional)',
  'order was created but not saved on the system': 'Se produjo un error al crear el orden en el sistema. Por favor contacta con el soporte.',
  'order_error': "No se pudo ejecutar la orden. Por favor asegúrese que no ha alcanzado el número de tarjetas máximo permitido en su perfil",
  'order_id': 'Orden de identificación',
  'order_list_label': 'Valor de transacción / Saldo',
  'other_amount': 'Otra cantidad',
  'other_amount2': 'Introduce otra cantidad',
  'password': 'Contraseña',
  'password_chards': 'Su contraseña debe tener al menos 8 carácteres, incluyendo minúsculas, MAYÚSCULAS, números y símbolos',
  'password_min_length': 'La contraseña debe contener más de 6 carácteres.',
  'pay': 'Pagar',
  'Pay in store': 'Pagar en comercios',
  'Pay my bills': 'Pago mis facturas',
  'pdf_comissao': 'Comision',
  // PDF
  'pdf_data': 'Fecha',
  'pdf_entidade': 'Entidad',
  'pdf_mensagem': 'Comercio',
  'pdf_montante': 'Monto',
  'pdf_ordem': 'Orden',
  'pdf_referencia': 'Referencia',
  'pdf_referencia e payshop': 'Referencia / Shop',
  'pdf_resultado': 'Resultado',
  'pdf_saldo': 'Saldo',
  'pdf_Spark ID': 'SPARK ID',
  'pdf_tipo': 'Metodo',
  'pendente': 'pendiente',
  'Personal Expenses': 'Gastos personales',
  'Personal expenses': 'Gastos personales',
  'personal_information': 'Información Personal',
  'phone': 'Teléfono',
  'Politician / Elected Member of Parliament': 'Política / Miembro del parlamento',
  'privacy_policy': 'Política de Privacidad',
  'profile': 'Perfil',
  'profiles': 'Perfiles',
  'Public servant / Police / Military': 'Servicio público / Policía / Militar',
  'Receipt of income from business': 'Recibo ingresos de una empresa',
  'Receive my salary': 'Recibo mi salario',
  'Receive my social welfare payments': 'Recibo pagos de asistencia social',
  'recover': 'Recuperar',
  'recovery_password': 'Recuperar contraseña',
  'recovery_password_error': 'No se pudo actualizar la contraseña',
  'recovery_password_success': 'Se ha actualizado la contraseña.',
  'reference': 'Referencia',
  'removecard': 'Quitar la tarjeta',
  'renew_session': 'Por razones de seguridad, su sesión caducará en 20 segundos. ¿Quieres renovarlo?',
  'requesT_recovery_password_error': 'No se pudo completar la solicitud',
  'requesT_recovery_password_success': 'La solicitud se realizó con éxito. Por favor verifique el email y siga las instrucciones para recuperar la contraseña',
  'resend_email': 'Reenviar email',
  'resume': 'Resumen',
  'Retiree': 'Jubilado',
  'save': 'Guardar',
  'Save money': 'Ahorrar dinero',
  'search': 'Búsqueda',
  'secundary_id': 'SPARK ID',
  'Self employed': 'Autónomo',
  'Send or Receive Money transfer': 'Enviar o recibir transferencia de dinero',
  'send_pin_msg': 'Un SMS con el PIN será enviado al número de teléfono registrado en la tarjeta',
  'send_pin_success': 'El PIN fue enviado al teléfono registrado en la tarjeta.',
  'Services / IT': 'Servicios / Tecnología',
  'session_expired': 'Por razones de seguridad, su sesión ha expirado.',
  'show': 'Mostrar',
  'Social Security / NGO': 'Seguridad Social / ONG',
  'Spain': 'España',
  'special-error-1': 'Se ha producido un error, póngase en contacto con nuestro soporte.',
  'special-error-2': 'Atención al cliente disponible en:',
  'special-error-3': 'Lunes a viernes',
  'special-error-4': '9:00 a.m. a 6:00 p.m. CET.',
  'special-error-5': '(Cerrado en festivos nacionales)',
  'special-error-6': 'Correo electrónico: info@tarjetaspark.es',
  'special-error-7': 'España:',
  'special-error-8': 'Portugal:',
  'special-error-create-account-1': 'Estimado Usuario, hemos detectado un fallo en el registro de su Tarjeta SPARK.',
  'special-error-create-account-2': 'Por tal motivo le solicitamos que nos envíe una copia de su documento de identidad, así como un justificante de domicilio a info@tarjetaspark.es.',
  'special-error-create-account-3': 'Una vez recibidos le informaremos lo antes posible cuando esté completado el registro sin errores. En caso de no recibir los documentos solicitados en los siguientes 7 días su tarjeta podría ser restringida.',
  'special-error-create-account-4-1': 'COMPROBANTE DE IDENTIDAD: ',
  'special-error-create-account-4-2': 'Pasaporte, Documento de identidad de la Unión Europea, Permiso de conducir de la unión europea, Tarjeta de residencia española (NIE) o DNI.',
  'special-error-create-account-5-1': 'DOCUMENTOS PARA COMPROBAR DIRECCIÓN: ',
  'special-error-create-account-5-2': 'Certificado de empadronamiento, Factura de suministros (teléfono, gas, electricidad, agua) inferior a 3 meses: Extracto bancario inferior a 3 meses.',
  'special-error-create-account-6': 'Gracias por elegir la Tarjeta Spark, esperamos su documentación.',
  'start_date': 'Fecha de inicio',
  'state': 'Provincia',
  'statement date': 'Fecha de declaración',
  'stolen_card': 'Reportar la tarjeta como robada',
  'stolen_card_msg': 'Pulsa el boton de abajo para bloquear su tarjeta permanentemente en caso de pérdida o robo. Una vez realizado esta acción contacta con el servicio de atención al cliente para reemplazar tu tarjeta.',
  'Student': 'Estudiante',
  'submit': 'Enviar',
  'sucesso': 'aprovado',
  'sync_transactions_error': 'La sincronización de la transacción con su tarjeta falló.',
  'terms_and_conditions': 'Términos y Condiciones',
  'this field are invalid': 'Este campo no se completó correctamente.',
  'total': 'Total',
  // server errors
  'total number of cards exceeded': 'Excedió el número máximo de tarjetas.',
  'total_amount': 'Monto total',
  'tranfer': 'Tranferencia',
  'transactions': 'Transacciones',
  'transfer_error_v2': 'Se produjo un error al intentar cargar la tarjeta. Solo se pueden cargar [AMOUNT] € a la tarjeta [CARID].',
  'Travel expenses': 'Gastos de viaje',
  'Travel Expenses': 'Gastos de viaje',
  'Unemployed': 'Desempleando',
  'Unknown Error': 'Se produjo un error. Por favor contacta con el soporte.',
  'unlock': 'Desbloquear',
  'unlock_card': 'Desbloquear la tarjeta',
  'unlock_msg': 'Pulsa el botón para reestablecer el uso de su tarjeta.',
  'update_card_status_success': 'El estado de la tarjeta se ha actualizado con éxito.',
  'updated_at': 'Actualizado en',
  'updated_card_name_error': 'No se pudo actualizar el nombre de la tarjeta.',
  'updated_card_name_success': 'El nombre de la tarjeta se ha actualizado con éxito.',
  'updated_password_error': 'No se pudo actualizar la contraseña.',
  'updated_password_success': 'Se ha actualizado la contraseña.',
  'upload_error': 'No se pueden subir archivos.',
  'upload_success': 'Recibimos tus documentos y te contactaremos entre 24-72 hrs.',
  'validate_date': 'Fecha de validez',
  'Valor / Comissão': 'Monto / Comision',
  'verification_code': 'Código de verificación',
  // 'verify kyc or parameters': 'Por favor comprueba que sus datos de perfil han sido actualizados.',
  'verify kyc or parameters': 'El importe máximo autorizado por cada recarga de la tarjeta de crédito es de 250 €, con un máximo de 3 recargas por día.',
  'verify_email': 'Verificar email',
  'waiting': 'En espera',
  'wrong_credentials': 'El correo electrónico o la contraseña son incorrectos.',
  'wrong_password_and_confirm_password': 'Los campos contraseña y confirmar contraseña no contienen el mismo valor',
  'yes': 'Si',
  'you will receive 1 pin on phone': 'In a moment you will receive the pin on the phone number placed.',
  'YYYY-MM-DD': 'AAAA-MM-DD',
  'zipcode': 'Código postal',
  'upload_document_message_1': 'Estimado cliente,',
  'upload_document_message_2': 'te comunicamos que, en cumplimiento con la normativa legal, nos encontramos mejorando nuestra plataforma KYC para subir tu SPARK a nivel premium. Por ello, lamentamos informarte de que no podemos verificar documentos en este momento.',
  'upload_document_message_3': 'Muy pronto podrás disfrutar de un mejor servicio que permitirá la verificación de documentos en un período muy corto de tiempo. Agradecemos tu paciencia mientras actualizamos el sistema y lamentamos los inconvenientes que esta circunstancia pueda ocasionarte. ',
  'upload_document_message_4': 'Equipo SPARK',
  'premium_level': 'Nivel Premium',
  'update_to_premium': 'Actualizar a premium',
  'document_number': 'Número del Documento',
  'document_type': 'DNI Documento Nacional de Identidad',
  'document_expiry_date': 'Fecha de caducidad',
  'bi': 'DNI / NIE',
  'passport': 'Pasaporte',
  'driving_license': 'Carne de conducir',
  'start_validation_process': 'Iniciar proceso de validación',
  'qrcode_form_title': 'Para actualizar a Premium el proceso es:',
  'qrcode_form_desc1': '1 - Introduce los datos de tu documento de identidad',
  'qrcode_form_desc2': '2 - Escanea el código QR con tu teléfono móvil',
  'qrcode_form_desc3': '3 - Ejecuta el proceso de validación para tomar una fotografía de tu documento, tomar una selfie y mover tu rostro hacia la cámara',
  'qrcode_form_desc4': '4 - Finaliza el proceso y espera unos minutos para la validación',
  'Temporary employee': 'Empleado temporal',
  'Not applicable': 'No aplica',
  'Cryptocurrency': 'Criptomoneda',
  'For use abroad': 'Para uso en el extranjero',
  'Gaming or Betting on-line': 'Juegos o apuestas en línea',
  'Point of Sale purchases': 'Compras en Punto de Venta',
  'Receive income from my business activity': 'Recibir ingresos de mi actividad empresarial',
  'Savings': 'Ahorros',
  'Send or receive money transfers': 'Enviar o recibir transferencias de dinero',
  'Business Expenses': 'Gastos de negocio',
  'Corporate Account Less than 50K': 'Cuenta corporativa menos de 50000€',
  'Corporate Account 50K - 150K': 'Cuenta corporativa 50000€ - 150000€',
  'Corporate Account Above 150K': 'Cuenta corporativa superior a 150000€',
  'countryOfIssuance': 'País de emisión doc identificación',
  'qr_minimum_requirements_title': 'Requisitos mínimos de conexión',
  'qr_minimum_requirements_desc1': 'Cámara web y micrófono.',
  'qr_minimum_requirements_desc2': 'Conexión a Internet Wifi o 4G/5G.',
  'qr_minimum_requirements_desc3': 'Navegador: Chrome, Firefox, Edge, Safari.',
  'qr_minimum_requirements_desc4': 'Cierra otras aplicaciones.',
  'upgrade_premium_error': 'Revise toda la información personal ingresada. Si tienes algún problema envía un email a info@tarjetaspark.es.',
  'upgrade_premium_error_050': 'Su cuenta está bajo revisión. Si tienes algún problema envía un email a info@tarjetaspark.es.',
  'WAITING_FOR_REVIEW': 'Su cuenta está bajo revisión. Si tienes algún problema envía un email a info@tarjetaspark.es.',
  'REVIEW_NOT_APPROVED': 'Su cuenta no fue validada debido a datos incorrectos o inconsistentes. Si tienes algún problema envía un email a info@tarjetaspark.es.',
  'AUTO_INVALID': 'Su cuenta no fue validada debido a datos incorrectos o inconsistentes. Si tienes algún problema envía un email a info@tarjetaspark.es.',
  'ERROR': 'Su cuenta no fue validada debido a datos incorrectos o inconsistentes. Si tienes algún problema envía un email a info@tarjetaspark.es.',
  'AUTO_INVALID_NAME': 'El nombre ingresado no pudo ser validado. Si tienes algún problema envía un email a info@tarjetaspark.es.',
  'AUTO_INVALID_DOB': 'No se pudo validar la fecha de nacimiento ingresada. Si tienes algún problema envía un email a info@tarjetaspark.es.',
  'AUTO_INVALID_NAMEDOB': 'No fue posible validar el nombre y fecha de nacimiento ingresados. Si tienes algún problema envía un email a info@tarjetaspark.es.',
  'NO_ID_UPLOADED': 'No se enviaron los datos necesarios para completar el proceso KYC. Si tienes algún problema envía un email a info@tarjetaspark.es.',
  'MODAL_CODE_TEXT': 'Por favor revisa tu casilla de correo electrónico con el código de validación, si no lo recibes revisa tu SPAM.',
  'Account is currently under KYC review': 'La cuenta se encuentra actualmente bajo revisión KYC.',
  'KYC is approved': 'Cuenta aprobada.',
  'User needs to resubmit perhaps trying from a different device or ID document or different lighting area': 'El usuario debe volver a enviarlo, tal vez intentándolo desde un dispositivo o documento de identificación diferente o un área de iluminación diferente.',
  'User has reached the limit of cards per this ID document': 'El usuario ha alcanzado el límite de tarjetas según este documento de identidad.',
  'Not returned for video KYC': 'No devuelto para video KYC.',
  'Not applicable for video KYC': 'No aplicable para video KYC.',
  'User needs to finalise and complete an upload': 'El usuario debe finalizar y completar una carga.',
  'citizien document information': 'Datos de tu documento de identidad',
  'here': 'aquí',
}
