<form [formGroup]="addressForm" (ngSubmit)="verifyViewStep3DataForm()" autocomplete="off">
    <div class="my-form-input input-phone">
        <label for="address">{{ 'address' | translate }} <span class="red">*</span></label>
        <input id="address" placeholder="{{ 'address' | translate }}" formControlName="address" [pattern]="addressPattern" required maxlength="30">
        <span class="error-span"
            *ngIf="af.address.value != '' && !af.address.valid">{{ 'this field are invalid' | translate }}</span>
    </div>

    <div class="my-form-input input-phone">
        <label for="zipcode">{{ 'zipcode' | translate }} <span class="red">*</span></label>
        <input id="zipcode" placeholder="{{ 'zipcode' | translate }}" pattern="[a-zA-Z0-9 -]{5,15}"
            formControlName="zipcode" required>
        <span class="error-span"
            *ngIf="af.zipcode.value != '' && !af.zipcode.valid">{{ 'this field are invalid' | translate }}</span>
    </div>

    <div class="my-form-input input-phone">
        <label for="city">{{ 'city' | translate }} <span class="red">*</span></label>
        <input id="city" placeholder="{{ 'city' | translate }}" formControlName="city" maxlength="25" required>
        <span class="error-span"
            *ngIf="af.city.value != '' && !af.city.valid">{{ 'this field are invalid' | translate }}</span>
    </div>

    <!-- <div class="my-form-input input-phone">
        <label for="state">{{ 'state' | translate }} <span class="red">*</span></label>
        <input id="state" placeholder="{{ 'state' | translate }}" formControlName="state" maxlength="8"
            [pattern]="statePattern" required>
        <span class="error-span"
            *ngIf="af.state.value != '' && !af.state.valid">{{ 'this field are invalid' | translate }}</span>
    </div> -->

    <div class="my-form-input input-phone">
        <label for="country">{{ 'country' | translate }} <span class="red">*</span></label>
        <select id="country" formControlName="country">
            <option *ngFor="let c of countries" [value]="c">{{c}}</option>
        </select>
    </div>

    <div class="btn-container" *ngIf="showButton">
        <button type="submit" class="app-button" mat-raised-button color="warn">{{ 'next' | translate }}</button>
    </div>
</form>