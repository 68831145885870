<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div
        class="my-form-input {{ (errors['name'] == true) ? 'my-form-input-error' : '' }} input-phone {{ (name.errors?.pattern) ? 'my-form-input-error' : '' }}">
        <label for="name">{{ 'name' | translate }} <span class="red">*</span></label>
        <input id="name" placeholder="{{ 'name' | translate }}" formControlName="name"
            (keydown)="onChangeInput('name')">
    </div>

    <!-- {{ (email.errors?.pattern) ? 'my-form-input-error' : '' }} -->
    <div class="my-form-input {{ (errors['email'] == true) ? 'my-form-input-error' : '' }}">
        <label for="email">E-mail <span class="red">*</span></label>
        <input id="email" type="email" placeholder="E-mail" formControlName="email" required [pattern]="emailPattern"
            (keydown)="onChangeInput('email')">
        <span class="error-span"
            *ngIf="formControls.email.value != '' && !formControls.email.valid">{{ 'this field are invalid' | translate }}</span>
    </div>

    <div class="my-form-input input-phone {{ (errors['address'] == true) ? 'my-form-input-error' : '' }}">
        <label for="address">{{ 'address' | translate }} <span class="red">*</span></label>
        <input id="address" placeholder="{{ 'address' | translate }}" formControlName="address" required
            (keydown)="onChangeInput('address')">
        <span class="error-span"
            *ngIf="formControls.address.value != '' && !formControls.address.valid">{{ 'this field are invalid' | translate }}</span>
    </div>

    <div class="my-form-input input-phone {{ (errors['address2'] == true) ? 'my-form-input-error' : '' }}">
        <label for="address2">{{ 'address' | translate }} 2 <span class="red">*</span></label>
        <input id="address2" placeholder="{{ 'address' | translate }} 2" formControlName="address2"
            (keydown)="onChangeInput('address2')">
        <span class="error-span"
            *ngIf="formControls.address2.value != '' && !formControls.address2.valid">{{ 'this field are invalid' | translate }}</span>
    </div>

    <div class="zipCityContainer">
        <div class="my-form-input input-zipcode {{ (errors['zipcode'] == true) ? 'my-form-input-error' : '' }}">
            <label for="zipcode">{{ 'zipcode' | translate }} <span class="red">*</span></label>
            <input id="zipcode" placeholder="{{ 'zipcode' | translate }}" [pattern]="zipPattern"
                formControlName="zipcode" required (keydown)="onChangeInput('zipcode')">
            <span class="error-span"
                *ngIf="formControls.zipcode.value != '' && !formControls.zipcode.valid">{{ 'this field are invalid' | translate }}</span>
        </div>

        <div class="my-form-input input-phone input-city {{ (errors['city'] == true) ? 'my-form-input-error' : '' }}">
            <label for="city">{{ 'city' | translate }} <span class="red">*</span></label>
            <input id="city" placeholder="{{ 'city' | translate }}" formControlName="city" required
                (keydown)="onChangeInput('city')">
            <span class="error-span"
                *ngIf="formControls.city.value != '' && !formControls.city.valid">{{ 'this field are invalid' | translate }}</span>
        </div>
    </div>

    <div
        class="my-form-input countryContainer {{ (errors['country'] == true) ? 'my-form-input-error' : '' }}">
        <label for="country">{{ 'country' | translate }} <span class="red">*</span></label>
        <select id="country" formControlName="country" (change)="onChangeInput('country')">
            <option *ngFor="let c of countries" [value]="c">{{c}}</option>
        </select>
    </div>

    <div
        class="my-form-input phoneCountry {{ (errors['phone'] == true) ? 'my-form-input-error' : '' }}">
        <label for="phone">{{ 'phone' | translate }} <span class="red">*</span></label>
        <ngx-intl-tel-input [inputId]="'adsasd'" [cssClass]="'custom'" [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.Portugal" [maxLength]="15"
            [phoneValidation]="true" [separateDialCode]="separateDialCode" name="phone" formControlName="phone"
            (keydown)="onChangeInput('phone')">
        </ngx-intl-tel-input>
    </div>

    <div class="amountContainer">
        <div class="amountContainer-row">
            <div class="amountContainer-row-label">Quantidade:</div>
            <div class="amountContainer-row-value">
                <input type="number" id="numberOfCards" placeholder="{{ 'quantidade' | translate }}"
                    class="quantityInput" formControlName="numberOfCards" required
                    (keydown)="onChangeInput('numberOfCards')" [pattern]="numberOfCardsPattern"
                    (input)="onInputNumber($event)" min="1" max="99">
                <!-- javascript: if (this.value.length > 2) this.value = this.value.slice(0, 2); -->
            </div>
        </div>
        <div class="amountContainer-row">
            <div class="amountContainer-row-label">Valor:</div>
            <div class="amountContainer-row-value">
                <span>{{ cardValueWithoutIva.toFixed(2) }}</span>
                <span class="amountContainer-row-value-symbol">€</span>
            </div>
        </div>
        <div class="amountContainer-row">
            <div class="amountContainer-row-label">IVA:</div>
            <div class="amountContainer-row-value">
                <span>+{{ CARD_IVA }}</span>
                <span class="amountContainer-row-value-symbol">%</span>
            </div>
        </div>
        <div class="amountContainer-row">
            <div class="amountContainer-row-label">Total:</div>
            <div class="amountContainer-row-value">
                <span>{{ cardValueTotal.toFixed(2) }}</span>
                <span class="amountContainer-row-value-symbol">€</span>
            </div>
        </div>
    </div>

    <div class="buyContainer">
        <div class="payments-list-box-container" *ngIf="country.value == 'Portugal'">
            <div class="payments-list-box {{ (paymenteMethod == 0) ? 'payments-list-box-selected' : '' }}"
                (click)="paymenteMethod = 0">
                <img *ngIf="(paymenteMethod != 0)" src="assets/images/payments/normal/mb_way_normal.png" width="48"
                    height="24" alt="mb" />
                <img *ngIf="(paymenteMethod == 0)" src="assets/images/payments/white/mb_way_white.png" width="48"
                    height="24" alt="mb" />
            </div>
            <div class="payments-list-box-text">MB way</div>
        </div>
        <div class="payments-list-box-container" *ngIf="country.value == 'Portugal'">
            <div class="payments-list-box {{ (paymenteMethod == 2) ? 'payments-list-box-selected' : '' }}"
                (click)="paymenteMethod = 2">
                <img *ngIf="(paymenteMethod != 2)" src="assets/images/payments/normal/mb_normal.png" width="26"
                    height="30" alt="mb" />
                <img *ngIf="(paymenteMethod == 2)" src="assets/images/payments/white/mb_white.png" width="26"
                    height="30" alt="mb" />
            </div>
            <div class="payments-list-box-text">Multibanco</div>
        </div>
        <!-- <div class="payments-list-box-container" *ngIf="country.value == 'Portugal'">
            <div class="payments-list-box {{ (paymenteMethod == 3) ? 'payments-list-box-selected' : '' }}"
                (click)="paymenteMethod = 3">
                <img *ngIf="(paymenteMethod != 3)" src="assets/images/payments/normal/payshop_normal.png" width="26"
                    height="30" alt="payshop" />
                <img *ngIf="(paymenteMethod == 3)" src="assets/images/payments/white/payshop_white.png" width="26"
                    height="30" alt="payshop" />
            </div>
            <div class="payments-list-box-text">Payshop</div>
        </div> -->
        <!-- <div class="payments-list-box-container">
            <div class="payments-list-box {{ (paymenteMethod == 4) ? 'payments-list-box-selected' : '' }}"
                (click)="paymenteMethod = 4">
                <img *ngIf="(paymenteMethod != 4)" src="assets/images/payments/normal/mymoid.png" width="34" height="39"
                    alt="mymoid" />
                <img *ngIf="(paymenteMethod == 4)" src="assets/images/payments/white/mymoid.png" width="34" height="39"
                    alt="mymoid" />
            </div>
            <div class="payments-list-box-text">{{ 'credit_card' | translate }}</div>
        </div> -->
    </div>

    <div class="btn-container">
        <button class="app-button" mat-raised-button color="warn">{{ 'buy' | translate }}</button>
    </div>
</form>