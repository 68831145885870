import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.css']
})
export class FlagComponent implements OnInit {
    @Input() flag = '';
    @Input() selected = true;
    @Output() onPress = new EventEmitter<void>();

    constructor() {}

    ngOnInit() { }

    onFlagPress() {
        this.onPress.emit();
    }
}