import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { TransferDataService } from '../../services/transferData.service';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-transfer-popup',
    templateUrl: './transfer-popup.component.html',
    styleUrls: ['./transfer-popup.component.css']
})

export class TransferPopupComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    private userSubscription: Subscription;

    showMe = false;
    deposit = [];
    divHeight;

    paymenteMethod = -1;
    phone = '';
    commission = 0;

    creditCard = null;

    constructor(
        private transferDataService: TransferDataService,
        private router: Router,
        private alertService: AlertService,
        private userService: UserService,
    ) { }

    ngOnInit() {
        this.loadUser();
        this.userSubscription = this.userService.onUserChange().subscribe(modified => this.loadUser());

        this.subscription = this.transferDataService.onDataUpdate().subscribe(modified => {
            const operation = this.transferDataService.data.operation || null;

            if (operation == 'transfer-popup') {
                const clientRect = document.body.getBoundingClientRect() as DOMRect;
                const y = (clientRect.y < 0) ? (clientRect.y * -1) : clientRect.y;

                this.divHeight = y + clientRect.height;
                this.showMe = true;
                this.deposit = this.transferDataService.data.deposit || [];
                this.commission = this.transferDataService.data.commission || 0;
                this.creditCard = this.transferDataService.data.creditCard || null;

                window.scroll(0, 0); // tem de ser aqui ou pelo menos depois da conta do tamanho da div
            }

            this.paymenteMethod = this.transferDataService.data.paymenteMethod;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }
    }

    loadUser() {
        const user = this.userService.authenticated_user;
        this.phone = (user && user.profiles && user.profiles.length > 0) ? user.profiles[0].phone : '';
    }

    get total() {
        let total = 0;
        let it;

        for (it = 0; it < this.deposit.length; it++) {
            total += this.deposit[it].value;
        }

        return total;
    }

    onInputValueChange(input: any) {
        this.phone = input.target.value;
    }

    onClosePopup() {
        this.phone = '';
        this.showMe = false;
    }

    onConfirm() {
        if (this.paymenteMethod == 0 && (!this.phone || this.phone.length < 9)) {
            this.alertService.error('invalid_phone');
            return;
        }

        this.showMe = false;
        const data = this.transferDataService.data;
        let phone = '' + this.phone;

        phone = phone.substr(phone.length - 9);

        this.transferDataService.data = {
            operation: 'transfer-payment',
            deposit: data.deposit,
            paymenteMethod: data.paymenteMethod,
            commission: data.commission,
            phone: phone,
            creditCard: this.creditCard,
        };

        this.router.navigate(['app/transferpayment']);
        this.phone = '';
    }
}