export const CARD_SELECTED = 'CARD_SELECTED';

export const getNumberFromLocalStorage = (key: string, defaultValue: number = 0): number => {
    try {
        const value = localStorage.getItem(key);
        return parseInt(value, 10);
    } catch (er) {
        console.log(er);
    }
    return defaultValue;
}

