import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-popup-renew-session',
    templateUrl: './popup-renew-session.component.html',
    styleUrls: ['./popup-renew-session.component.css']
})
export class PopupRenewSessionComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<PopupRenewSessionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() { }

    // events

    okClick() {
        this.dialogRef.close(true);
    }
}
