import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { first, finalize } from 'rxjs/operators';

import { CardService } from '../services/card.service';
import { TransferDataService } from '../services/transferData.service';
import { AlertService } from '../services/alert.service';
import { UserService } from '../services/user.service';

import { Card } from '../models/card';
import { Profile } from '../models/profile';
import { Address } from '../models/address';
import { Translate } from '../locales/translate';
import { ActivatedRoute } from '@angular/router';

// import { formatCurrency, getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit, OnDestroy {
  private userSubscription: Subscription;
  private dataSubscription: Subscription;

  @ViewChild('cardname') cardname: ElementRef;
  @ViewChild('password') password: ElementRef;

  card: Card;
  profile: Profile = new Profile();
  address: Address = new Address();
  editCardName = false;
  isAdministrator = false;

  cardCredentialsExists = false;

  constructor(
    private titleService: Title,
    private transferDataService: TransferDataService,
    private cardService: CardService,
    private alertService: AlertService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(queryParams => this.editCardName = (queryParams['editCardName'] == 'true'));

    const translate = new Translate();
    this.titleService.setTitle(translate.transform('detalhe_cartao'));

    this.card = (this.transferDataService.data && this.transferDataService.data.card) ? this.transferDataService.data.card : new Card();
    this.loadUser();
    this.VerifyCardExists();
    this.userSubscription = this.userService.onUserChange().subscribe(modified => this.loadUser());

    // quando temos mais que 1 cartao
    this.dataSubscription = this.transferDataService.onDataUpdate().subscribe(modified => {
      this.card = this.transferDataService.data.card || new Card();
      this.VerifyCardExists();
    });
  }

  get currencyName() {
    // if (this.card) {
    //   console.log(getCurrencySymbol(this.card.basecurrency, 'norrow'))
    //   return getCurrencySymbol(this.card.basecurrency, 'wide');
    // }

    return 'EURO';
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
    this.dataSubscription.unsubscribe();
  }

  loadUser() {
    const user = this.userService.authenticated_user;

    this.isAdministrator = user.isAdministrator();
    this.profile = user.searchProfileForCardId(this.card.id);
    this.address = this.profile.getMainAddress();
  }

  VerifyCardExists() {
    if (!this.card) {
      return;
    }
    // load card credentials
    const subs1 = this.userService.card_user_exists(this.card.id)
      .pipe(finalize(() => subs1.unsubscribe()))
      .subscribe(
        data => this.cardCredentialsExists = true,
        error => this.cardCredentialsExists = false
      );
  }

  displayExpirationDate(expirationDate): string {
    return (expirationDate) ? [expirationDate.substring(0, 2), expirationDate.substring(2, 4)].join('/') : '';
  }

  onCardNameInputKeydown(event) {
    if (event.key === 'Enter') {
      this.changeCardNamePress();
    }
  }

  changeCardNamePress() {
    if (this.editCardName == true) {
      // entao é o save
      const new_name = this.cardname.nativeElement.value;

      if (new_name != this.card.name) {
        this.alertService.loading(true);

        this.cardService.updateName(this.card.id, new_name)
          .pipe(first())
          .pipe(finalize(() => {
            this.alertService.loading(false);
            this.editCardName = false;
          }))
          .subscribe(
            data => {
              this.card.name = new_name;
              this.alertService.success('updated_card_name_success');
            },
            error => this.alertService.error('updated_card_name_error')
          );
      } else {
        this.editCardName = false;
      }
    } else {
      this.editCardName = true;
      setTimeout(() => {
        if (this.cardname && this.cardname.nativeElement) {
          this.cardname.nativeElement.focus();
        }
      }, 500);
    }
  }

  onPasswordSave() {
    if (this.password.nativeElement.value.length < 6) {
      this.alertService.error('password_min_length');
    } else if (this.card.id) {
      this.alertService.loading(true);

      this.userService.create_user_for_card(this.card.id, this.password.nativeElement.value)
        .pipe(first())
        .pipe(finalize(() => this.alertService.loading(false)))
        .subscribe(
          data => this.alertService.success('updated_password_success'),
          error => this.alertService.error('updated_password_error')
        );
    }
  }

}
