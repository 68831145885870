<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="my-form-input {{ (cardholderid.errors?.minlength) ? 'my-form-input-error' : '' }}">
        <label for="cardholder">{{ 'secundary_id' | translate }} <span class="red">*</span></label>
        <input id="cardholder" placeholder="{{ 'card_holder' | translate }}" formControlName="cardholderid"
            minlength="12" required>
        <app-helper-input image="assets/images/cards/id-secundario_pt.png"></app-helper-input>
    </div>

    <div class="my-form-input">
        <label for="cardnumber">{{ 'four_digits' | translate }} <span class="red">*</span></label>
        <input id="cardnumber" placeholder="{{ 'four_digits' | translate }}" formControlName="cardnumber" required>
        <app-helper-input image="assets/images/cards/numero-primario_pt.png"></app-helper-input>
    </div>

    <div class="my-form-input">
        <label for="email">{{ 'email' | translate }} <span class="red">*</span></label>
        <input id="email" placeholder="{{ 'email' | translate }}" formControlName="email" maxlength="40"
            [pattern]="emailPattern" required>
    </div>

    <div class="my-form-input">
        <label for="phone">{{ 'phone' | translate }} <span class="red">*</span></label>
        <ngx-intl-tel-input [inputId]="'adsasd'" [cssClass]="'custom'" [preferredCountries]="preferredCountries"
            [enableAutoCountrySelect]="false" [enablePlaceholder]="true" [searchCountryFlag]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
            [selectedCountryISO]="CountryISO.Portugal" [maxLength]="15"
            [phoneValidation]="true" [separateDialCode]="separateDialCode" name="phoneNumber"
            formControlName="phoneNumber">
        </ngx-intl-tel-input>
    </div>

    <div class="btn-container">
        <button class="app-button" mat-raised-button color="warn">{{ 'active' | translate }}</button>
    </div>
</form>