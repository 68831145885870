export const EMPLOYMENTSTATUS = [
  "Director / Owner",
  "Employee / Worker",
  "Executive",
  "Manager",
  "Retiree",
  "Self employed",
  "Student",
  "Unemployed",
  "Temporary employee",
  // "Director / Owner",
  // "Executive",
  // "Manager",
  // "Employee / Worker",
  // "Self employed",
  // "Student",
  // "Retiree",
  // "Unemployed",
];

// --- SECTOR
export const OCCUPATION = [
  "Public servant / Police / Military",
  "Agriculture",
  "Craftwork / Trade",
  "Arts / Culture / Sport",
  "Banking / Insurance / Finance / Auditing",
  "Construction / Publicworks",
  "Education",
  "Manufacturing / Maintenance",
  "Medical / Paramedical",
  "Food industry / Work from home / Hospitality / Tourism",
  "Services / IT",
  "Social Security / NGO",
  "Politician / Elected Member of Parliament",
  "Not applicable",
  // "Public servant / Police / Military",
  // "Agriculture",
  // "Craftwork / Trade",
  // "Arts / Culture / Sport",
  // "Banking / Insurance / Finance / Auditing",
  // "Construction / Publicworks",
  // "Education",
  // "Manufacturing / Maintenance",
  // "Medical / Paramedical",
  // "Food industry / Work from home / Hospitality / Tourism",
  // "Services / IT",
  // "Social Security / NGO",
  // "Politician / Elected Member of Parliament  ",
];

export const CARDPURPOSE = [
  "Cryptocurrency",
  "For use abroad",
  "Gaming or Betting on-line",
  "Pay my bills",
  "Personal Expenses",
  "Point of Sale purchases",
  "Receive income from my business activity",
  "Receive my salary",
  "Receive my social welfare payments",
  "Savings",
  "Send or receive money transfers",
  "Travel Expenses",
  "Business Expenses",
  // "Receive my social welfare payments",
  // "Receive my salary",
  // "Pay my bills",
  // "Personal expenses",
  // "Receipt of income from business",
  // "Send or Receive Money transfer",
  // "Travel expenses",
  // "Gamble or bet online",
  // "Pay in store",
  // "Save money",
];

export const MONTHLYINCOME = [
  "LESS THAN 500 EUR",
  "501 to 1000 EUR",
  "1001 to 1500 EUR",
  "1501 to 2000 EUR",
  "2001 to 3000 EUR",
  "MORE THAN 3000 EUR",
  "Corporate Account Less than 50K",
  "Corporate Account 50K - 150K",
  "Corporate Account Above 150K",
  // "LESS THAN 500 EUR",
  // "501 to 1000 EUR",
  // "1001 to 1500 EUR",
  // "1501 to 2000 EUR",
  // "2001 to 3000 EUR",
  // "MORE THAN 3000 EUR",
];

export const ESTATE = [
  "LESS THAN 20 000 EUR",
  "20 001 to 50 000 EUR",
  "50 001 to 75 000 EUR",
  "75 001 to 100 000 EUR",
  "100 001 to 250 000 EUR",
  "250 001 to 500 000 EUR",
  "500 001 to 1M EUR",
  "MORE THAN 1M EUR",
  // "LESS THAN 20 000 EUR",
  // "20 001 to 50 000 EUR",
  // "50 001 to 75 000 EUR",
  // "75 001 to 100 000 EUR",
  // "100 001 to 250 000 EUR",
  // "250 001 to 500 000 EUR",
  // "500 001 to 1M EUR",
  // "MORE THAN 1M EUR",
];
