import { OrderCard } from './order_card';

export class Order {
    id: number;
    purchase_id: number;
    amount: number;
    entity: string;
    message: string;
    ref: string;
    ref_ps: string;
    status: number;
    type: number;
    user_id: number;
    cards: OrderCard[];
    inserted_at: string;
    updated_at: string;
    date: string;
    phone: string;
    fee: number;
    creditcard_id: number;

    constructor(obj?: any) {
        Object.assign(this, obj);

        this.cards = [];

        if (obj && 'orders_cards' in obj) {
            let it;
            for (it = 0; it < obj.orders_cards.length; it++) {
                this.cards.push(new OrderCard(obj.orders_cards[it]));
            }
        }
    }
}
