import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class RequestLocationService {
    constructor(private http: HttpClient) { }

    getLocation() {
        return this.http.jsonp(`https://freegeoip.app/json`, 'callback');
    }
}