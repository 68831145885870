import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isEmpty as _isEmpty } from 'lodash';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { AlertService } from 'src/app/services/alert.service';
import { CardService } from 'src/app/services/card.service';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-popup-phone-code',
    templateUrl: './popup-phone-code.component.html',
    styleUrls: ['./popup-phone-code.component.css']
})
export class PopupPhoneCodeComponent implements OnInit {
    errorVisible = false;
    errorMessage = 'this field are invalid';

    code = '';
    cardholderid = '';
    phoneNumber = '';
    countryCode = '';

    constructor(
        private dialogRef: MatDialogRef<PopupPhoneCodeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private cardService: CardService,
        private alertService: AlertService,
    ) { }

    ngOnInit() {
        this.cardholderid = this.data.cardholderid || '';
        this.phoneNumber = this.data.phoneNumber || '';
        this.countryCode = this.data.countryCode || '';
    }

    verifyPhoneCode(): void {
        this.errorMessage = 'this field are invalid';

        if (_isEmpty(this.code)) {
            this.errorVisible = false;
        } else {
            this.errorVisible = !(this.code.length < 7);
        }
    }

    // events

    okClick(): void {
        if (!this.errorVisible) {
            this.alertService.loading(true);

            this.reCaptchaV3Service.execute(environment.recaptcha, 'activatecard', (token) => {
                const sub1 = this.cardService.activate(this.cardholderid, this.phoneNumber, this.countryCode, this.code, token)
                    .pipe(finalize(() => {
                        this.alertService.loading(false);
                        sub1.unsubscribe();
                    }))
                    .subscribe(
                        resp => this.dialogRef.close(true),
                        err => {
                            this.errorVisible = true;
                            this.errorMessage = 'invalid code';
                        }
                    );
            });
        }
    }
}
