<h1 mat-dialog-title class="title">Cambios en los niveles SPARK</h1>
<div class="mainContainer" mat-dialog-content>
    <p>
        Estimados Clientes,
    </p>

    <p>
        Debido a la aplicación de la DIRECTIVA (UE) 2018/843, traspuesta por el Real Decreto Ley 7/2021 que se modifica la
        Ley 10/2010, los límites de recarga de la tarjetas prepago han sido modificados.
    </p>

    <p>
        A pesar de que logramos extender los límites de recarga de nuestra producto SPARK, ayer nuestro emisor PCSIL, ha
        cambiado con efecto inmediato dichos límites de acuerdo a lo siguiente:
    </p>

    <p>
        SPARK: Una sola recarga de máximo 150€
        <br>
        SPARK PLUS: recargas de 150€ / año
        <br>
        SPARK PREMIUM: recargas de 8.000€ / mes
    </p>

    <p>
        Para más información por favor verificar la información completa en la sección NIVELES de la web:
        <a href="https://www.tarjetaspark.es/niveles">https://www.tarjetaspark.es/niveles</a>
    </p>

    <p>
        Rogamos disculpen las molestias que pueda causar estos cambios pero son normativas de obligado cumplimiento a nivel
        europeo.
    </p>

    <p>
        Equipo SPARK
    </p>
</div>

<!-- mat-dialog-actions -->
<div class="btnContainer">
    <button mat-button class="ok" [mat-dialog-close]="false">{{'cancel' | translate}}</button>
</div>