import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  showTransferPopup = false;

  constructor(
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.showTransferPopup = this.userService.authenticated_user != null;

    this.userService.onUserChange().subscribe(modified => {
      this.showTransferPopup = this.userService.authenticated_user != null;
    });

    if (!(localStorage.getItem('language'))) {
      const browserLanguage = navigator.language;

      if (browserLanguage.indexOf('pt') > -1) {
        localStorage.setItem('language', 'pt');
      } else if (browserLanguage.indexOf('en') > -1) {
        localStorage.setItem('language', 'en');
      } else {
        localStorage.setItem('language', 'es');
      }
    }
  }

}
