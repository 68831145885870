import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';

// used to create fake backend

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GMaterialModule } from './material-module';

// GUARDS
import { AuthGuard } from './guards/auth.guard';

// INTERCEPTORS
import { JwtInterceptor } from './helpers/jwt.interceptor';
// import { ErrorInterceptor } from './helpers/error.interceptor';

import { AlertService } from './services/alert.service';
import { AuthenticationService } from './services/authentication.service';
import { UserService } from './services/user.service';
import { CardService } from './services/card.service';
import { CardPopupService } from './services/card-popup.service';
import { ValidationService } from './services/validation.service';

// LAYOUTS
import { AuthLayoutComponent } from './_layout/auth-layout/auth-layout.component';
import { AppLayoutComponent } from './_layout/app-layout/app-layout.component';
import { AppCardLayoutComponent } from './_layout/app-layout/card-layout/card-layout.component';

// COMPONENTS

// INTRO
import { LoginComponent } from './intro/login/login.component';
import { RecoveryPasswordComponent } from '../app/intro/recovery-password/recovery-password.component';
import { AboutCardRegisterComponent } from '../app/intro/about-card-register/about-card-register.component';
import { CreateAccountComponent } from '../app/intro/create-account/create-account.component';

import { AlertComponent } from './directives/alert.component';
import { CardComponent } from './card/card.component';
import { TransactionsComponent } from './card/transactions/transactions.component';
import { TransferComponent } from './transfer/transfer.component';
import { CardPopupComponent } from '../app/card/card-popup/card-popup.component'
import { InformationComponent } from '../app/user/information/information.component';
import { TransferPopupComponent } from '../app/transfer/transfer-popup/transfer-popup.component';
import { TransferPaymentComponent } from '../app/transfer/transfer-payment/transfer-payment.component';
import { TransferCardListComponent } from './transfer/transfer-card-list/transfer-card-list.component';
import { OrdersComponent } from '../app/orders/orders.component';
import { VerifyEmailComponent } from '../app/intro/verify-email/verify-email.component';
import { CardProfileComponent } from './card/card-profile/card-profile.component';

// VISUAL COMPONENTS
import { BoxCardComponent } from './visual_components/box-card/box-card.component';
import { SimpleBoxCardComponent } from './visual_components/simple-box-card/simple-box-card.component';
import { OrderLineComponent } from './visual_components/order-line/order-line.component';
import { FlagComponent } from './visual_components/flag/flag.component';
import { FormUserComponent } from './user/form-user/form-user.component';
import { HelperInputComponent } from './visual_components/helper-input/helper-input.component';
import { QRCodeModule } from 'angularx-qrcode';

// PIpeline
import { Translate } from '../app/locales/translate';

import { NgxCaptchaModule } from 'ngx-captcha';
import { RequestLocationService } from './services/request-location.servvice';
import { FormUserStep0Component } from './user/form-user/form-user-step0/form-user-step0.component';
import { FormUserStep1Component } from './user/form-user/form-user-step1/form-user-step1.component';
import { FormUserStep2Component } from './user/form-user/form-user-step2/form-user-step2.component';
import { FormUserStep3Component } from './user/form-user/form-user-step3/form-user-step3.component';
import { CardPopupAddCardComponent } from './card/card-popup/card-popup-add-card/card-popup-add-card.component';
import { PopupRenewSessionComponent } from './modals/popup-renew-session/popup-renew-session.component';
import { PopupExportComponent } from './modals/popup-export/popup-export.component';
import { PopupCodeComponent } from './modals/popup-code/popup-code.component';
import { PopupKycQrCodeComponent } from './modals/popup-kyc-qrcode/popup-kyc-qrcode.component';
import { AccessInformationComponent } from './user/access-information/access-information.component';
import { ContactSupportComponent } from './intro/contact-support/contact-support.component';
import { ActivateCardComponent } from './intro/activate-card/activate-card.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PopupPhoneCodeComponent } from './modals/popup-phone-code/popup-phone-code.component';
import { PopupActivationCardComponent } from './modals/popup-activation-card/popup-activation-card.component';
import { BuyCardComponent } from './intro/buy-card/buy-card.component';
import { PopupNotificationComponent } from './modals/popup-notification/popup-notification.component';
import { CardUpdatePremiumComponent } from './card/card-update-premium/card-update-premium.component';

@NgModule({
  declarations: [
    AppComponent,
    AlertComponent,
    CardPopupComponent,
    TransferPopupComponent,
    CardPopupAddCardComponent,

    // layouts
    AuthLayoutComponent,
    AppLayoutComponent,
    AppCardLayoutComponent,

    // components
    LoginComponent,
    RecoveryPasswordComponent,
    AboutCardRegisterComponent,
    CreateAccountComponent,
    CardComponent,
    TransactionsComponent,
    InformationComponent,
    TransferComponent,
    TransferPaymentComponent,
    TransferCardListComponent,
    OrdersComponent,
    VerifyEmailComponent,
    CardProfileComponent,
    AccessInformationComponent,
    ContactSupportComponent,
    ActivateCardComponent,
    BuyCardComponent,
    CardUpdatePremiumComponent,

    // visual components
    BoxCardComponent,
    SimpleBoxCardComponent,
    OrderLineComponent,
    FlagComponent,
    FormUserComponent,
    FormUserStep0Component,
    FormUserStep1Component,
    FormUserStep2Component,
    FormUserStep3Component,
    HelperInputComponent,

    // POPUPS
    PopupRenewSessionComponent,
    PopupExportComponent,
    PopupCodeComponent,
    PopupKycQrCodeComponent,
    PopupPhoneCodeComponent,
    PopupActivationCardComponent,
    PopupNotificationComponent,

    // PIPELINES
    Translate,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    GMaterialModule,
    NgxCaptchaModule,
    HttpClientJsonpModule,
    FormsModule,
    NgxIntlTelInputModule,
    QRCodeModule
  ],
  entryComponents: [
    PopupRenewSessionComponent,
    PopupExportComponent,
    PopupCodeComponent,
    PopupKycQrCodeComponent,
    PopupPhoneCodeComponent,
    PopupActivationCardComponent,
    PopupNotificationComponent
  ],
  providers: [
    AuthGuard,
    AlertService,
    AuthenticationService,
    UserService,
    CardService,
    CardPopupService,
    RequestLocationService,
    ValidationService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
