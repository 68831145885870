<div class="bcontainer">
    <div class="container">
        <div class="row" *ngFor="let c of cards; let i = index" (click)="onCardSelect(i)">
            <div class="marker-container ">
                <div class="marker" *ngIf="!isCardSelected(i)"></div>
                <div class="marker-selected" *ngIf="isCardSelected(i)">
                    <div class="marker-selected-dot"></div>
                </div>
            </div>
            <div class="cardname">{{ c.name }}</div>
            <div class="cardnumber">{{ c.idFormated }}</div>
        </div>
    
        <div class="row-btns">
            <button  mat-raised-button class="cancel-btn" (click)="close()">{{ 'cancel' | translate }}</button>
            <button  mat-raised-button class="add-btn" (click)="addCards()">{{ 'add' | translate }}</button>
        </div>
    </div>
</div>