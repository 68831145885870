export class Address {
    address: string;
    city: string;
    country: string;
    state: string;
    zipcode: string;
    main: number;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}