import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, finalize } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { AlertService } from './alert.service';

import { User } from '../models/user';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    basePath: string;
    private userP: User;
    private dataUpdateSubject = new Subject<any>();
    private downloading = false;

    constructor(
        private http: HttpClient,
        private router: Router,
        private alertService: AlertService,
        private authenticateService: AuthenticationService,
    ) {
        this.basePath = environment.baseUrl;
        this.userP = null;

        // console.log('INITED');
        // this.download_user();
    }

    get authenticated_user(): User {
        if (this.authenticateService.isJwtSaved() == false) {
            return null;
        }

        if (this.userP != null) {
            // console.log('>> USer guardado na base de dados <<');
            return this.userP;
        }

        // console.log('>> Faz Download do USer <<');
        this.download_user();
        return new User();
    }

    clear() {
        this.downloading = false;
        this.userP = null;
    }

    onUserChange(): Observable<any> {
        return this.dataUpdateSubject.asObservable();
    }

    create_user_for_card(card_id: string, password: string) {
        return this.http.post<any>(`${this.basePath}/users/createforcard`, { card_id: card_id, password: password });
    }

    card_user_exists(card_id: string) {
        return this.http.post<any>(`${this.basePath}/users/carduserexists`, { card_id: card_id });
    }

    refreshUser() {
        this.downloading = false;
        this.download_user();
    }

    private download_user() {
        if (this.downloading) {
            return;
        }

        this.downloading = true;

        this.http.post<User>(`${this.basePath}/users/details`, {})
            .pipe(map(res => new User(res)))
            .pipe(finalize(() => { this.downloading = false; }))
            .subscribe(
                data => {
                    this.userP = data;
                    this.dataUpdateSubject.next(true);
                },
                error => {
                    this.alertService.error('Bad Request');
                    this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
                }
            );
    }

    list_users_associated() {
        return this.http.get<any>(`${this.basePath}/users/associate`);
    }

    resend_confirm_email(token) {
        return this.http.post(`${this.basePath}/users/resendemail`, { recaptcha_token: token });
    }

    verifyEmail(token: string, email: string) {
        return this.http.post(`${this.basePath}/verifyemail`, { recaptcha_token: token, email: email });
    }

    update_email(email: string, code: string) {
        return this.http.post<any>(`${this.basePath}/users/updateemail`, { email: email, code: code });
    }

    update_password(password: string, code: string) {
        return this.http.post<any>(`${this.basePath}/users/updatepassword`, { password: password, code: code });
    }

    isStrValidAsPassword(str: string): boolean {
        const verifySpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!verifySpecialChar.test(str)) {
            return false;
        }

        const format = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        if (!format.test(str)) {
            return false;
        }

        return true;
    }

}