import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { CreditCard } from '../models/creditcard';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CreditCardService {
    basePath: string;

    constructor(private http: HttpClient) {
        this.basePath = environment.baseUrl;
    }

    list() {
        return this.http.get<CreditCard[]>(`${this.basePath}/creditcards`).pipe(map(res => res.map(c => new CreditCard(c))))
    }
}
