import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { finalize } from "rxjs/operators";
import { AlertService } from "src/app/services/alert.service";
import { CardService } from "src/app/services/card.service";
import { countryList } from "src/app/models/countries";

@Component({
  selector: "app-popup-kyc-qrcode",
  templateUrl: "./popup-kyc-qrcode.component.html",
  styleUrls: ["./popup-kyc-qrcode.component.css"],
})
export class PopupKycQrCodeComponent implements OnInit {
  qrCodeUrl = "";

  form: FormGroup;

  documentTypes = [
    { label: "bi", value: "Id" },
    { label: "passport", value: "Passport" },
    // { label: "driving_license", value: "DrivingLicense" },
  ];

  mode = "FORM"; // 'FORM', 'QRCODE'

  cardHolderId: string = null;

  countries = countryList;

  get documentTypesAvailable() {
    if (this.form.controls.countryOfIssuance.value === "Spain") {
      return [{ label: "bi", value: "Id" }];
    }

    return this.documentTypes;
  }

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<PopupKycQrCodeComponent>,
    private alertService: AlertService,
    private cardService: CardService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    const { cardHolderId = null, mode, redirectUrl } = this.data || {};

    this.cardHolderId = cardHolderId;

    this.mode = mode || "FORM";
    this.qrCodeUrl = redirectUrl || "";

    this.form = this.formBuilder.group({
      documentNumber: ["", Validators.required],
      documentType: ["Id", Validators.required],
      documentExpiryDate: ["", Validators.required],
      countryOfIssuance: ["", Validators.required],
    });
  }

  // events

  onClosePress(action: string): void {
    this.dialogRef.close(action);
  }

  onNext(): void {
    if (this.form.valid) {
      this.alertService.loading(true);

      let language = "PT";

      switch (localStorage.getItem("language")) {
        case "es":
          language = "ES";
        case "en":
          language = "EN";
        default:
          language = "PT";
      }

      const subscription = this.cardService
        .requestKycDocumentsUploadAddress(
          this.cardHolderId,
          this.form.controls.documentNumber.value,
          this.form.controls.documentType.value,
          this.form.controls.documentExpiryDate.value,
          this.form.controls.countryOfIssuance.value,
          language
        )
        .pipe(
          finalize(() => {
            subscription.unsubscribe();
            this.alertService.loading(false);
          })
        )
        .subscribe(
          (address: string) => {
            this.dialogRef.updateSize("860px");

            this.qrCodeUrl = address;
            this.mode = "QRCODE";
          },
          (error) => {
            console.log("error", error);

            this.alertService.error("erro ao requesitar address");
          }
        );
    }
  }
}
