import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TransferDataService {
    private dataUpdateSubject = new Subject<any>();
    private dataP;

    constructor() {
        this.dataP = undefined;
    }

    get data() { return this.dataP; }

    set data(data) {
        this.dataP = data;
        this.dataUpdateSubject.next(true);
    }

    clear() { this.data = undefined; }

    onDataUpdate(): Observable<any> {
        return this.dataUpdateSubject.asObservable();
    }
}