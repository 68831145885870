import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isEmpty as _isEmpty } from 'lodash';
import { SearchCountryField, CountryISO } from 'ngx-intl-tel-input';
import { CardService } from 'src/app/services/card.service';
import { environment } from 'src/environments/environment';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { finalize } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { Router } from '@angular/router';

const PURCHASE_TYPE = {
    MBWAY: 0,
    MB: 2,
    PAYSHOP: 3,
    MYMOID: 4,
    UTRUST: 5
};

@Component({
    selector: 'app-buy-card',
    templateUrl: './buy-card.component.html',
    styleUrls: ['../intro.component.css', './buy-card.component.css']
})
export class BuyCardComponent implements OnInit {
    namePattern = '[a-zA-Z ]{1,20}';
    emailPattern = '[a-zA-Z0-9@.]{1,40}';
    phonePattern = '[0-9]{7,15}';
    zipPattern = '[a-zA-Z0-9 -]{5,15}';
    numberOfCardsPattern = '[0-9]{1,2}';

    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    preferredCountries: CountryISO[] = [CountryISO.Portugal, CountryISO.Spain, CountryISO.UnitedKingdom];

    form: FormGroup;
    countries = ['Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hungary', 'Iceland', 'Ireland', 'Italy', 'Latvia', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands', 'Norway', 'Poland', 'Portugal', 'Romania', 'Slovakia', 'Slovenia', 'Spain', 'Sweden', 'United Kingdom'];

    paymenteMethod = -1;

    errors = {};

    CARD_VALUE = 10;
    CARD_IVA = 0.23;

    constructor(
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private cardService: CardService,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private alertService: AlertService,
        private router: Router,
    ) { }

    ngOnInit() {
        const defaultCountry = 'Portugal'; // (language == 'pt') ? 'Portugal' : 'Spain';

        this.form = this.formBuilder.group({
            name: ['', [Validators.required, Validators.pattern(this.namePattern)]],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required]], //, Validators.pattern(this.phonePattern) 
            address: ['', Validators.required],
            address2: [''], // , Validators.required
            zipcode: ['', Validators.required],
            country: [defaultCountry, Validators.required],
            city: ['', Validators.required],
            numberOfCards: [1, [Validators.required, Validators.pattern(this.numberOfCardsPattern)]],
        });
    }

    get formControls() {
        return this.form.controls;
    }

    get name() {
        return this.form.get('name');
    }

    get email() {
        return this.form.get('email');
    }

    get phone() {
        return this.form.get('phone');
    }

    get address() {
        return this.form.get('address');
    }

    get address2() {
        return this.form.get('address2');
    }

    get zipcode() {
        return this.form.get('zipcode');
    }

    get country() {
        return this.form.get('country');
    }

    get city() {
        return this.form.get('city');
    }

    get numberOfCards(): number {
        return this.form.get('numberOfCards').value;
    }

    get cardValueTotal(): number {
        return this.numberOfCards * this.CARD_VALUE;
    }

    get cardValueWithoutIva(): number {
        return this.cardValueTotal - (this.cardValueTotal * this.CARD_IVA);
    }

    // ------
    // events
    // ------

    onInputNumber(ev): void {
        let value = ev.target.value || '';
        if (value.length > 2) {
            value = value.slice(0, 2);
        }
        // else if (value.length <= 0) {
        //     // value = '0';
        // }
        ev.target.value = value;
    }

    onChangeInput(field: string): void {
        if (field in this.errors) {
            delete this.errors[field];
        }
    }

    onSubmit(): void {
        if (this.form.invalid) {
            this.errors = Object.keys(this.form.controls).reduce((acc, key) => {
                if (this.form.controls[key].status === 'INVALID') {
                    acc[key] = true;
                }
                return acc;
            }, {});
        } else if (this.numberOfCards <= 0) {
            this.alertService.error('buy at least one card');
        } else if (this.paymenteMethod < 0) {
            this.alertService.error('choose_payment_type');
        } else {
            this.reCaptchaV3Service.execute(environment.recaptcha, 'buycard', (token) => {
                const sub = this.cardService.buycard(
                    this.paymenteMethod,
                    this.name.value,
                    this.address.value,
                    this.address2.value,
                    this.email.value,
                    (this.phone.value && 'e164Number' in this.phone.value) ? this.phone.value.e164Number : '',
                    this.zipcode.value,
                    this.country.value,
                    this.city.value,
                    this.numberOfCards,
                    token,
                    localStorage.getItem('language') || 'en'
                )
                    .pipe(finalize(() => {
                        sub.unsubscribe();
                        this.alertService.loading(false);
                    })).subscribe(
                        data => {
                            this.alertService.success('card was requested successfully');
                            this.router.navigate(['/login']);
                        },
                        error => this.alertService.error('cant request buy card')
                    );
            });
        }
    }

}
