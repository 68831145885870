<div class="title">
    {{ 'access_information' | translate }}
</div>

<div class="list">
    <div class="list-item">
        <span class="list-item-title">{{ 'email' | translate }}</span>
        <div class="list-item-description">
            <span *ngIf="!editingEmail">{{ user?.username }}</span>
            <div *ngIf="editingEmail" class="my-form-input {{ (user.username == '') ? 'my-form-input-error' : '' }}">
                <input #inputEmail type="email" id="email" placeholder="Email" value="{{ user.username }}"
                    (keydown)="onInputKeydown($event, 'email')">
            </div>
        </div>
        <div class="list-item-btn-container" (click)="onEmailOperationBtnPress()">
            <span *ngIf="!editingEmail">{{ 'edit' | translate }}</span>
            <span *ngIf="editingEmail">{{ 'save' | translate }}</span>
        </div>
    </div>
    <div class="list-item">
        <span class="list-item-title">{{ 'password' | translate }}</span>
        <div class="list-item-description">
            <span *ngIf="!editingPassword">{{ user?.password }}</span>
            <div *ngIf="editingPassword" class="my-form-input">
                <input #inputPassword type="password" id="password" placeholder="{{ 'password' | translate }}"
                    (keydown)="onInputKeydown($event, 'password')" [(ngModel)]="password">
                <div class="password-helper password-helper-{{ passwordStatus }}">
                    {{ 'password_chards' | translate }}
                </div>
            </div>
        </div>
        <div class="list-item-btn-container" (click)="onPasswordOperationBtnPress()">
            <span *ngIf="!editingPassword">{{ 'edit' | translate }}</span>
            <span *ngIf="editingPassword">{{ 'save' | translate }}</span>
        </div>
    </div>
</div>