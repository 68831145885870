<form [formGroup]="verifyCardForm" (ngSubmit)="verifyCardSubmit()" autocomplete="off">
    <div class="my-form-input" *ngIf="showName">
        <label for="name">{{ 'name' | translate }} <span class="red">*</span></label>
        <input id="name" placeholder="{{ 'name' | translate }}" formControlName="name" required>
    </div>

    <div class="my-form-input">
        <label for="cardholder">{{ 'secundary_id' | translate }} <span class="red">*</span></label>
        <input id="cardholder" placeholder="{{ 'card_holder' | translate }}" formControlName="cardholder" required>
        <app-helper-input image="assets/images/cards/id-secundario_pt.png"></app-helper-input>
    </div>

    <div class="my-form-input">
        <label for="cardnumber">{{ 'four_digits' | translate }} <span class="red">*</span></label>
        <input id="cardnumber" placeholder="{{ 'four_digits' | translate }}" formControlName="cardnumber" required>
        <app-helper-input image="assets/images/cards/numero-primario_pt.png"></app-helper-input>
    </div>

    <div class="btn-container" *ngIf="showButton">
        <button class="app-button" mat-raised-button color="warn">{{ 'next' | translate }}</button>
    </div>
</form>