export default {
  '4_digits_card_number': 'Últimos 4 dígitos do seu número principal',
  '20 001 to 50 000 EUR': '20 001 a 50 000 EUR',
  '50 001 to 75 000 EUR': '50 001 a 75 000 EUR',
  '050_error_message': 'A sua conta está a ser revista, por favor contacte o suporte para mais informações.',
  '75 001 to 100 000 EUR': '75 001 a 100 000 EUR',
  '100 001 to 250 000 EUR': '100 001 a 250 000 EUR',
  '250 001 to 500 000 EUR': '250 001 a 500 000 EUR',
  '500 001 to 1M EUR': '500 001 a 1M EUR',
  '501 to 1000 EUR': '501 a 1000 EUR',
  '1001 to 1500 EUR': '1001 a 1500 EUR',
  '1501 to 2000 EUR': '1501 a 2000 EUR',
  '2001 to 3000 EUR': '2001 a 3000 EUR',
  'about-register-card_1': 'Ativar seu cartão antes do registo enviando uma SMS para um dos números abaixo com a palavra PIN e os últimos 8 digitos do seu cartão.',
  'about-register-card_2': 'Portugal: +351 927 944 818',
  'about-register-card_2-1': 'Espanha: +34 600 67 07 07',
  'about-register-card_3': 'Receberá um SMS a confirmar ativação do seu cartão e o seu código PIN, que será usado em qualquer ATM ou compra.',
  'access_information': 'Dados de acesso',
  'account_will_be_removed': 'Caso não confirme o seu email e active a sua conta, a mesma será eliminada ao fim de 24 horas.',
  'activate card': 'Ativar cartão',
  'active': 'Activo',
  'active the card': 'Ativar seu cartão antes do registo!',
  'add': 'Adicionar',
  'add_new_account': '+ adicionar nova conta',
  'add_new_card': 'Adicionar novo cartão',
  'address': 'Endereço',
  'Agriculture': 'Agricultura',
  'already registered': 'Este cartão já se encontra registado.',
  'amount': 'Montante',
  'amount_to_be_loaded': 'Total a ser carregado',
  'api_internal_error': 'Ocorreu um erro com o seu pedido. Por favor contacte o suporte.',
  'approved': 'Aprovado',
  'Arts / Culture / Sport': 'Artes / Cultura / Desporto',
  'back_desc': 'Cartão de cidadão de um país da EU, carta de condução EU',
  'back_doc': 'Parte traseira do documento de\n identificação pessoal',
  'back_to_login': 'Regressar ao início de sessão',
  'back_to_step_1': 'Voltar ao passo anterior',
  'bad file': 'Ficheiro não suportado.',
  'Bad Request': 'Ocorreu um erro com o seu pedido.',
  'Banking / Insurance / Finance / Auditing': 'Bancário / Seguros / Finanças / Auditoria',
  'buy': 'Comprar',
  'buy card': 'Comprar cartão',
  'cancel': 'Cancelar',
  // 'cant save on external database': 'Ocorreu um erro interno. Por favor contacte o suporte.',
  'cant save on external database': 'Servidor temporariamente indisponível. Por favor contacte o suporte.',
  'card activated with success': 'O cartão foi activo com sucesso.',
  'card blocked by api': 'Este cartão encontra-se bloqueado para uso. Por favor contacte o suporte.',
  'card id or card number not exist': 'Algum dos dados introduzidos não se encontra correto, tente novamente.',
  'card not activated': 'Este cartão não se encontra activo.',
  'card number': 'Número de cartão',
  'Card Purpose': 'Finalidade do cartão',
  'card_activation_title': 'Como activar cartão',
  'card_add_success': 'O cartão foi adicionado.',
  'card_already_registered': 'O cartão já se encontra registado na plataforma, associado a outra conta, terá de fazer login na conta com o email:',
  'card_credentials': 'Criar acesso individual ao cartão',
  'card_details': 'Dados do Cartão',
  'card_holder': 'SPARK ID',
  // novos2
  'card_number': 'Nome do Cartão',
  'cert_doc': 'Certidão Comercial (Necessário apenas para empresas)',
  'cert_doc_desc': 'Certificado de residência, Extracto bancário com menos de 3 meses, factura de serviços básico (telefone, gás, electricidade, água) com menos de 3 meses.',
  'choose_amount': 'Escolher o montante',
  'choose_payment_type': 'Escolha o método de pagamento',
  'city': 'Cidade',
  'close': 'Fechar',
  'confirm': 'Confirmar',
  'confirm_password': 'Confirmar Password',
  'Construction / Publicworks': 'Construção / Obras públicas',
  'consult_pin': 'Consultar PIN',
  'contact support': 'Por favor, contacte o suporte.',
  'continue with registration': 'Continuar com o registo',
  'country': 'País',
  'Craftwork / Trade': 'Artesanato / Comércio',
  'create': 'Criar',
  'create_account': 'Criar Conta',
  'create_account_helper': 'Guia de Área de Cliente',
  'created with incomplete data': 'A conta foi creada com sucesso. No entanto, O sistema não consegui descarregar as suas transaçôes.',
  'credentials': 'Credenciais de acesso',
  'credit_card': 'Cartão de crédito',
  'credit_card_processing_message': 'O processamento por cartão de crédito por demorar entre 10 e 15 minutos.',
  'currency': 'Moeda',
  'detalhe_cartao': 'Detalhes do Cartão',
  'Director / Owner': 'Director / Proprietário',
  'dob': 'Data de Nascimento',
  'documents': 'Documentos',
  'edit': 'Editar',
  'Education': 'Educação',
  'email': 'Email',
  'email / card_holder': 'Email or SPARK ID',
  'email not the same previous saved': 'O e-mail não é o mesmo existente nos dados do cartão.',
  'email_already_registered': 'Já existe uma conta com este email. Faça login para entrar.',
  'email_enviado': 'O email foi reenviado com sucesso.',
  'email_exists': 'Já existe uma conta com este email. Faça login para entrar.',
  'email_not_same_as_in_card': 'O cartão já se encontra registado com um email diferente, utilize o mesmo email ou contact o nosso suporte.',
  'email_verification_desc': 'Para prosseguir deve verificar o seu email.',
  'email_verification_title': 'Verificação de Email',
  'Employee / Worker': 'Empregado / Trabalhador',
  'Employment Status': 'Situação de emprego',
  'end_date': 'Data de fim',
  'entity': 'Entidade',
  'erro': 'erro',
  'error': 'Erro',
  'Estate': 'Património pessoal',
  'Executive': 'Executivo',
  'export': 'Exportar',
  'export_csv': 'Exportar csv',
  'export_pdf': 'Exportar PDF',
  'fee': 'Comissão',
  'file in review': 'O ficheiro encontra-se em avaliação',
  'file was saved': 'Este ficheiro foi guardado com sucesso.',
  'filter': 'Filtrar',
  'firstname': 'Primeiro Nome',
  'Food industry / Work from home / Hospitality / Tourism': 'Indústria alimentar / Trabalho em casa / Hotelaria / Turismo',
  'forgot_password': 'Esqueceu-se da sua password?',
  'form_error': 'O formulário não se encontra devidamente preenchido',
  'four_digits': '4 últimos dígitos do seu número principal',
  'front_doc': 'Parte frontal do documento de\n identificação pessoal',
  'front_doc_desc': 'Cartão de cidadão de um país da EU, carta de condução EU ou Passport',
  // novas
  'fullname': 'Nome Completo',
  'Gamble or bet online': 'Jogos ou apostas online',
  'help': 'Guía de Documentos',
  'hide': 'Ocultar',
  'history': 'Histórico de transações',
  'info_about_card': 'Informações sobre o Cartão',
  'invalid address': 'Endereço incorrecto.',
  'invalid card': 'Cartão inválido.',
  'invalid card user': 'Não foi possível adicionar o cartão ao utilizador.',
  'invalid code': 'Código de verificação inválido',
  'invalid email': 'Este email não é válido ou já se encontra em uso.',
  'invalid kyc': 'O seu registo ainda não se encontra completo. Por favor, complete o seu registo para poder usar todas as funcionalidades.',
  'invalid order': 'Ordem inválida.',
  'invalid params': 'Parâmetros incorrectos.',
  'invalid user': 'Utilizador inválido.',
  'invalid_dob': 'A data de nascimento não é válida. Esta plataforma dirige-se a utilizadores com idade igual ou superior a 18 anos.',
  'invalid_form_dates': 'As datas não se encontram correctas.',
  'invalid_phone': 'O nùmero de telefone não é válido',
  'it was not possible add cards to order': 'Não foi possivel adicionar o cartão a ordem.',
  'it was not possible change the email': 'Não foi possível actualizar o email.',
  'it was not possible execute your order': 'Não foi possível executar a ordem.',
  'it was not possible save file': 'Não foi possível guardar o ficheiro',
  'kyc_level': 'Nível SPARK',
  // 'kyc_not_complete_message': 'O seu kyc ainda não se encontra completo. Por favor, complete o kyc para poder usar todas as funcionalidade.',
  'kyc_not_complete_message': 'O seu limite anual é de 2.500€.',
  'last login': 'Último login',
  'lastname': 'Último Apelido',
  'LESS THAN 20 000 EUR': 'Menos de 20 000 EUR',
  'LESS THAN 500 EUR': 'Menos de 500 EUR',
  'limit orders': 'Atingido o número máximo de carregamentos possíveis por dia. Número máximo 3 carregamentos por cartão diários.',
  'load_card': 'Carregar Cartão',
  'load_card_order': 'Carregamento',
  'loading_fee': 'Comissão',
  'lock': 'Bloquear',
  'lock_card': 'Bloquear cartão',
  'lock_card_1_msg': 'Prima o botão abaixo para bloquear temporariamente o seu Cartão SPARK. Esta ação restringirá a realização de operações com o seu cartão . Ao bloquear o cartão, não pode utilizá-lo para efetuar pagamentos mas ainda poderá efectuar carregamentos no seu cartão.',
  'lock_card_2_msg': 'Pode restabelecer a utilização do cartão neste mesmo menu quando necessitar.',
  'locked': 'Bloqueado',
  'login': 'Iniciar Sessão',
  'logout': 'Terminar Sessão',
  'lost_card': 'Denunciar cartão como perdido',
  'lost_card_msg': 'Prima o botão abaixo para denunciar o seu cartão como perdido. Uma vez feita a denúncia, a nossa equipa de Atendimento ao Cliente investigará o caso e entrará em contacto consigo através dos seus dados de contacto.',
  'Manager': 'Gerente',
  'Manufacturing / Maintenance': 'Manufactura / Manutenção',
  'Medical / Paramedical': 'Médico / Paramédico',
  'middlename': 'Segundo nome',
  'Monthly Income': 'Rendimento Mensal',
  'MORE THAN 1M EUR': 'Mais de 1M EUR',
  'MORE THAN 3000 EUR': 'Mais de 3000 EUR',
  'my_cards': 'Meus Cartões',
  'name': 'Nome',
  'nationaliry': 'Nacionalidade',
  'new_account_step_1': 'Nova conta passo 1',
  'new_account_step_2': 'Nova conta passo 2',
  'new_account_step_3': 'Nova conta passo 3',
  'new_account_step_4': 'Nova conta passo 4',
  'new_to_upload_1_file': 'Deve fazer pelo menos 1 upload.',
  'newsletter': 'Newsletter',
  'next': 'Seguinte',
  // new form
  'NIF / NIE': 'NIF / NIE',
  'no': 'Não',
  'not_active': 'Não Activo',
  'Occupation': 'Ocupação',
  'optional_company_name': 'Nome da Empresa (opcional)',
  'order was created but not saved on the system': 'Ocorreu um erro ao criar a ordem no sistema. Por favor contacte o suporte.',
  'order_error': 'Não foi possível executar o seu pedido. Por favor, verifique se os cartões selecionados não atingiram o limite máximo permitido para o seu perfil.',
  'order_id': 'Ordem ID',
  'order_list_label': 'Valor Transação / Saldo',
  'other_amount': 'Outra quantia',
  'other_amount2': 'Introduza outro valor',
  'password': 'Palavra chave',
  'password_chards': 'A sua senha deve ter pelo menos 8 caracteres, incluindo MAIÚSCULAS, números e símbolos',
  'password_min_length': 'A password deve conter mais de 6 caracteres.',
  'pay': 'Pagar',
  'Pay in store': 'Pagar na loja',
  'Pay my bills': 'Pagar as minhas contas',
  'pdf_comissao': 'Comissao',
  // PDF
  'pdf_data': 'Data',
  'pdf_entidade': 'Entidade',
  'pdf_mensagem': 'Mensagem',
  'pdf_montante': 'Montante',
  'pdf_ordem': 'Ordem',
  'pdf_referencia': 'Referencia',
  'pdf_referencia e payshop': 'Referencia / Payshop',
  'pdf_resultado': 'Resultado',
  'pdf_saldo': 'Saldo',
  'pdf_Spark ID': 'SPARK ID',
  'pdf_tipo': 'Tipo',
  'pendente': 'pendente',
  'Personal Expenses': 'Despesas pessoais',
  'Personal expenses': 'Despesas pessoais',
  'personal_information': 'Informação Pessoal',
  'phone': 'Telefone',
  'Politician / Elected Member of Parliament': 'Político / Membro Eleito do Parlamento',
  'privacy_policy': 'Política de privacidade',
  'profile': 'Perfil',
  'profiles': 'Perfis',
  'Public servant / Police / Military': 'Funcionário Público / Polícia / Militar',
  'Receipt of income from business': 'Receita de negócios',
  'Receive my salary': 'Receber o meu salário',
  'Receive my social welfare payments': 'Receber os meus pagamentos de assistência social',
  'recover': 'Recuperar',
  'recovery_password': 'Recuperar Password',
  'recovery_password_error': 'Não foi possível actualizar a password,',
  'recovery_password_success': 'A password foi actualizada.',
  'reference': 'Referencia',
  'removecard': 'Remover cartão',
  'renew_session': 'Por questões de segurança a sua sessão expira dentro de 20 segundos. Pretende renova-la?',
  'requesT_recovery_password_error': 'Não foi possível executar o pedido,',
  'requesT_recovery_password_success': 'O pedido foi executado com sucesso. Por favor verifica o email e siga as instruções para recuperar a password,',
  'resend_email': 'Reenviar email',
  'resume': 'Resumo',
  'Retiree': 'Aposentado',
  'save': 'Guardar',
  'Save money': 'Economizar',
  'search': 'Procurar',
  'secundary_id': 'ID Secundário',
  'Self employed': 'Trabalhadores por conta própria',
  'Send or Receive Money transfer': 'Envio ou recepção de dinheiro',
  'send_pin_msg': 'Serã enviada uma mensagem com o PIN para o número ',
  'send_pin_success': 'Enviámos uma mensagem com o seu código PIN ao seu telemóvel.',
  'Services / IT': 'Serviços / TI',
  'session_expired': 'Por questões de segurança a sua sessão expirou.',
  'show': 'Mostrar',
  'Social Security / NGO': 'Segurança Social / ONG',
  'Spain': 'Espanha',
  'special-error-1': 'Ocorreu um erro, por favor contacte o nosso suporte.',
  'special-error-2': 'Apoio ao cliente disponível de:',
  'special-error-3': 'Segunda a Sexta',
  'special-error-4': '9:00 AM to 6:00 PM CET.',
  'special-error-5': '(Fechado nos feriados nacionais)',
  'special-error-6': 'E-mail: info@tarjetaspark.es',
  'special-error-7': 'Espanha:',
  'special-error-8': 'Portugal:',
  'special-error-create-account-1': 'Estimado cliente, detectamos um problema com os dados do registo do seu cartão SPARK.',
  'special-error-create-account-2': 'Por esse motivo solicitamos que nos envie uma cópia do seu documento de identificação pessoal, bem como comprovativo de residência com menos de 3 meses para o email: info@cartaospark.pt.',
  'special-error-create-account-3': 'Logo que recepcionarmos os documentos solicitados procederemos à sua validação e validação da sua conta. Caso não nos envie os documentos nos próximos 7 dias o cartão ficará bloqueado.',
  'special-error-create-account-4-1': 'DOCUMENTO DE IDENTIFICAÇÃO: ',
  'special-error-create-account-4-2': 'Passaporte ou cartão de cidadão emitido por um país da União Europeia, carta de condução emitida por um país da União Europeia.',
  'special-error-create-account-5-1': 'COMPROVATIVO DE MORADA: ',
  'special-error-create-account-5-2': 'Faturas de serviços públicos como (telefone, gás, electricidade, água) emitida com menos de 3 meses ou extracto bancário com data inferior a 3 meses.',
  'special-error-create-account-6': 'Obrigado pela preferência em aderir ao Cartão SPARK e esperamos pelos seus documentos.',
  'start_date': 'Data de inicio',
  'state': 'Distrito',
  'statement date': 'Data de declaração',
  'stolen_card': 'Denunciar cartão como roubado',
  'stolen_card_msg': 'Prima o botão abaixo para denunciar o seu cartão como roubado. Uma vez feita a denúncia, a nossa equipa de Atendimento ao Cliente investigará o caso e entrará em contacto consigo através dos seus dados de contacto.',
  'Student': 'Estudante',
  'submit': 'Enviar',
  'sucesso': 'sucesso',
  'sync_transactions_error': 'Falhou a sinconização das trasações com o seu cartão.',
  'terms_and_conditions': 'Termos e condições',
  'this field are invalid': 'Este campo não se encontra correctamente preenchido.',
  'total': 'Total',
  // server errors
  'total number of cards exceeded': 'Excedeu o número de cartões permitidos.',
  'total_amount': 'Total carregado',
  'tranfer': 'Tranferència',
  'transactions': 'Transações',
  'transfer_error_v2': 'Ocorreu um erro ao tentar carregar o cartão. No cartão [CARID] só é possível carregar [AMOUNT] €.',
  'Travel Expenses': 'Despesas de viagem',
  'Travel expenses': 'Despesas de viagem',
  'Unemployed': 'Desempregado',
  'Unknown Error': 'Ocorreu um erro com o seu pedido. Por favor contacte o suporte.',
  'unlock': 'Desbloquear',
  'unlock_card': 'Desbloquear cartão',
  'unlock_msg': 'Prima o botão abaixo para restabelecer a utilização do cartão.',
  'update_card_status_success': 'O estado do cartão foi actualizado com sucesso.',
  'updated_at': 'Actualizado às',
  'updated_card_name_error': 'Não foi possível actualizar o nome do cartão.',
  'updated_card_name_success': 'O nome do cartão foi actualizado com sucesso.',
  'updated_password_error': 'Não foi possível atualizar a password.',
  'updated_password_success': 'A password foi actualizada.',
  'upload_error': 'Não foi possível fazer upload dos ficheiros.',
  'upload_success': 'Os documentos foram recebido com sucesso. Será contactado num prazo de 24 a 72 horas.',
  'validate_date': 'Data de validade',
  'Valor / Comissão': 'Valor / Comissão',
  'verification_code': 'Código de verificação',
  // 'verify kyc or parameters': 'Por favor, verifique se o seus dados de perfil foram todos atualizados.',
  'verify kyc or parameters': 'O montante máximo autorizado para carregamentos através de cartão de crédito é de 250€, podendo realizar até 3 carregamentos por dia.',
  'verify_email': 'Verificar Email',
  'waiting': 'Em espera',
  'wrong_credentials': 'O email ou password encontram-se incorretos.',
  'wrong_password_and_confirm_password': 'Os campos password e confirmar password não contêm o mesmo valor',
  'yes': 'Sim',
  'you will receive 1 pin on phone': 'Dentro de instantes irá receber o pin do cartão no número usado para a activação.',
  'YYYY-MM-DD': 'AAAA-MM-DD',
  'zipcode': 'Código postal',
  'upload_document_message_1': 'Estimado cliente,',
  'upload_document_message_2': 'Informamos que, em cumprimento das normas legais, estamos a melhorar a nossa plataforma KYC para elevar o seu SPARK ao nível premium. Portanto, lamentamos informar que não podemos verificar os documentos neste momento.',
  'upload_document_message_3': 'Muito em breve poderá usufruir de um melhor serviço que permitirá a verificação de documentos num espaço de tempo muito curto. Agradecemos sua paciência enquanto atualizamos o sistema e pedimos desculpas por qualquer inconveniente que isso possa causar.',
  'upload_document_message_4': 'Equipe SPARK',
  'premium_level': 'Nível Premium',
  'update_to_premium': 'Atualizar para Premium',
  'document_number': 'Número do documento',
  'document_type': 'Cartáo de cidadáo',
  'document_expiry_date': 'Data de expiração',
  'bi': 'Cartão único',
  'passport': 'Passport',
  'driving_license': 'Carta de condução',
  'start_validation_process': 'Iniciar processo de validação',
  'qrcode_form_title': 'Para continuar o processo de atualização da sua conta para premium você precisa:',
  'qrcode_form_desc1': '1 - Abra um leitor de QR Code ou câmera para ler o QR Code',
  'qrcode_form_desc2': '2 - Siga as instruções do seu telefone e tire fotos do seu CU e Selfie',
  'qrcode_form_desc3': '3 - Aguarde alguns minutos pela validação',
  'qrcode_form_desc4': '4 - Case aconteça algum problema ou erro entre em contacto com o suporte',
  'Temporary employee': 'Empregado temporário',
  'Not applicable': 'Não aplicável',
  'Cryptocurrency': 'Criptomoeda',
  'For use abroad': 'Para uso no exterior',
  'Gaming or Betting on-line': 'Jogos ou apostas online',
  'Point of Sale purchases': 'Compras no ponto de venda',
  'Receive income from my business activity': 'Receber rendimentos da minha atividade empresarial',
  'Savings': 'Poupança',
  'Send or receive money transfers': 'Envie ou receba transferências de dinheiro',
  'Business Expenses': 'Despesas de negócio',
  'Corporate Account Less than 50K': 'Conta Corporativa menos de 50000€',
  'Corporate Account 50K - 150K': 'Conta Corporativa 50000€ - 150000€',
  'Corporate Account Above 150K': 'Conta Corporativa acima de 150000€',
  'countryOfIssuance': 'País de Emissão',
  'qr_minimum_requirements_title': 'Requisitos mínimos de conexão',
  'qr_minimum_requirements_desc1': 'Webcam e microfone.',
  'qr_minimum_requirements_desc2': 'Conexão de Internet Wi-Fi ou 4G/5G.',
  'qr_minimum_requirements_desc3': 'Navegador: Chrome, Firefox, Edge, Safari.',
  'qr_minimum_requirements_desc4': 'Feche outros aplicativos.',
  'upgrade_premium_error': 'Por favor, reveja toda a informação pessoal introduzida. Caso tenha algum problema envie um email para info@tarjetaspark.es.',
  'upgrade_premium_error_050': 'A sua conta encontra-se em revisão. Caso tenha algum problema envie um email para info@tarjetaspark.es.',
  'WAITING_FOR_REVIEW': 'A sua conta encontra-se em revisão. Caso tenha algum problema envie um email para info@tarjetaspark.es.',
  'REVIEW_NOT_APPROVED': 'A sua conta não foi validada por ter dados incorrectos ou incoerentes. Caso tenha algum problema envie um email para info@tarjetaspark.es.',
  'AUTO_INVALID': 'A sua conta não foi validada por ter dados incorrectos ou incoerentes. Caso tenha algum problema envie um email para info@tarjetaspark.es.',
  'ERROR': 'A sua conta não foi validada por ter dados incorrectos ou incoerentes. Caso tenha algum problema envie um email para info@tarjetaspark.es.',
  'AUTO_INVALID_NAME': 'Não foi possível validar o nome inserido. Caso tenha algum problema envie um email para info@tarjetaspark.es.',
  'AUTO_INVALID_DOB': 'Não foi possível validar a data de nascimento inserida. Caso tenha algum problema envie um email para info@tarjetaspark.es.',
  'AUTO_INVALID_NAMEDOB': 'Não foi possível validar os nome e a data de nascimento inserida. Caso tenha algum problema envie um email para info@tarjetaspark.es.',
  'NO_ID_UPLOADED': 'Não foram submetidos os dados necessários para concluir o processo de KYC. Caso tenha algum problema envie um email para info@tarjetaspark.es.',
  'MODAL_CODE_TEXT': 'Por favor verifique a caixa de email com o código de validação, caso não receba verifique o SPAM.',
  'Account is currently under KYC review': 'A conta está atualmente sob análise.',
  'KYC is approved': 'Conta aprovada.',
  'User needs to resubmit perhaps trying from a different device or ID document or different lighting area': 'O usuário precisa reenviar, talvez tentando usar um dispositivo ou documento de identificação diferente ou uma área de iluminação diferente.',
  'User has reached the limit of cards per this ID document': 'O usuário atingiu o limite de cartões por este documento de identificação.',
  'Not returned for video KYC': 'Cliente não efectou a validação por vídeo KYC.',
  'Not applicable for video KYC': 'Não aplicável para vídeo KYC.',
  'User needs to finalise and complete an upload': 'O usuário precisa finalizar e o processo de kyc.',
  'citizien document information': 'Dados documento de identificação',
  'here': 'aqui',
}
