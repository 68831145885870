import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, finalize } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthToken } from '../models/auth_token';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PopupRenewSessionComponent } from '../modals/popup-renew-session/popup-renew-session.component';
import { ReCaptchaV3Service } from 'ngx-captcha';

const firePopupWarningLogout = 20000; // 20 seconds - 20000 miliseconds

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    basePath: string;
    private isAuthenticatedSubject: BehaviorSubject<boolean>;
    public isAuthenticated: Observable<boolean>;
    private authToken: AuthToken;

    private dialogRef = null;
    private listener = null;
    private logoutListener = null;

    constructor(
        private reCaptchaV3Service: ReCaptchaV3Service,
        private http: HttpClient,
        private router: Router,
        public dialog: MatDialog
    ) {
        this.basePath = environment.baseUrl;

        this.isAuthenticatedSubject = new BehaviorSubject<boolean>(this.isJwtSaved());
        this.isAuthenticated = this.isAuthenticatedSubject.asObservable();

        this.loadAuthTokenFromStorate();
    }

    loadAuthTokenFromStorate() {
        try {
            const token = JSON.parse(localStorage.getItem('currentUser'));
            this.authToken = new AuthToken(token);

            // falta validar token exp time
            if (!this.authToken.isValid()) {
                this.logout();
            } else {
                this.registerLogoutListener();
            }
        } catch (err) {
            this.authToken = null;
        }
    }

    isJwtSaved() {
        if (this.authToken != null) {
            return this.authToken.isValid();
        }

        return false;
    }

    isJwtTokenExists() {
        return (this.authToken != null);
    }

    // retorna o tempo que falta ate deixar de ser valido em milisegundos
    remainAuthenticationTime(): number {
        if (this.isJwtTokenExists()) {
            const time = this.authToken.timeUntilExpires();
            return time;
        }
        return 0;
    }

    login(username: string, password: string, token: string, type: string = 'normal') {
        const body = type === 'impersonate'
            ? { username: username, impersonate_password: password, recaptcha_token: token }
            : { username: username, password: password, recaptcha_token: token };

        return this.http.post<any>(`${this.basePath}/login`, body)
            .pipe(
                map(token => {
                    this.authToken = new AuthToken(token);

                    // login successful if there's a jwt token in the response
                    if (this.authToken.isValid()) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(this.authToken));

                        this.registerLogoutListener();
                        // this.isAuthenticatedSubject.next(true);
                    }

                    return token;
                })
            );
    }

    renewSession(token: string) {
        return this.http.post<any>(`${this.basePath}/renewsession`, { recaptcha_token: token })
            .pipe(
                map(token => {
                    this.authToken = new AuthToken(token);

                    // login successful if there's a jwt token in the response
                    if (this.authToken.isValid()) {
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('currentUser', JSON.stringify(this.authToken));

                        this.clearListeners();
                        this.registerLogoutListener();
                    }

                    return token;
                })
            );
    }

    request_recover_password(email: string, token: string, language: string) {
        return this.http.post(`${this.basePath}/requestrecoverypassword`, { email: email, recaptcha_token: token, language: language });
    }

    recover_password(password: string, token: string, reCaptchaToken: string) {
        return this.http.post(`${this.basePath}/recoverypassword`, { password: password, token: token, recaptcha_token: reCaptchaToken });
    }

    logout() {
        // remove user from local storage to log user out
        this.authToken = null;
        localStorage.removeItem('currentUser');

        if (this.dialogRef) {
            this.dialogRef.close();
        }

        this.clearListeners();
        // this.isAuthenticatedSubject.next(true);
    }

    clearListeners(): void {
        if (this.listener) {
            clearTimeout(this.listener);
            this.listener = null;
        }

        if (this.logoutListener) {
            clearTimeout(this.logoutListener);
            this.logoutListener = null;
        }
    }

    registerLogoutListener(): void {
        if (this.logoutListener) {
            return;
        }

        const timeRemaining = this.remainAuthenticationTime();
        this.logoutListener = setTimeout(() => {
            clearTimeout(this.logoutListener);
            this.logoutListener = null;

            this.logout();
            this.router.navigate(['/login'], { queryParams: { expired: true } });
        }, timeRemaining);

        this.registerShowPopupLogoutInXSeconds();
    }

    registerShowPopupLogoutInXSeconds(): void {
        if (this.listener != null) {
            return;
        }

        const timeRemaining = this.remainAuthenticationTime(); // in miliseconds

        if (timeRemaining > firePopupWarningLogout) {
            console.log('Tempo restante: ' + (timeRemaining / 1000 / 60), ' fires: ' + (timeRemaining - firePopupWarningLogout));

            this.listener = setTimeout(() => {
                clearTimeout(this.listener);
                this.listener = null;

                this.dialogRef = this.dialog.open(PopupRenewSessionComponent, {
                    width: '470px',
                });

                this.dialogRef.afterClosed().subscribe(results => {
                    if (results === true) {
                        this.reCaptchaV3Service.execute(environment.recaptcha, 'renewsession', (token) => {
                            const sub = this.renewSession(token)
                                .pipe(finalize(() => sub.unsubscribe()))
                                .subscribe(data => { });
                        }, {
                            useGlobalDomain: false
                        });
                    }
                });
                // alert('Em ' + (firePopupWarningLogout / 1000 / 60) + ' segundos ira fazer logout');
            }, timeRemaining - firePopupWarningLogout);
        }
    }
}