import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';

import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { LogAppService } from '../services/log_app.service';
import { UserService } from '../services/user.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private logAppService: LogAppService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headersObject = {
            authkey: environment.authKey,
            'Cache-Control': 'no-cache',
            //Pragma: 'no-cache'
        };

        if (request.url === 'https://freegeoip.app/json') {
            headersObject['Accept'] = 'application/json';
            headersObject['Content-type'] = 'application/json';
        } else {
            // add authorization header with jwt token if available
            const currentUser = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUser && currentUser.jwt) {
                headersObject['Authorization'] = `Bearer ${currentUser.jwt}`;
            }
        }

        request = request.clone({ setHeaders: headersObject });

        return next.handle(request)
            .pipe(map(this.processServerResponse))
            .pipe(catchError(this.serverUnreachable));
    }

    processServerResponse = (event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
            if (event.status == 200) {
                if (event.body instanceof Blob) {
                    return event;
                }

                const { code = 500, message = null } = event.body || {};

                if ((code == 200 || code == 201) && event.body) {
                    // tem de ser assim porque nao podemos parar o processo de erro,
                    // se for erro passa ao proximo pipe
                    return event.clone({ body: message });
                } else {
                    throw event.clone({ status: code });
                }
            }
        }
        return event;
    }

    sendErrorsMessagesToServer(error) {
        const pathNotLog = ['login', '/app/cards/carddetails'];
        const { url = '' } = this.router;
        const found = pathNotLog.find(p => url.indexOf(p) > -1);

        if (!found) {
            const { id = null } = this.userService.authenticated_user || {};

            this.logAppService.saveError(id, url, error).subscribe();
        }
    }

    // << ErrorInterceptor >>
    // trata quando o servidor nao responde ou estamos sem acesso a ele
    serverUnreachable = (err: any) => {
        let logout = false;

        switch (err.status) {
            case 401:
                console.log('access not authorized');
                logout = true;
                break;
            case 500:
                console.log('SERVER DOWN');
                logout = true;
                break;
            case 0: // 'normalmente connection refuse'
                console.log('Connection refuse');
                logout = true;
                break;
            default:
                console.log('Error unknown');
                break;
        }

        if (logout) {
            if (this.authenticationService.isJwtTokenExists()) {
                this.authenticationService.logout();
                this.router.navigate(['/login']);
            }
        }

        const { body = null } = err;
        const { message = null, error = null } = body || {}
        const result = {
            code: err.status,
            error: body ? (message || error) : 'api_internal_error'
        };

        if (err.status != 501) {
            this.sendErrorsMessagesToServer(result);
        }

        return throwError(result);
    }
}
