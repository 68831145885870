import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-simple-box-card',
    templateUrl: './simple-box-card.component.html',
    styleUrls: ['./simple-box-card.component.css']
})
export class SimpleBoxCardComponent implements OnInit {
    @Input() title = 'Card name';
    @Input() value = '0,00';

    constructor() {}

    ngOnInit() {}

}
