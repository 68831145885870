<div class="container">
    <div class="container-input">
        <a class="export_csv right-space" (click)="exportPopup = true">{{ 'export' | translate }}</a>

        <input type="text" placeholder="{{ 'search' | translate }}" (keyup)="searchChanged($event)">

        <button class="my-button my-button-filter" mat-raised-button color="warn" (click)="filterListVisible = true">
            <img src="assets/images/filter.png" alt="filter" >
        </button>
    </div>

    <div class="list-label">{{ 'order_list_label' | translate }} </div>
    <app-order-line [order]="order" *ngFor="let order of orders"></app-order-line>
</div>

<div class="filter-container" *ngIf="filterListVisible">
    <div class="filter-content">
        <div class="my-form-input bottom-space">
            <label for="startDate">{{ 'start_date' | translate }} <span class="red">*</span></label>
            <div class="my-date-form">
                <input matInput [matDatepicker]="pickerStart" id="startDate" placeholder="{{ 'start_date' | translate }}" [value]="startDate" required (dateChange)="onDateInputChange($event, true)">
                <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
            </div>
        </div>

        <div class="my-form-input bottom-space">
            <label for="endDate">{{ 'end_date' | translate }} <span class="red">*</span></label>
            <div class="my-date-form">
                <input matInput [matDatepicker]="pickerEnd" id="endDate" placeholder="{{ 'end_date' | translate }}" [value]="endDate" required (dateChange)="onDateInputChange($event, false)">
                <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                <mat-datepicker #pickerEnd></mat-datepicker>
            </div>
        </div>

        <button class="my-button my-button-search" mat-raised-button color="warn" (click)="onFilter()">
            <img src="assets/images/search.png" alt="search" >
        </button>
    </div>
</div>

<div class="filter-container" *ngIf="exportPopup">
    <div class="filter-content">
        <button class="my-button my-button-export" mat-raised-button color="warn" (click)="exportAs('csv')">
            CSV
        </button>

        <br>

        <button class="my-button my-button-export" mat-raised-button color="warn" (click)="exportPdf()">
            PDF
        </button>
    </div>
</div>