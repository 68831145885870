export class CardPriv {
    accountBaseCurrency: string;
    accountNumber: string;
    availBal: string;
    badPinTries: string;
    cardStatus: string;
    cardStyle: string;
    cardType: string;
    client: string;
    companyName: string;
    deliveryType: string;
    distributorCode: string;
    expirationDate: string;
    ledgerBal: string;
    loadAmount: string;
    phonecardNumber: string;
    pinTriesExceeded: string;
    sortCode: string;
    sortCodeAccountNumber: string;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }
}