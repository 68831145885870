<div class="m-container">
  <div class="m-container-column">
    <div class="title">{{ "personal_information" | translate }}</div>

    <div class="container">
      <div class="container-row">
        <div class="container-row-left-cell">{{ "kyc_level" | translate }}</div>
        <div class="container-row-right-cell">{{ displayKycLabel() }}</div>
      </div>

      <!-- Firtname -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "firstname" | translate }}</div>

        <div class="container-row-right-cell">
          <div *ngIf="!isEdit">
            {{ profile?.firstname || "----" }}
          </div>

          <div
            *ngIf="isEdit"
            class="my-form-input {{ profile.firstname == '' ? 'my-form-input-error' : '' }}"
          >
            <input
              #inputFirstname
              type="text"
              id="firstname"
              placeholder="{{ 'firstname' | translate }}"
              value="{{ profile.firstname }}"
              (keydown)="onInputKeydown($event)"
            />
          </div>
        </div>

        <!-- <div class="container-row-right-btn">
          <span *ngIf="!editingFirstname" (click)="editingFirstname = true">
            {{ "edit" | translate }}
          </span>

          <span
            *ngIf="editingFirstname"
            (click)="editingFirstname = false"
            style="margin-right: 6px"
          >
            {{ "cancel" | translate }}
          </span>

          <span *ngIf="editingFirstname" (click)="onInputKeydown()">{{ "save" | translate }}</span>
        </div> -->
      </div>

      <!-- Middlename -->
      <!-- <div class="container-row">
        <div class="container-row-left-cell">{{ "middlename" | translate }}</div>

        <div class="container-row-right-cell">
          <div *ngIf="!isEdit">
            {{ profile?.middlename || "----" }}
          </div>

          <div
            *ngIf="isEdit"
            class="my-form-input {{ profile.middlename == '' ? 'my-form-input-error' : '' }}"
          >
            <input
              #inputMiddlename
              type="text"
              id="middlename"
              placeholder="{{ 'middlename' | translate }}"
              maxlength="1"
              value="{{ profile.middlename }}"
              (keydown)="onInputKeydown($event)"
            />
          </div>
        </div>
      </div> -->

      <!-- Lastname -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "lastname" | translate }}</div>

        <div class="container-row-right-cell">
          <div *ngIf="!isEdit">
            {{ profile?.lastname || "----" }}
          </div>

          <div
            *ngIf="isEdit"
            class="my-form-input {{ profile.lastname == '' ? 'my-form-input-error' : '' }}"
          >
            <input
              #inputLastname
              type="text"
              id="lastname"
              placeholder="{{ 'lastname' | translate }}"
              value="{{ profile.lastname }}"
              (keydown)="onInputKeydown($event)"
            />
          </div>
        </div>

        <!-- <div class="container-row-right-btn">
          <span *ngIf="!editingLastname" (click)="editingLastname = true">
            {{ "edit" | translate }}
          </span>

          <span *ngIf="editingLastname" (click)="editingLastname = false" style="margin-right: 6px">
            {{ "cancel" | translate }}
          </span>

          <span *ngIf="editingLastname" (click)="onInputKeydown()">{{ "save" | translate }}</span>
        </div> -->
      </div>

      <!-- Nationality -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "nationaliry" | translate }}</div>

        <div class="container-row-right-cell">
          <div *ngIf="!isEdit">
            {{ profile?.nationality || "----" }}
          </div>

          <div
            *ngIf="isEdit"
            class="my-form-input {{ profile.nationality == '' ? 'my-form-input-error' : '' }}"
          >
            <select id="nationality" style="max-width: 313px" [(ngModel)]="nationalityUpdated">
              <option *ngFor="let c of nationalityL" [value]="c">
                {{ c }}
              </option>
            </select>
          </div>
        </div>

        <!-- <div class="container-row-right-btn">
          <span *ngIf="!editingNationality" (click)="editingNationality = true">
            {{ "edit" | translate }}
          </span>

          <span
            *ngIf="editingNationality"
            (click)="editingNationality = false"
            style="margin-right: 6px"
          >
            {{ "cancel" | translate }}
          </span>

          <span *ngIf="editingNationality" (click)="onInputKeydown()">
            {{ "save" | translate }}
          </span>
        </div> -->
      </div>

      <!-- Address -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "address" | translate }}</div>

        <div class="container-row-right-cell">
          <div *ngIf="!isEdit">
            {{ mainAddress?.address || "----" }}
          </div>

          <div
            *ngIf="isEdit"
            class="my-form-input {{ mainAddress?.address == '' ? 'my-form-input-error' : '' }}"
          >
            <input
              #inputAddress
              type="text"
              id="address"
              placeholder="{{ 'address' | translate }}"
              value="{{ mainAddress?.address }}"
              (keydown)="onInputAddressKeydown($event)"
            />
          </div>
        </div>

        <!-- <div class="container-row-right-btn">
          <span *ngIf="!editingAddress" (click)="editingAddress = true">
            {{ "edit" | translate }}
          </span>

          <span *ngIf="editingAddress" (click)="editingAddress = false" style="margin-right: 6px">
            {{ "cancel" | translate }}
          </span>

          <span *ngIf="editingAddress" (click)="onInputAddressKeydown()">
            {{ "save" | translate }}
          </span>
        </div> -->
      </div>

      <!-- City -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "city" | translate }}</div>

        <div class="container-row-right-cell">
          <div *ngIf="!isEdit">
            {{ mainAddress?.city || "----" }}
          </div>

          <div
            *ngIf="isEdit"
            class="my-form-input {{ mainAddress?.city == '' ? 'my-form-input-error' : '' }}"
          >
            <input
              #inputCity
              type="text"
              id="city"
              placeholder="{{ 'city' | translate }}"
              value="{{ mainAddress?.city }}"
              (keydown)="onInputAddressKeydown($event)"
            />
          </div>
        </div>

        <!-- <div class="container-row-right-btn">
          <span *ngIf="!editingCity" (click)="editingCity = true">
            {{ "edit" | translate }}
          </span>

          <span *ngIf="editingCity" (click)="editingCity = false" style="margin-right: 6px">
            {{ "cancel" | translate }}
          </span>

          <span *ngIf="editingCity" (click)="onInputAddressKeydown()">
            {{ "save" | translate }}
          </span>
        </div> -->
      </div>

      <!-- Zip Code -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "zipcode" | translate }}</div>

        <div class="container-row-right-cell">
          <div *ngIf="!isEdit">
            {{ mainAddress?.zipcode || "----" }}
          </div>

          <div
            *ngIf="isEdit"
            class="my-form-input {{ mainAddress?.zipcode == '' ? 'my-form-input-error' : '' }}"
          >
            <input
              #inputZipCode
              type="text"
              id="zipcode"
              placeholder="{{ 'zipcode' | translate }}"
              value="{{ mainAddress?.zipcode }}"
              (keydown)="onInputAddressKeydown($event)"
            />
          </div>
        </div>

        <!-- <div class="container-row-right-btn">
          <span *ngIf="!editingZipCode" (click)="editingZipCode = true">
            {{ "edit" | translate }}
          </span>

          <span *ngIf="editingZipCode" (click)="editingZipCode = false" style="margin-right: 6px">
            {{ "cancel" | translate }}
          </span>

          <span *ngIf="editingZipCode" (click)="onInputAddressKeydown()">
            {{ "save" | translate }}
          </span>
        </div> -->
      </div>

      <!-- Country -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "country" | translate }}</div>
        <div class="container-row-right-cell">
          <span *ngIf="!isEdit">{{ country }}</span>

          <div *ngIf="isEdit" class="my-form-input">
            <select id="countryUpdated" style="max-width: 313px" [(ngModel)]="countryUpdated">
              <option *ngFor="let c of countries" [value]="c">
                {{ c }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- Phone -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "phone" | translate }}</div>

        <div class="container-row-right-cell">
          <div *ngIf="!isEdit">
            {{ profile?.phone || "----" }}
          </div>

          <div
            *ngIf="isEdit"
            class="my-form-input {{ profile.phone == '' ? 'my-form-input-error' : '' }}"
          >
            <ngx-intl-tel-input
              [(ngModel)]="phoneModelValue"
              [cssClass]="'custom'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="true"
              [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.Portugal"
              [maxLength]="15"
              [phoneValidation]="true"
              [separateDialCode]="separateDialCode"
              name="phoneNumber"
              [inputId]="'inputPhone'"
            >
            </ngx-intl-tel-input>

            <!-- <input
              #inputPhone
              type="text"
              id="phone"
              placeholder="{{ 'phone' | translate }}"
              value="{{ profile.phone }}"
              (keydown)="onInputKeydown($event)"
            /> -->
          </div>
        </div>

        <!-- <div class="container-row-right-btn">
          <span *ngIf="!editingPhone" (click)="editingPhone = true">{{ "edit" | translate }}</span>

          <span *ngIf="editingPhone" (click)="editingPhone = false" style="margin-right: 6px">
            {{ "cancel" | translate }}
          </span>

          <span *ngIf="editingPhone" (click)="onInputKeydown()">{{ "save" | translate }}</span>
        </div> -->
      </div>

      <!-- Email -->
      <div class="container-row">
        <div class="container-row-left-cell">E-mail</div>
        <div class="container-row-right-cell">
          <div *ngIf="!isEdit">
            {{ profile?.email || "----" }}
          </div>

          <div
            *ngIf="isEdit"
            class="my-form-input {{ profile.email == '' ? 'my-form-input-error' : '' }}"
          >
            <input
              #inputEmail
              type="email"
              id="email"
              placeholder="Email"
              value="{{ profile.email }}"
              (keydown)="onInputKeydown($event)"
            />
          </div>
        </div>

        <!-- <div class="container-row-right-btn">
          <span *ngIf="!editingEmail" (click)="editingEmail = true">{{ "edit" | translate }}</span>

          <span *ngIf="editingEmail" (click)="editingEmail = false" style="margin-right: 6px">
            {{ "cancel" | translate }}
          </span>

          <span *ngIf="editingEmail" (click)="onInputKeydown()">{{ "save" | translate }}</span>
        </div> -->
      </div>

      <!-- Card Purpose -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "Card Purpose" | translate }}</div>

        <div class="container-row-right-cell">
          <span *ngIf="!isEdit">{{ profile?.card_purpose || "----" }}</span>

          <div *ngIf="isEdit" class="my-form-input">
            <select
              id="cardPurposeUpdated"
              style="max-width: 313px"
              [(ngModel)]="cardPurposeUpdated"
            >
              <option *ngFor="let c of cardPurpose" [value]="c">
                {{ c | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- Employment status -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "Employment Status" | translate }}</div>

        <div class="container-row-right-cell">
          <span *ngIf="!isEdit">{{ profile?.employment_status || "----" }}</span>

          <div *ngIf="isEdit" class="my-form-input">
            <select
              id="employmentStatusUpdated"
              style="max-width: 313px"
              [(ngModel)]="employmentStatusUpdated"
            >
              <option *ngFor="let c of employmentStatus" [value]="c">
                {{ c | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- Occupation -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "Occupation" | translate }}</div>

        <div class="container-row-right-cell">
          <span *ngIf="!isEdit">{{ profile?.occupation || "----" | translate }}</span>

          <div *ngIf="isEdit" class="my-form-input">
            <select id="occupation" style="max-width: 313px" [(ngModel)]="occupationUpdated">
              <option *ngFor="let c of occupation" [value]="c">
                {{ c | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- Monthly Income -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "Monthly Income" | translate }}</div>

        <div class="container-row-right-cell">
          <span *ngIf="!isEdit">{{ profile?.monthly_income || "----" }}</span>

          <div *ngIf="isEdit" class="my-form-input">
            <select
              id="monthlyIncomeUpdated"
              style="max-width: 313px"
              [(ngModel)]="monthlyIncomeUpdated"
            >
              <option *ngFor="let c of monthlyIncome" [value]="c">
                {{ c | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- Estate -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "Estate" | translate }}</div>

        <div class="container-row-right-cell">
          <span *ngIf="!isEdit">{{ profile?.estate || "----" }}</span>

          <div *ngIf="isEdit" class="my-form-input">
            <select id="estateUpdated" style="max-width: 313px" [(ngModel)]="estateUpdated">
              <option *ngFor="let c of estate" [value]="c">
                {{ c | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- DOCUMENTATION -->

      <div class="container-row--subtitle">
        {{ "citizien document information" | translate }}
      </div>

      <!-- countryOfIssuance -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "countryOfIssuance" | translate }}</div>

        <div class="container-row-right-cell">
          <div *ngIf="!isEdit">
            {{ profile?.country_of_issuance || "----" }}
          </div>

          <div
            *ngIf="isEdit"
            class="my-form-input {{
              profile.country_of_issuance == '' ? 'my-form-input-error' : ''
            }}"
          >
            <select
              id="countryOfIssuance"
              style="max-width: 313px"
              [(ngModel)]="countryOfIssuanceUpdated"
            >
              <option *ngFor="let c of countries" [value]="c">
                {{ c }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- document type -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "document_type" | translate }}</div>

        <div class="container-row-right-cell">
          <div *ngIf="!isEdit">
            {{
              selectedDocumentType != null && selectedDocumentType != ""
                ? (selectedDocumentType | translate)
                : "----"
            }}
          </div>

          <div
            *ngIf="isEdit"
            class="my-form-input {{ profile.document_type == '' ? 'my-form-input-error' : '' }}"
          >
            <select id="document_type" style="max-width: 313px" [(ngModel)]="documentTypeUpdated">
              <option *ngFor="let dt of documentTypesAvailable" [value]="dt.value">
                {{ dt.label | translate }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- document number -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "document_number" | translate }}</div>

        <div class="container-row-right-cell">
          <div *ngIf="!isEdit">
            {{ profile?.document_number || "----" }}
          </div>

          <div
            *ngIf="isEdit"
            class="my-form-input {{ profile.document_number == '' ? 'my-form-input-error' : '' }}"
          >
            <input
              #inputDocumentNumber
              type="text"
              id="document_number"
              placeholder="{{ 'document_number' | translate }}"
              value="{{ profile.document_number }}"
              (keydown)="onInputKeydown($event)"
            />
          </div>
        </div>
      </div>

      <!-- document expiry date -->
      <div class="container-row">
        <div class="container-row-left-cell">{{ "document_expiry_date" | translate }}</div>

        <div class="container-row-right-cell">
          <div *ngIf="!isEdit">
            {{ documentExpirationDate || "----" }}
          </div>

          <div
            *ngIf="isEdit"
            class="my-date-form {{
              profile.document_expiry_date === null || profile.document_expiry_date === ''
                ? 'my-date-form-error'
                : ''
            }} "
          >
            <input
              #inputDocumentExpiryDate
              matInput
              [matDatepicker]="picker"
              id="document_expiry_date"
              placeholder="YYYY-MM-DD"
              placeholder="{{ 'document_expiry_date' | translate }}"
              value="{{ profile.document_expiry_date }}"
            />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
      </div>

      <div class="container-row btn-action-container">
        <div class="container-row-left-cell">
          <button
            *ngIf="!isEdit"
            class="c-button"
            mat-raised-button
            color="warn"
            (click)="isEdit = true"
          >
            {{ "edit" | translate }}
          </button>

          <button
            *ngIf="isEdit"
            class="c-button"
            mat-raised-button
            color="warn"
            (click)="isEdit = false"
          >
            {{ "cancel" | translate }}
          </button>
        </div>

        <div class="container-row-right-cell">
          <button
            *ngIf="isEdit && isSavedAvailable"
            class="c-button c-button-save"
            mat-raised-button
            color="warn"
            (click)="onOperationBtnPress()"
          >
            {{ "save" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="m-container-divider"></div>

  <div class="m-container-column" *ngIf="profile && profile.kyc != 2">
    <div class="title">
      {{ "documents" | translate }} - {{ "help" | translate }}
      <!-- <a class="pdf-link" target="_blank" href="{{pdfLink()}}">{{ 'help' | translate }}</a> -->
    </div>

    <div class="d-container">
      <div class="d-container-row">
        <div class="upload-container-column">
          <div class="upload-container-column-title" style="margin-bottom: 16px">
            {{ "upload_document_message_1" | translate }}
          </div>
          <div class="upload-container-column-title" style="margin-bottom: 16px">
            {{ "upload_document_message_2" | translate }}
          </div>
          <div class="upload-container-column-title" style="margin-bottom: 16px">
            {{ "upload_document_message_3" | translate }}
          </div>
          <div class="upload-container-column-title">
            {{ "upload_document_message_4" | translate }}
          </div>
        </div>
      </div>

      <!-- <div class="d-container-row">
                <div class="upload-container-column">
                    <div class="upload-container-column-title">{{ 'front_doc' | translate }}</div>
                    <div class="upload-container-column-description">
                        {{ 'front_doc_desc' | translate }}
                    </div>
                </div>
                <div class="upload-container-column-img {{ (fileControlType0 != null) ? 'upload-container-column-img-' + fileControlType0.status : '' }}"
                    (click)="onUploadPress(0)">
                    <div *ngIf="fileControlType0 == null">
                        <img *ngIf="filesResults.front_id == null" src="assets/images/upload.png">
                        <img *ngIf="filesResults.front_id != null" src="{{ filesResults.front_id }}"
                            class="upload-img-loaded">
                    </div>
                    <div *ngIf="fileControlType0 != null"> {{ statusText(fileControlType0.status) | translate }} </div>
                </div>

                <input #front_id type="file" id="front_id" class="kyc-input-files" (change)="onUploadFile($event, 0)"
                    accept="image/x-png,image/png,image/jpeg,application/pdf" />
            </div> -->

      <!-- <div class="d-container-row">
                <div class="upload-container-column">
                    <div class="upload-container-column-title">{{ 'back_doc' | translate }}</div>
                    <div class="upload-container-column-description">
                        {{ 'back_desc' | translate }}
                    </div>
                </div>
                <div class="upload-container-column-img {{ (fileControlType1 != null) ? 'upload-container-column-img-' + fileControlType1.status : '' }}"
                    (click)="onUploadPress(1)">
                    <div *ngIf="fileControlType1 == null">
                        <img *ngIf="filesResults.back_id == null" src="assets/images/upload.png">
                        <img *ngIf="filesResults.back_id != null" src="{{ filesResults.back_id }}"
                            class="upload-img-loaded">
                    </div>
                    <div *ngIf="fileControlType1 != null"> {{ statusText(fileControlType1.status) | translate }} </div>
                </div>
                <input #back_id type="file" id="back_id" class="kyc-input-files" (change)="onUploadFile($event, 1)"
                    accept="image/x-png,image/png,image/jpeg,application/pdf" />
            </div>

            <div class="d-container-row">
                <div class="upload-container-column">
                    <div class="upload-container-column-title">{{ 'cert_doc' | translate }}</div>
                    <div class="upload-container-column-description">
                        {{ 'cert_doc_desc' | translate }}
                    </div>
                </div>
                <div class="upload-container-column-img {{ (fileControlType2 != null) ? 'upload-container-column-img-' + fileControlType2.status : '' }}"
                    (click)="onUploadPress(2)">
                    <div *ngIf="fileControlType2 == null">
                        <img *ngIf="filesResults.comercial_id == null" src="assets/images/upload.png">
                        <img *ngIf="filesResults.comercial_id != null" src="{{ filesResults.comercial_id }}"
                            class="upload-img-loaded">
                    </div>
                    <div *ngIf="fileControlType2 != null"> {{ statusText(fileControlType2.status) | translate }} </div>
                </div>
                <input #comercial_id type="file" id="comercial_id" class="kyc-input-files"
                    (change)="onUploadFile($event, 2)" accept="image/x-png,image/png,image/jpeg,application/pdf" />
            </div>

            <div class="d-container-row-divider"></div>

            <div class="d-container-row-msg-error-container">
                <div *ngIf="fileControlType0 != null" class="d-container-row-msg-error">
                    {{ fileControlType0.errormessage | translate }}</div>
                <div *ngIf="fileControlType1 != null" class="d-container-row-msg-error">
                    {{ fileControlType1.errormessage | translate }}</div>
                <div *ngIf="fileControlType2 != null" class="d-container-row-msg-error">
                    {{ fileControlType2.errormessage | translate }}</div>
            </div>

            <div class="submit-button-container">
                <div class="submit-button {{ (!isValidToSubmit()) ? 'submit-button-locked' : '' }}"
                    (click)="onSubmitKyc()">
                    <span>{{ 'submit' | translate }}</span>
                    <div class="submit-button-img-container">
                        <img src="assets/images/arrow_2.png">
                    </div>
                </div>
            </div>
        </div> -->
    </div>
  </div>
</div>
