import { PipeTransform, Pipe } from '@angular/core';

import en from './en';
import pt from './pt';
import es from './es';

@Pipe({name: 'translate'})
export class Translate implements PipeTransform {
    transform(value: string) {
        const language = localStorage.getItem('language');
        let result = '';

        switch (language) {
            case 'pt':
                result = pt[value] || '';
                break;
            case 'en':
                result = en[value] || '';
                break;
            case 'es':
                result = es[value] || '';
                break;
            default:
                result = value;
                break;
        }

        return result;
    }
}