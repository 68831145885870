import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { AlertService } from 'src/app/services/alert.service';

import { TransferDataService } from 'src/app/services/transferData.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { Profile } from 'src/app/models/profile';
import { Address } from 'src/app/models/address';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { environment } from 'src/environments/environment';
import { CardService } from 'src/app/services/card.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-form-user',
    templateUrl: './form-user.component.html',
    styleUrls: ['./form-user.component.css'],
})
export class FormUserComponent implements OnInit, OnDestroy {
    @Output() onUserCreated = new EventEmitter<User>();
    backPress: Subscription;

    loadedEmail = ''; // email saved on card info
    typeKyc = '0'; // 0 -> Nada, 1 - simples, 2 - completo
    cardholderid = '';
    cardnumber = '';
    alreadyRegistered = false; // email exists on platform??

    viewStep;
    user: User;
    profile: Profile;
    address: Address;
    dataCollectForm: FormGroup;

    // desnecessaria
    locked = false; // variavel de controlo para nao deixar submeter o mesmo form mais do que 1 vez

    constructor(
        private activatedRoute: ActivatedRoute,
        private alertService: AlertService,
        private transfereDataService: TransferDataService,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private cardService: CardService,
        private router: Router
    ) { }

    ngOnInit() {
        this.locked = false
        this.activatedRoute.queryParams.subscribe(queryParams => this.viewStep = queryParams['step'] || '0');

        this.backPress = this.transfereDataService.onDataUpdate().subscribe(
            data => {
                if (this.transfereDataService.data.op === 'back_intro_press') {
                    this.viewStep = (this.viewStep > 0) ? this.viewStep - 1 : this.viewStep;

                    if (this.viewStep === 0) {
                        this.transfereDataService.data = {
                            op: 'update_links',
                            title: 'new_account_step_1',
                            bottomLinkText: 'back_to_login',
                            bottomLink: '/login'
                        }
                    }
                }
            }
        );
    }

    ngOnDestroy() {
        if (this.backPress) {
            this.backPress.unsubscribe();
        }
    }

    // Listeners

    onValidCard(obj: any) {
        if (obj == null) {
            this.router.navigate(['/aboutcardregister']);
        } else {
            this.typeKyc = obj.typeKyc;
            this.cardholderid = obj.cardHolderId;
            this.cardnumber = obj.cardNumber;
            this.loadedEmail = obj.email;
            this.alreadyRegistered = obj.alreadyRegistered;

            // this.viewStep = '1';
            if (this.typeKyc != '0' && obj.alreadyRegistered) {
                this.viewStep = '3';
                this.user = new User();
            } else {
                this.viewStep = '1';
            }
            // this.viewStep = (this.typeKyc != '0' && obj.alreadyRegistered) ? '3' : '1';
        }
    }

    onUserDefined(user: User) {
        this.user = user;
    }

    step1OnNextPress(profile: Profile) {
        this.profile = profile;

        if (this.typeKyc == '0') {
            this.viewStep = '2';
        } else {
            this.viewStep = '3';
        }
    }

    step2OnNextPress(address: Address) {
        this.address = address;
        this.viewStep = '3';
    }

    step3OnNextPress(dataCollectForm: FormGroup) {
        this.dataCollectForm = dataCollectForm;
        this.onSubmit();
    }

    onSubmit() {
        if (this.locked) {
            return;
        }

        this.alertService.loading(true);
        this.locked = true;

        if (this.user) {
            let profile = null;

            if (this.typeKyc == '0') {
                profile = this.profile;

                if (this.address) {
                    this.profile.addAddressObj(this.address);
                }
            } else {
                profile = new Profile();
            }

            if (this.dataCollectForm) {
                const dcf = this.dataCollectForm.controls;
                profile.ssn = dcf.ssn.value;
                profile.employment_status = dcf.employment_status.value;
                profile.occupation = dcf.occupation.value;
                profile.card_purpose = dcf.card_purpose.value;
                profile.monthly_income = dcf.monthly_income.value;
                profile.estate = dcf.estate.value;
                profile.termsAndConditions = dcf.termsAndConditions.value;
                profile.privacy = dcf.privacy.value;
                profile.newsletter = dcf.newsletter.value;
            }

            this.user.profile = profile;

            this.reCaptchaV3Service.execute(environment.recaptcha, 'login', (token) => {
                const f = (this.typeKyc != '0' && this.alreadyRegistered)
                    ? this.cardService.registerCardExistingAccount(this.cardholderid, this.cardnumber, this.user.profile, token, localStorage.getItem('language'))
                    : this.cardService.register(this.cardholderid, this.cardnumber, this.user, token, localStorage.getItem('language'));

                const sub = f
                    .pipe(finalize(() => {
                        sub.unsubscribe();
                        this.alertService.loading(false);
                        this.locked = false;
                    }))
                    .subscribe(
                        data => {
                            if (data === 'data incomplete') {
                                this.alertService.success('created with incomplete data');
                                setTimeout(() => this.onUserCreated.emit(this.user), 10000);
                            } else {
                                this.onUserCreated.emit(this.user);
                            }
                        }, //this.performLogin(user)
                        error => {
                            if (error.error == 'email not the same previous saved') {
                                this.alertService.specialError('email_not_same_as_in_card');
                            } else {
                                this.alertService.specialError(); // this.alertService.error(error.error || 'api_internal_error')
                            }
                        }
                    );
            }, {
                useGlobalDomain: false
            });
        } else {
            this.alertService.loading(false);
            this.locked = false;
        }
    }

}