import * as jsPDF from 'jspdf';
import * as Encoder from 'code-128-encoder';

import { FONT_CANARO_NORMAL, FONT_128, logo, multibanco, mb_way, payshop, ctt } from './contants';
import { User } from '../models/user';
import { Order } from '../models/order';
import { Translate } from '../locales/translate';
import { Card } from '../models/card';

const IS_DEV = false;
const LIB_RATIO_PX = 96 / 72;
const A4_SIZE_PX = { width: (595.28 / LIB_RATIO_PX), height: (841.89 / LIB_RATIO_PX) }; // mm = 595.28 x 841.89
const FOOTER_HEIGHTS = {
    'mb_payshop': { height: 111 / 2, bar: 19 / 2, box: 92 / 2 }, //56, // 111
    'transfer': { height: 321 / 2, bar: 19 / 2, box: 302 / 2 },
    'other': { height: 50 / 2, bar: 0, box: 0 },
};
const FOOTER_TEXTS = [
    {
        text: 'Para realizar um carregamento através de transferência bancária em Portugal, efetue a transferência para a seguinte conta bancária:',
        color: '#C0C0C0',
        space: 15,
        lines: 2,
    },
    {
        text: 'SANTANDER TOTTA',
        color: '#00000',
        space: 6,
        lines: 1,
    },
    {
        text: 'NIB: 0018 0003 4459 7235 0200 4',
        color: '#00000',
        space: 6,
        lines: 1,
    },
    {
        text: 'IBAN: PT50 0018 0003 44 597235020 04',
        color: '#00000',
        space: 15,
        lines: 1,
    },
    {
        text: 'Para poder identificar corretamente o seu pagamento e para contactá-lo sempre que necessário, não se esqueça de colocar os seguintes dados:',
        color: '#C0C0C0',
        space: 15,
        lines: 2,
    },
    {
        text: 'Titular da conta: Servicios de Prepago Integrales',
        color: '#C0C0C0',
        space: 6,
        lines: 1,
    },
    {
        type: 'order',
        text: 'Descritivo: ',
        text1: ' fornecido na coluna ao lado',
        color: '#C0C0C0',
        space: 6,
        lines: 1,
    },
    {
        text: 'Exemplo: #000 000 000',
        color: '#C0C0C0',
        space: 15,
        lines: 1,
    },
    {
        text: 'O cartão será carregado quando a transferência se torna efetiva (24-72 horas). Receberá um SMS confirmando o saldo carregado.',
        color: '#C0C0C0',
        space: 15,
        lines: 2,
    },
];

const adjust_size = (size) => size / 2;
const adjust_size_width = (size) => size / LIB_RATIO_PX;
const clearText = (text) => (!text || text == 'null') ? '' : text;

const render_footer = (type, documento, margins, entidade, ref, amount, ref_payshop, order_id) => {
    const FOOTER_SI = (type == 'transfer') ? FOOTER_HEIGHTS.transfer : FOOTER_HEIGHTS.mb_payshop;
    let yInitial = A4_SIZE_PX.height - margins.bottom - FOOTER_SI.height;
    const page_width = A4_SIZE_PX.width - margins.left - margins.right;
    let x;
    let y;
    let dim;
    let dimEnt;
    let dimRef;
    let dimAum;
    let xAux;

    documento.setFillColor('#4D4D4D');
    documento.rect(margins.left, yInitial, page_width, FOOTER_SI.bar, 'F');

    yInitial += FOOTER_SI.bar;

    documento.setFillColor('#FBFBFB');
    documento.rect(margins.left, yInitial, page_width, FOOTER_SI.box, 'F');

    y = yInitial + adjust_size(15);

    documento.setFontSize(10);

    if (type == 'transfer') {
        x = margins.left + 10;

        for (let it = 0; it < FOOTER_TEXTS.length; it++) {
            documento.setTextColor(FOOTER_TEXTS[it].color);
            documento.text(FOOTER_TEXTS[it].text, x, y, { baseline: 'top', maxWidth: page_width - 10 });

            if (FOOTER_TEXTS[it].type) {
                dim = documento.getTextDimensions(FOOTER_TEXTS[it].text);
                xAux = x + dim.w;

                documento.setTextColor('#4D4D4D');
                documento.text(order_id, xAux, y, { baseline: 'top', maxWidth: page_width - 10 });

                dim = documento.getTextDimensions(order_id);
                xAux += dim.w;

                documento.setTextColor(FOOTER_TEXTS[it].color);
                documento.text(FOOTER_TEXTS[it].text1, xAux, y, { baseline: 'top', maxWidth: page_width - 10 });
            }

            dim = documento.getTextDimensions(FOOTER_TEXTS[it].text);
            y += (dim.h * FOOTER_TEXTS[it].lines) + adjust_size(FOOTER_TEXTS[it].space);
        }
    } else {
        dim = documento.getTextDimensions('Referência'); // neste caso é a palavra maior

        // detectar qual a dimensao recebida maior
        dimEnt = documento.getTextDimensions(entidade);
        dimRef = documento.getTextDimensions(ref);
        dimAum = documento.getTextDimensions('€ ' + amount);

        if (type == 'payshop') {
            x = margins.left + 21 + adjust_size(33) + 21;
            xAux = x + dim.w + 12 + Math.max(Math.max(dimEnt.w, dimRef.w), dimAum.w);

            // right block

            documento.addImage(
                payshop,
                'PNG',
                margins.left + page_width - 65,
                yInitial + adjust_size(15),
                adjust_size(53),
                adjust_size(14)
            );
            documento.addImage(
                ctt,
                'PNG',
                margins.left + page_width - adjust_size(53) - 65 - 25,
                yInitial + adjust_size(12),
                adjust_size(96),
                adjust_size(24)
            );

            // QRCODE
            // documento.addFileToVFS('LibreBarcode39-Regular-normal.ttf', LibreBarcode39);
            // documento.addFont('LibreBarcode39-Regular-normal.ttf', 'LibreBarcode39-Regular', 'normal');
            // documento.addFileToVFS('LibreBarcode128-Regular-normal.ttf', FONT_128);
            // documento.addFont('LibreBarcode128-Regular-normal.ttf', 'LibreBarcode128-Regular', 'normal');

            // const encoder = new Encoder();

            // documento.setFont('LibreBarcode128-Regular');
            documento.setTextColor('#000000');
            // documento.setFontSize(32);
            documento.text(
                // encoder.encode(ref_payshop),
                'Referência de pagamento payshopNet',
                // margins.left + page_width - 30,
                margins.left + page_width - 10,
                // yInitial + adjust_size(28),
                yInitial + adjust_size(42),
                { align: 'right', baseline: 'top' }
            );

            const dimP = documento.getTextDimensions(ref_payshop);
            documento.setFontSize(12);
            documento.setFont('clight');
            documento.text(
                ref_payshop,
                // margins.left + page_width - (dimP.w / 2) + 4,
                // yInitial + adjust_size(22) + dimP.h + 2,
                margins.left + page_width - (dimP.w / 2) - 5,
                yInitial + adjust_size(48) + dimP.h + 2,
                { align: 'right', baseline: 'top' }
            );
        } else {
            // inverte x com x aux porque primeiru a contar da direita sao os valores
            xAux = margins.left + page_width - 21; // content
            x = xAux - dim.w - 12 - Math.max(Math.max(dimEnt.w, dimRef.w), dimAum.w); // labels

            if (type == 'multibanco') {
                documento.addImage(multibanco, 'PNG', x - adjust_size(62) - 21, yInitial + adjust_size(26), adjust_size(33), adjust_size(39));
            } else {
                documento.addImage(mb_way, 'PNG', x - adjust_size(62) - 21, yInitial + adjust_size(26), adjust_size(62), adjust_size(31));
            }

            documento.setFontSize(10);

            documento.setTextColor('#C0C0C0');
            documento.text('Entidade', x, y, { baseline: 'top' });

            documento.setTextColor('#505050');
            documento.text(entidade, xAux, y, { align: 'right', baseline: 'top' });

            y += dim.h + adjust_size(6);

            documento.setTextColor('#C0C0C0');
            documento.text('Referência', x, y, { baseline: 'top' });

            documento.setTextColor('#505050');
            documento.text(ref, xAux, y, { align: 'right', baseline: 'top' });

            y += dim.h + adjust_size(6);

            documento.setTextColor('#C0C0C0');
            documento.text('Montante', x, y, { baseline: 'top' });

            documento.setTextColor('#505050');
            documento.text('€ ' + amount, xAux, y, { align: 'right', baseline: 'top' });
        }
    }
}

const render_order = (documento, page_width, pageY, margins, card_name, card_number, amount, fee, backgroundColor) => {
    let x = margins.left + adjust_size_width(20);
    let dim;
    let pageYLocal = pageY + adjust_size(10);

    documento.setFillColor(backgroundColor);
    documento.rect(margins.left, pageY, page_width, 34, 'F');

    documento.setTextColor('#4D4D4D');
    documento.setFontSize(10);
    documento.text(card_name, x, pageYLocal, { baseline: 'top' });

    dim = documento.getTextDimensions(card_name);

    documento.setTextColor('#505050');
    documento.setFontSize(8);
    documento.text(card_number, x, pageYLocal + dim.h + 2, { baseline: 'top' });

    x = margins.left + page_width - adjust_size_width(20);

    documento.setTextColor('#505050');
    documento.setFontSize(10);
    documento.text(amount.toFixed(2) + ' €', x, pageYLocal, { align: 'right', baseline: 'top' });

    dim = documento.getTextDimensions(amount.toFixed(2) + ' €');

    documento.setTextColor('#505050');
    documento.setFontSize(8);
    documento.text('-' + fee.toFixed(2) + ' €', x, pageYLocal + dim.h + 2, { align: 'right', baseline: 'top' });

    let dim1 = documento.getTextDimensions('-' + fee.toFixed(2) + ' €');

    documento.setTextColor('#505050');
    documento.setFontSize(8);
    documento.text((amount - fee).toFixed(2) + ' €', x, pageYLocal + dim.h + 2 + dim1.h + 2, { align: 'right', baseline: 'top' });

    return pageY + 34;
}

export const generate_order_pdf = (order: Order, user: User) => {
    const documento = new jsPDF({ unit: 'px' });
    const margins = { top: 25, left: adjust_size_width(80), right: adjust_size_width(40), bottom: 20 }; // top : 50, bottom : 25
    const page_width = A4_SIZE_PX.width - margins.left - margins.right;
    let pageY = 0;
    let x;
    let dim;
    let backgroundColor;
    let FOOTER = { properties: { height: 0 }, type: '' };

    // add font
    documento.addFileToVFS('clight-normal.ttf', FONT_CANARO_NORMAL);
    documento.addFont('clight-normal.ttf', 'clight', 'normal');
    documento.setFont('clight');

    // add top image
    documento.addImage(logo, 'PNG', margins.left, margins.top, adjust_size(140), adjust_size(35));
    pageY = margins.top + 25 + adjust_size(35);

    x = A4_SIZE_PX.width - margins.right - adjust_size_width(20);

    const positionLeft = margins.left + adjust_size_width(20);

    // ----------------
    // CABECALHO Pagina
    // ----------------

    documento.setTextColor('#505050');
    documento.setFontSize(8);

    //  --- left ---

    let auxPageY = pageY;

    const firstOrderCard = order.cards[0]; // usa sempre o profile do primeiro cartao
    const profile = user.searchProfileForCardId(firstOrderCard.card_id);
    const mainAddress = profile.getMainAddress();

    const fullname = clearText(profile.fullname);
    const address = clearText(mainAddress.address);
    const zipCity = [clearText(mainAddress.zipcode), clearText(mainAddress.city)].join(', ') + ',';
    const country = clearText(mainAddress.country);

    documento.text(fullname, positionLeft, auxPageY, { align: 'left', baseline: 'top' });

    dim = documento.getTextDimensions(fullname);
    auxPageY += dim.h + 6;

    documento.text(address, positionLeft, auxPageY, { align: 'left', baseline: 'top' });

    dim = documento.getTextDimensions(address);
    auxPageY += dim.h + 6;

    documento.text(zipCity, positionLeft, auxPageY, { align: 'left', baseline: 'top' });

    dim = documento.getTextDimensions(zipCity);
    auxPageY += dim.h + 6;

    documento.text(country, positionLeft, auxPageY, { align: 'left', baseline: 'top' });

    //  --- right ---

    documento.text('Total', x, pageY, { align: 'right', baseline: 'top' });

    dim = documento.getTextDimensions('Total');
    pageY += dim.h + 6;

    documento.setTextColor('#4D4D4D');
    documento.setFontSize(16); // 31
    documento.text(order.amount.toFixed(2) + ' €', x, pageY, { align: 'right', baseline: 'top' });

    dim = documento.getTextDimensions(order.amount.toFixed(2) + ' €');
    pageY += dim.h + 6;

    documento.setTextColor('#505050');
    documento.setFontSize(8);
    documento.text('-' + order.fee.toFixed(2) + ' €', x, pageY, { align: 'right', baseline: 'top' });

    dim = documento.getTextDimensions('-' + order.fee.toFixed(2) + ' €');
    pageY += dim.h + 6;

    documento.setTextColor('#505050');
    documento.setFontSize(8);
    documento.text((order.amount - order.fee).toFixed(2) + ' €', x, pageY, { align: 'right', baseline: 'top' });

    dim = documento.getTextDimensions('€ ' + order.fee.toFixed(2));
    pageY += dim.h + 26;

    // -----
    // TITLE
    // -----

    documento.setFillColor('#FBFBFB');
    documento.rect(margins.left, pageY, page_width, 19, 'F');

    documento.setTextColor('#505050');
    documento.setFontSize(10);
    documento.text('Resumo', positionLeft, pageY + 4, { baseline: 'top' });

    pageY += 19;

    // FIND FOOTER

    switch (order.type) {
        case 0:
            FOOTER.properties = FOOTER_HEIGHTS.mb_payshop;
            FOOTER.type = 'mbway';
            break;
        case 1:
            FOOTER.properties = FOOTER_HEIGHTS.transfer;
            FOOTER.type = 'transfer';
            break;
        case 2:
            FOOTER.properties = FOOTER_HEIGHTS.mb_payshop;
            FOOTER.type = 'multibanco';
            break;
        case 3:
            FOOTER.properties = FOOTER_HEIGHTS.mb_payshop;
            FOOTER.type = 'payshop';
            break;
        default:
            FOOTER.properties = FOOTER_HEIGHTS.other;
            FOOTER.type = null; //'transfer';
            break;
    }

    // RENDER ORDERS
    const footerPosY = A4_SIZE_PX.height - FOOTER.properties.height - margins.bottom;
    for (let it = 0; it < order.cards.length; it++) {
        if ((it % 2) == 0) {
            backgroundColor = '#FFFFFF';
        } else {
            backgroundColor = '#FBFBFB';
        }

        pageY = render_order(
            documento,
            page_width,
            pageY,
            margins,
            order.cards[it].card_name,
            '' + order.cards[it].card_id,
            order.cards[it].amount,
            order.cards[it].fee,
            backgroundColor
        );

        // verificar se esta no footer
        if (pageY >= footerPosY && pageY >= (A4_SIZE_PX.height - margins.bottom - 34)) {
            documento.addPage();

            documento.addImage(logo, 'PNG', margins.left, margins.top, adjust_size(140), adjust_size(35));
            pageY = margins.top + 25 + adjust_size(35);
        }
    }

    if (FOOTER.type != null) {
        // RENDER FOOTER
        const purchase_id = (('' + order.purchase_id).match(/.{1,3}/g)).join(' ');
        render_footer(FOOTER.type, documento, margins, order.entity, order.ref, order.amount.toFixed(2), order.ref_ps || '', purchase_id);
    }

    if (IS_DEV) {
        documento.output('dataurlnewwindow');
    } else {
        documento.save('spark.pdf');
    }
}

// -----------------------
//
// GENERATE HISTORY AS PDF
//
// -----------------------

const HISTORY_HEADERS = [
    {
        labels: ['pdf_data'],
        width: 56.637,
    },
    {
        labels: ['pdf_ordem'],
        width: 56.637,
    },
    {
        labels: ['pdf_resultado'], //'pdf_tipo', 
        width: 56.637,
    },
    // {
    //     labels: ['Entidade /', 'Ref. MB /', 'Ref. Payshop'],
    //     width: 49.5575,
    // },
    {
        labels: ['pdf_mensagem'],
        width: 56.637,
    },
    {
        labels: ['pdf_Spark ID'],
        width: 56.637,
    },
    {
        labels: ['Valor / Comissão'],
        width: 76.637,
    },
    {
        labels: ['pdf_saldo'],
        width: 36.637,
    }
];

const historyHeadder = (documento, margins, page_width, translate: Translate, user: User, card: Card, beginDate: string, endDate: string) => {
    let pageYAux;
    let pageY = 0;
    let x;
    let xAux;
    let dim;

    // logo

    xAux = page_width - 103 + margins.left;

    documento.addImage(logo, 'PNG', xAux, margins.top, 103, 25);
    pageY = margins.top + 25 + margins.top;

    documento.setFontSize(8);

    // user profile and Spark information

    const mainAddress = user.profile.getMainAddress();

    documento.text(user.profile.fullname, margins.left, pageY, { baseline: 'top' });
    documento.text('Av de la Industria 8 PLT1 Officina 3A', xAux, pageY, { baseline: 'top' });
    pageY += 10;

    documento.text(mainAddress.address, margins.left, pageY, { baseline: 'top' });
    documento.text('Alcobendas Madrid', xAux, pageY, { baseline: 'top' });
    pageY += 10;

    documento.text([mainAddress.city, mainAddress.country].join(', '), margins.left, pageY, { baseline: 'top' });
    documento.text('28108', xAux, pageY, { baseline: 'top' });
    pageY += 10;

    documento.text(mainAddress.zipcode, margins.left, pageY, { baseline: 'top' });
    documento.text(translate.transform('Spain'), xAux, pageY, { baseline: 'top' });

    pageY += margins.top;

    // card information

    documento.text(translate.transform('card number') + ': ' + card.cardnumber, margins.left, pageY, { baseline: 'top' });
    pageY += 10;

    documento.text(translate.transform('statement date') + ': ' + beginDate + ' - ' + endDate, margins.left, pageY, { baseline: 'top' });
    pageY += 10;

    documento.text(translate.transform('pdf_saldo') + ': € ' + card.availBal, margins.left, pageY, { baseline: 'top' });

    pageY += margins.top;

    documento.setFillColor('#FBFBFB');
    documento.rect(margins.left, pageY, page_width, 15, 'F');

    x = margins.left;

    HISTORY_HEADERS.forEach((h, it) => {
        documento.setFillColor('#FBFBFB');
        documento.rect(x, pageY, h.width, 15, 'F');

        pageYAux = pageY + 3;
        // x += 3; 
        HISTORY_HEADERS[it].labels.forEach((l) => {
            documento.setTextColor('#505050');
            documento.text(translate.transform(l), x + 3, pageYAux, { baseline: 'top' });

            dim = documento.getTextDimensions(l);
            pageYAux += dim.h + 3;
        });

        x += h.width;
    });

    return pageY + 20;
}

export const generate_history_pdf = (orders, user: User, card: Card, beginDate: string, endDate: string) => {
    const documento = new jsPDF({ unit: 'px' });
    const margins = { top: 25, left: 25, bottom: 25 };
    const page_width = A4_SIZE_PX.width - (margins.left * 2);
    const page_height = A4_SIZE_PX.height - margins.bottom;
    let pageY = 0;
    let dim;
    let x;
    let pageYAux;
    let data;
    let label;

    const translate = new Translate();

    // // add font
    documento.addFileToVFS('clight-normal.ttf', FONT_CANARO_NORMAL);
    documento.addFont('clight-normal.ttf', 'clight', 'normal');

    // render table headers
    documento.setFont('clight');

    pageY = historyHeadder(documento, margins, page_width, translate, user, card, beginDate, endDate);

    documento.setTextColor('#000000');

    orders.forEach((order, it) => {
        if ((it % 2) === 0) {
            documento.setFillColor('#FFFFFF');
        } else {
            documento.setFillColor('#FBFBFB');
        }

        order.orders_cards.forEach((v) => {
            if (pageY >= page_height) {
                documento.addPage();

                pageY = historyHeadder(documento, margins, page_width, translate, user, card, beginDate, endDate);

                documento.setTextColor('#000000');
            }

            pageYAux = pageY + 3;
            x = margins.left + 3;

            data = (order.date || ' ').split(' ');

            // data

            documento.text(data[0], x, pageYAux, { baseline: 'top' });
            // dim = documento.getTextDimensions(data[0]);
            // documento.text(data[1], x, pageYAux + dim.h + 3, { baseline: 'top' });

            // ordem_id

            x += HISTORY_HEADERS[0].width;
            documento.text('' + order.purchase_id, x, pageYAux, { baseline: 'top' });

            // ordem_id tipo / estado

            // label = (v.type === 0) ? 'mbway' : ((v.type === 1) ? 'multibanco' : 'transferencia');

            // x += HISTORY_HEADERS[1].width;
            // documento.text(label, x, pageYAux, { baseline: 'top' });

            // dim = documento.getTextDimensions(label);

            // label = (v.status === 0) ? 'pendente' : ((v.status === 1) ? 'sucesso' : 'erro');
            // documento.text(translate.transform(label), x, pageYAux + dim.h + 3, { baseline: 'top' });

            x += HISTORY_HEADERS[1].width;
            label = (v.status === 0) ? 'pendente' : ((v.status === 1) ? 'sucesso' : 'erro');
            documento.text(translate.transform(label), x, pageYAux, { baseline: 'top' });

            // 'Entidade /', 'Ref. MB /', 'Ref. Payshop'

            // x += HISTORY_HEADERS[2].width;
            // documento.text('' + order.entity, x, pageYAux, { baseline: 'top' });

            // dim = documento.getTextDimensions('' + order.entity);
            // pageYAux += dim.h + 3;
            // documento.text('' + order.ref, x, pageYAux, { baseline: 'top' });

            // dim = documento.getTextDimensions('' + order.ref);
            // pageYAux += dim.h + 3;
            // documento.text('' + order.ref_ps, x, pageYAux, { baseline: 'top' });

            // Mensagem

            pageYAux = pageY + 3; // repor ao valor inicial

            x += HISTORY_HEADERS[2].width;
            documento.text('' + v.message, x, pageYAux, { baseline: 'top', maxWidth: HISTORY_HEADERS[4].width - 5 });

            // ID Secundário

            x += HISTORY_HEADERS[3].width;
            documento.text('' + v.card_id, x, pageYAux, { baseline: 'top', maxWidth: HISTORY_HEADERS[5].width - 5 });

            // 'Valor /', 'Comissão'

            x += HISTORY_HEADERS[4].width;
            documento.text('€' + (v.amount || 0).toFixed(2) + ' / €' + (v.fee || 0).toFixed(2), x, pageYAux, { baseline: 'top' });

            // dim = documento.getTextDimensions('' + v.amount);
            // documento.text('€' + (v.fee || 0).toFixed(2), x, pageYAux + dim.h + 3, { baseline: 'top' });

            // saldo
            x += HISTORY_HEADERS[5].width;
            documento.text('€' + (v.balance || 0).toFixed(2), x, pageYAux, { baseline: 'top' });

            pageY += 25;
        });
    });

    if (IS_DEV) {
        documento.output('dataurlnewwindow');
    } else {
        documento.save('spark.pdf');
    }
}
