import { Component, OnInit, OnDestroy } from '@angular/core';
import { TransferDataService } from 'src/app/services/transferData.service';
import { Router } from '@angular/router';

import { User } from 'src/app/models/user';

import { ReCaptchaV3Service } from 'ngx-captcha';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { first, finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { isEmpty as _isEmpty } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { PopupNotificationComponent } from 'src/app/modals/popup-notification/popup-notification.component';

@Component({
    selector: 'app-create-account',
    templateUrl: './create-account.component.html',
    styleUrls: ['../intro.component.css', './create-account.component.css']
})
export class CreateAccountComponent implements OnInit, OnDestroy {
    constructor(
        private router: Router,
        private transferdataService: TransferDataService,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private authenticationService: AuthenticationService,
        public dialog: MatDialog
    ) { }

    ngOnInit() {
        // const dialogRef = this.dialog.open(PopupNotificationComponent, {
        //     width: '520px',
        // });

        // dialogRef.afterClosed().subscribe(result => {});
    }

    ngOnDestroy() { }

    // METHODS

    onValidCard(ok) {
        if (!ok) {
            this.router.navigate(['/aboutcardregister']);
        } else {
            this.transferdataService.data = {
                op: 'update_links',
                title: 'new_account_step_2',
                bottomLinkText: 'back_to_step_1',
                bottomLink: '/requestpassword'
            }
        }
    }

    onUserDefined() {
        this.transferdataService.data = {
            op: 'update_links',
            title: 'new_account_step_3',
            bottomLinkText: 'back_to_step_1',
            bottomLink: '/requestpassword'
        }
    }

    onUserCreated(user: User) {
        this.reCaptchaV3Service.execute(environment.recaptcha, 'login', (token) => {
            if (_isEmpty(user.username) || _isEmpty(user.password)) {
                this.router.navigateByUrl('/login');
            } else {
                const sub = this.authenticationService.login(user.username, user.password, token)
                    .pipe(finalize(() => sub.unsubscribe()))
                    .subscribe(
                        data => { this.router.navigateByUrl('app/userinfo'); },
                        error => { this.router.navigateByUrl('/login'); }
                    );
            }
        }, {
            useGlobalDomain: false
        });
    }

}
