import { Address } from "./address";
import { Card } from "./card";
import { File } from "./file";

export const DOCUMENT_TYPES = [
  { label: "bi", value: "Id" },
  { label: "passport", value: "Passport" },
  // { label: "driving_license", value: "DrivingLicense" },
];

export class Profile {
  id: number;
  firstname: string;
  lastname: string;
  middlename: string;
  dob: string;
  nationality: string;
  type: number;
  email: string;
  phone: string;
  addresses: Address[];
  cards: Card[];
  files: File[];
  kyc: number;
  p_company_name: string;
  ssn: string;
  employment_status: string;
  occupation: string;
  card_purpose: string;
  monthly_income: string;
  estate: string;
  country_of_issuance: string;
  document_number: string;
  document_type: string;
  document_expiry_date: string;

  termsAndConditions: boolean;
  privacy: boolean;
  newsletter: boolean;

  get fullname(): string {
    let name = this.firstname ? this.firstname : "";
    name += this.middlename ? " " + this.middlename : "";
    name += this.lastname ? " " + this.lastname : "";
    return name;
  }

  get cardsUnlocked() {
    const cardsu = [];
    let it;

    for (it = 0; it < this.cards.length; it++) {
      if (this.cards[it].status === "unlocked") {
        cardsu.push(this.cards[it]);
      }
    }

    return cardsu;
  }

  constructor(obj?: any) {
    Object.assign(this, obj);

    this.cards = [];
    this.addresses = [];
    this.files = [];

    if (obj) {
      let it;
      if ("cards" in obj && obj.cards instanceof Array) {
        for (it = 0; it < obj.cards.length; it++) {
          this.cards.push(new Card(obj.cards[it]));
        }
      }

      if ("addresses" in obj && obj.addresses instanceof Array) {
        for (it = 0; it < obj.addresses.length; it++) {
          this.addresses.push(new Address(obj.addresses[it]));
        }
      }

      if ("files" in obj && obj.files instanceof Array) {
        for (it = 0; it < obj.files.length; it++) {
          this.files.push(new File(obj.files[it]));
        }
      }
    }
  }

  searchCardById(id): Card {
    if (this.cards) {
      return this.cards.find((c) => c.id == id);
    }
    return null;
  }

  addAddressObj(address: Address) {
    this.addresses.push(address);
  }

  addAddress(
    address: string,
    city: string,
    country: string,
    state: string,
    zipcode: string,
    main: number
  ) {
    const addr = new Address();
    addr.address = address;
    addr.city = city;
    addr.country = country;
    addr.state = state;
    addr.zipcode = zipcode;
    addr.main = main;

    this.addresses.push(addr);
  }

  getMainAddress(): Address {
    if (this.addresses || this.addresses.length > 0) {
      for (var it = 0; it < this.addresses.length; it++) {
        if (this.addresses[it].main == 0) {
          return this.addresses[it];
        }
      }
    }

    return new Address();
  }

  getFileByType(type: number): File {
    for (var it = 0; it < this.files.length; it++) {
      if (type == this.files[it].type) {
        return this.files[it];
      }
    }

    return null;
  }

  hasCardWithKycBiggerThan(kyc: number): boolean {
    for (let i = 0; i < this.cards.length; i++) {
      if (this.cards[i].tierAsNumber > kyc) {
        return true;
      }
    }

    return false;
  }
}
