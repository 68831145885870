<div class="mContainer">
    <ng-container *ngIf="typeError == 1">
        <div class="mContainer-text-title mContainer-space">
            {{ 'special-error-1' | translate }}
        </div>
        <div class="mContainer-text-title">
            {{ 'special-error-2' | translate }}
        </div>
        <div>
            {{ 'special-error-3' | translate }}
        </div>
        <div>
            {{ 'special-error-4' | translate }}
        </div>
        <div>
            {{ 'special-error-5' | translate }}
        </div>
        <div class="mContainer-space">
            {{ 'special-error-6' | translate }}
        </div>
    </ng-container>

    <div class="containerStatus3" *ngIf="typeError == 3">
        <div class="mContainer-space">
            {{'050_error_message' | translate}}
        </div>        
    </div>

    <!-- <div class="containerStatus3" *ngIf="typeError == 3">
        <div class="mContainer-space">
            {{'special-error-create-account-1' | translate}}
        </div>

        <div class="mContainer-space">
            {{'special-error-create-account-2' | translate}}
        </div>

        <div class="mContainer-space">
            {{'special-error-create-account-3' | translate}}
        </div>

        <div class="mContainer-space">
            <span class="mContainer-text-title">{{'special-error-create-account-4-1' | translate}}</span>
            <span>{{'special-error-create-account-4-2' | translate}}</span>
        </div>

        <div class="mContainer-space">
            <span class="mContainer-text-title">{{'special-error-create-account-5-1' | translate}}</span>
            <span>{{'special-error-create-account-5-2' | translate}}</span>
        </div>

        <div class="mContainer-space">
            {{'special-error-create-account-1' | translate}}
        </div>
    </div> -->

    <div class="mContainer-contacts {{ (typeError == 3) ? 'leftMargin' : '' }}">
        <div>
            <span class="mContainer-text-title">
                {{ 'special-error-7' | translate }}
            </span>
            <br>
            +34 91 29 39 789
        </div>
        <div>
            <span class="mContainer-text-title">
                {{ 'special-error-8' | translate }}
            </span>
            <br>
            +351 707 500 101
        </div>
    </div>
</div>