import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { CardService } from 'src/app/services/card.service';
import { first, finalize } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { Profile } from 'src/app/models/profile';
import { Address } from 'src/app/models/address';
import { FormGroup } from '@angular/forms';
import { FormUserStep0Component } from 'src/app/user/form-user/form-user-step0/form-user-step0.component';
import { FormUserStep2Component } from 'src/app/user/form-user/form-user-step2/form-user-step2.component';
import { FormUserStep1Component } from 'src/app/user/form-user/form-user-step1/form-user-step1.component';
import { FormUserStep3Component } from 'src/app/user/form-user/form-user-step3/form-user-step3.component';

@Component({
    selector: 'app-card-popup-add-card',
    templateUrl: './card-popup-add-card.component.html',
    styleUrls: ['./card-popup-add-card.component.css']
})
export class CardPopupAddCardComponent implements OnInit {
    @Output() onFinish = new EventEmitter<boolean>();

    @ViewChild(FormUserStep0Component)
    private formUserStep0Component: FormUserStep0Component;

    @ViewChild(FormUserStep1Component)
    private formUserStep1Component: FormUserStep1Component;

    @ViewChild(FormUserStep2Component)
    private formUserStep2Component: FormUserStep2Component;

    @ViewChild(FormUserStep3Component)
    private formUserStep3Component: FormUserStep3Component;

    steps = 0;

    profile: Profile;
    verifyResult;

    constructor(
        private alertService: AlertService,
        private cardService: CardService,
        private userService: UserService,
        private router: Router
    ) { }

    ngOnInit() { }

    // STEP 0

    onValidCard(obj: any) {
        this.verifyResult = obj;
        if (this.verifyResult.typeKyc != '0') {
            this.steps = 3;
            this.profile = new Profile();
            // this.addCard(this.verifyResult.name, this.verifyResult.cardHolderId, this.verifyResult.cardNumber, null);
        } else {
            // os formularios de 1 novo perfil devem ser preenchidos
            this.steps = 1;
        }
    }

    // Quando o kyc do cartao é > 0 entao basta nos adicionar o cartao
    // senao temos de mandar os dados todos
    addCard(name: string, id: string, cardnumber: string, profile: Profile) {
        this.alertService.loading(true)
        this.cardService.create(id, cardnumber, name, profile)
            .pipe(first())
            .pipe(finalize(() => this.alertService.loading(false)))
            .subscribe(
                data => {
                    this.userService.refreshUser();
                    this.onFinish.emit(true);
                    this.alertService.success('card_add_success');
                    this.router.navigate(['/app/cards/transactions']);
                },
                error => this.alertService.error(error.error)
            );
    }

    // OTHER STEPS

    step1OnNextPress(profile: Profile) {
        this.profile = profile;
        this.steps = 2;
    }

    step2OnNextPress(address: Address) {
        this.profile.addAddressObj(address);
        this.steps = 3;
        // this.addCard(this.verifyResult.name, this.verifyResult.cardHolderId, this.verifyResult.cardNumber, this.profile);
    }

    step3OnNextPress(dataCollectForm: FormGroup) {
        if (dataCollectForm) {
            const dcf = dataCollectForm.controls;
            this.profile.ssn = dcf.ssn.value;
            this.profile.employment_status = dcf.employment_status.value;
            this.profile.occupation = dcf.occupation.value;
            this.profile.card_purpose = dcf.card_purpose.value;
            this.profile.monthly_income = dcf.monthly_income.value;
            this.profile.estate = dcf.estate.value;
        }

        this.addCard(this.verifyResult.name, this.verifyResult.cardHolderId, this.verifyResult.cardNumber, this.profile);
    }

    // Funcao chamada no parent
    onConfirmPress(): boolean {
        switch (this.steps) {
            case 0:
                this.formUserStep0Component.verifyCardSubmit();
                break;
            case 1:
                this.formUserStep1Component.verifyUserSubmit();
                break;
            case 2:
                this.formUserStep2Component.verifyViewStep3DataForm();
                break;
            case 3:
                this.formUserStep3Component.verifyCollectDataForm();
                break;
        }

        return true;
    }
}
