<div class="container">
    <img src="../../../assets//images/email.png">

    <div class="title">{{ 'email_verification_title' | translate }}</div>

    <div class="description">
        {{ 'email_verification_desc' | translate }}
        <!-- <br>
        <span class="desc-special">
            {{ 'account_will_be_removed' | translate }}
        </span> -->
    </div>

    <div class="resend_email" (click)="resendConfirmEmail()">{{ 'resend_email' | translate }}</div>
</div>