import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isEmpty as _isEmpty } from 'lodash';

@Component({
    selector: 'app-popup-activation-card',
    templateUrl: './popup-activation-card.component.html',
    styleUrls: ['./popup-activation-card.component.css']
})
export class PopupActivationCardComponent implements OnInit {
    constructor(
        private dialogRef: MatDialogRef<PopupActivationCardComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() { }

    // events

    okClick(): void {
        this.dialogRef.close('goToRegister');
    }
}
