export class CreditCard {
    id: string;
    cardnumber: string;
    expires_date: string;
    status: number;
    visible: boolean;

    constructor(obj = {}) {
        Object.assign(this, obj);
    }
}