import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

import { environment } from "../../environments/environment";

import { Card } from "../models/card";
import { User } from "../models/user";
import { Profile } from "../models/profile";

@Injectable({
  providedIn: "root",
})
export class CardService {
  basePath: string;

  constructor(private http: HttpClient) {
    this.basePath = environment.baseUrl;
  }

  create(id: string, cardnumber: string, name: string, profile: Profile = null) {
    return this.http.post(`${this.basePath}/cards/addasnewprofile`, {
      id: id,
      cardNumber: cardnumber,
      name: name,
      profile: profile,
    });
  }

  getById(id: string) {
    return this.http
      .post<Card>(`${this.basePath}/cards/details`, { id: id })
      .pipe(map((res) => new Card(res)));
  }

  verifyAndReturnKyc(id: string, cardNumber: string, token: string, email: string = "") {
    return this.http.post(`${this.basePath}/cards/verify`, {
      id: id,
      cardNumber: cardNumber,
      returnInfo: true,
      recaptcha_token: token,
      email: email,
    });
  }

  verify(id: string, cardNumber: string) {
    return this.http.post(`${this.basePath}/cards/verify`, {
      id: id,
      cardNumber: cardNumber,
    });
  }

  updateCardEmail(id: string, email: string) {
    return this.http.post(`${this.basePath}/cards/updateemail`, {
      id: id,
      email: email,
    });
  }

  pinReminder(id: string) {
    return this.http.post(`${this.basePath}/cards/pinreminder`, { id: id });
  }

  register(id: string, cardNumber: string, user: User, token: string, language: string) {
    return this.http.post(`${this.basePath}/cards/register`, {
      id: id,
      cardNumber: cardNumber,
      user: user,
      recaptcha_token: token,
      language: language,
    });
  }

  registerCardExistingAccount(
    id: string,
    cardNumber: string,
    profile: Profile,
    token: string,
    language: string
  ) {
    return this.http.post(`${this.basePath}/cards/registercardexistingaccount`, {
      id: id,
      cardNumber: cardNumber,
      profile: profile,
      recaptcha_token: token,
      language: language,
    });
  }

  updateStatus(id: string, status: string) {
    return this.http.post(`${this.basePath}/cards/status`, {
      id: id,
      status: status,
    });
  }

  updateName(id: string, name: string) {
    return this.http.post(`${this.basePath}/cards/name`, {
      id: id,
      name: name,
    });
  }

  availablePayments(id: string) {
    return this.http.post<Array<number>>(`${this.basePath}/cards/availablepayments`, { id: id });
  }

  hideCard(id: string) {
    return this.http.post(`${this.basePath}/cards/hide`, { id: id });
  }

  requestPhoneConfirmationCode(phone: string, countryCode: string, token: string) {
    return this.http.post(`${this.basePath}/cards/requestphoneconfirmation`, {
      phone: phone,
      country: countryCode,
      recaptcha_token: token,
    });
  }

  activate(cardholderid: string, phone: string, countryCode: string, code: string, token: string) {
    return this.http.post(`${this.basePath}/cards/activatecard`, {
      id: cardholderid,
      phone: phone,
      country: countryCode,
      code: code,
      recaptcha_token: token,
    });
  }

  buycard(
    paymentType: number,
    name: string,
    address: string,
    address2: string,
    email: string,
    phone: string,
    zipcode: string,
    country: string,
    city: string,
    quantity: number,
    token: string,
    lang: string = "en"
  ) {
    const data = {
      type: paymentType,
      name: name,
      address: address,
      address2: address2,
      email: email,
      phone: phone,
      zipcode: zipcode,
      country: country,
      city: city,
      quantity: quantity,
      recaptcha_token: token,
      lang: lang,
    };

    return this.http.post(`${this.basePath}/purchasecard`, {
      purchase_card: data,
    });
  }

  requestKycDocumentsUploadAddress(
    id: string,
    documentNumber: string,
    documentType: string,
    documentExpiryDate: string,
    countryOfIssuance: string,
    language: string
  ) {
    return this.http.post(`${this.basePath}/cards/${id}/request-kyc`, {
      documentNumber,
      documentType,
      documentExpiryDate,
      countryOfIssuance,
      language,
    });
  }

  requestKycDocumentsUploadAddressSimple(id: string, language: string) {
    return this.http.post(`${this.basePath}/cards/${id}/request-kyc`, { language });
  }
}
