import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthenticationService } from '../../services/authentication.service';
import { AlertService } from '../../services/alert.service';

import { ReCaptchaV3Service } from 'ngx-captcha';
import { first, finalize } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-recovery-password',
    templateUrl: './recovery-password.component.html',
    styleUrls: ['../intro.component.css', './recovery-password.component.css']
})
export class RecoveryPasswordComponent implements OnInit {
    loading = false;
    rpForm: FormGroup;
    isChangePasswordForm = false;

    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private alertService: AlertService,
        private router: Router,
        private reCaptchaV3Service: ReCaptchaV3Service,
        private activatedRoute: ActivatedRoute,
    ) { }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            const token = params['token'] || '';
            this.isChangePasswordForm = ('token' in params);
            this.buildForm(token);
        });
    }

    get f() { return this.rpForm.controls; }

    buildForm(token) {
        if (this.isChangePasswordForm) {
            this.rpForm = this.formBuilder.group({
                password: ['', [Validators.required, Validators.minLength(6)]],
                confirmpassword: ['', [Validators.required, Validators.minLength(6)]],
                token: [token, [Validators.required]]
            });
        } else {
            this.rpForm = this.formBuilder.group({
                email: ['', [Validators.required]], //, Validators.email
            });
        }
    }

    onSubmit() {
        if (this.loading || this.rpForm.invalid) {
            return;
        }

        if (this.isChangePasswordForm) {
            const verifySpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

            if (!verifySpecialChar.test(this.f.password.value)) {
                this.alertService.error('password_chards');
                return;
            }

            const format = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

            if (!format.test(this.f.password.value)) {
                this.alertService.error('password_chards');
                return;
            }

            if (this.isChangePasswordForm && this.f.password.value != this.f.confirmpassword.value) {
                this.alertService.error('wrong_password_and_confirm_password');
                this.f.confirmpassword.setErrors({ 'incorrect': true });
                return;
            }
        }

        this.loading = true;

        this.reCaptchaV3Service.execute(environment.recaptcha, 'login', (token) => {
            if (this.isChangePasswordForm) {
                this.authenticationService.recover_password(this.f.password.value, this.f.token.value, token)
                    .pipe(first())
                    .pipe(finalize(() => { this.loading = false; }))
                    .subscribe(
                        data => {
                            this.router.navigate(['/login']);
                            this.alertService.success('recovery_password_success');
                        },
                        error => this.alertService.error('recovery_password_error')
                    );
            } else {
                this.authenticationService.request_recover_password(this.f.email.value, token, localStorage.getItem('language'))
                    .pipe(first())
                    .pipe(finalize(() => { this.loading = false; }))
                    .subscribe(
                        data => {
                            this.router.navigate(['/login']);
                            this.alertService.success('requesT_recovery_password_success');
                        },
                        error => this.alertService.error('requesT_recovery_password_error')
                    );
            }
        }, {
            useGlobalDomain: false
        });
    }
}
