import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { ReCaptchaV3Service } from 'ngx-captcha';
import { AlertService } from 'src/app/services/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['../intro.component.css', './verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit, OnDestroy {
  private userSubscription: Subscription;
  timeoutEvent;

  constructor(
    private router: Router,
    private userService: UserService,
    private alertService: AlertService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit() {
    this.loadUser();
    this.userSubscription = this.userService.onUserChange().subscribe(modified => this.loadUser());
    this.userService.refreshUser();
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.timeoutEvent) {
      clearTimeout(this.timeoutEvent);
    }
  }

  loadUser() {
    const user = this.userService.authenticated_user;

    if (user.status != -1 && user.status !== 2) {
      this.router.navigate(['/app/cards/carddetails'])
    } else {
      if (this.timeoutEvent) {
        clearTimeout(this.timeoutEvent);
      }

      this.timeoutEvent = setTimeout(() => {
        this.userService.refreshUser();
      }, 5000);
    }
  }

  resendConfirmEmail() {
    this.reCaptchaV3Service.execute(environment.recaptcha, 'login', (token) => {
      this.userService.resend_confirm_email(token)
        .pipe(first())
        .subscribe(
          data => this.alertService.success('email_enviado'),
          err => this.alertService.error('Bad Request'),
        );
    }, {
      useGlobalDomain: false
    });
  }
}
