import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserService } from '../services/user.service';
import { TransferDataService } from '../services/transferData.service';
import { CardService } from '../services/card.service';

import { User } from '../models/user';
import { Card } from '../models/card';
import { first, finalize } from 'rxjs/operators';
import { CommissionService } from '../services/commission.service';
import { Commission } from '../models/commission';
import { RequestLocationService } from '../services/request-location.servvice';
import { CARD_SELECTED } from '../helpers/localstorage-utils';
import { CreditCardService } from '../services/credit_card.service';
import { AlertService } from '../services/alert.service';
import { CreditCard } from '../models/creditcard';

@Component({
    selector: 'app-card-transfer',
    templateUrl: './transfer.component.html',
    styleUrls: ['./transfer.component.css']
})
export class TransferComponent implements OnInit, OnDestroy {
    private subscription: Subscription;

    minLimit = 50;

    user: User = new User();
    deposit = [{
        card: new Card(),
        value: 0,
        input_value: '',
    }];
    availablePayments = []; // ['mbway', 'multibanco', 'transfer']; 
    paymenteMethod = -1;
    showListCards = false;
    commissions: Commission[] = [];
    fee = 0;
    listCards: Card[];
    countryCode = 'es';

    creditCards: CreditCard[] = [];
    creditCardIdSelected: number = null; // null - ainda nada foi selecionado | 0 - novo | > 0 - existentes

    constructor(
        private userService: UserService,
        private transferDataService: TransferDataService,
        private cardService: CardService,
        private commissionService: CommissionService,
        private requestLocationService: RequestLocationService,
        private creditCardService: CreditCardService,
        private alertService: AlertService,
    ) { }

    ngOnInit() {
        this.loadUser();
        this.subscription = this.userService.onUserChange().subscribe(modified => this.loadUser());
        this.countryCode = 'PT';

        // nao usar o forkjoin por o segundo pedido (requestLocationService.getLocation()) tende a dar erro em espanha
        const comSub = this.commissionService.list()
            .pipe(finalize(() => comSub.unsubscribe()))
            .subscribe(dataCommissions => {
                this.commissions = dataCommissions || [];
            });
        // const locSub = this.requestLocationService.getLocation()
        //     .pipe(finalize(() => locSub.unsubscribe()))
        //     .subscribe(dataGeo => {
        //         this.countryCode = dataGeo['country_code'] || 'es';
        //     });
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    loadUser() {
        this.user = this.userService.authenticated_user;
        this.listCards = [];

        if (this.user) {
            const cardsu = this.user.cardsUnlocked;
            const selectedCardId = localStorage.getItem(CARD_SELECTED);

            const depAux = cardsu.reduce((acc, c) => {
                if (c.id === selectedCardId) {
                    acc.defSel = c;
                } else {
                    acc.dep.push(c);
                }
                return acc;
            }, { defSel: null, dep: [] });

            if (depAux.defSel == null) {
                if (depAux.dep.length > 0) {
                    this.deposit[0].card = depAux.dep[0];
                    this.listCards = depAux.dep;
                    this.listCards.shift();
                }
            } else {
                this.deposit[0].card = depAux.defSel;
            }

            if (this.deposit[0].card.id) {
                const sub = this.cardService.availablePayments(this.deposit[0].card.id)
                    .pipe(finalize(() => sub.unsubscribe()))
                    .subscribe(
                        data => this.availablePayments = data,
                        error => this.availablePayments = [],
                    );
            }
        }
    }

    get total() {
        let total = 0;
        let it;

        for (it = 0; it < this.deposit.length; it++) {
            if (this.deposit[it].value >= 0) {
                total += this.deposit[it].value;
            }
        }

        // compute fee
        this.fee = 0;

        if (total > 0) {
            for (it = 0; it < this.commissions.length; it++) {
                if (this.paymenteMethod === this.commissions[it].transaction_type) {
                    this.fee += this.commissions[it].fixed + (total * this.commissions[it].variable);
                }
            }
        }

        return total;
    }

    isPaymentAvailable(code) {
        return this.availablePayments.find((v) => v === code) !== undefined;
    }

    isSubmitButtonLocked(): boolean {
        return (this.paymenteMethod == -1 || this.total < this.minLimit);
        // return (this.paymenteMethod == -1 || this.total < this.minLimit || (this.paymenteMethod == 4 && this.creditCardIdSelected == null));
    }

    // ------
    // events
    // ------

    onSelectPaymentMethod(paymentMethod: number): void {
        this.paymenteMethod = paymentMethod;
        this.creditCardIdSelected = null;

        if (this.paymenteMethod == 4) { // mymoid
            this.alertService.loading(true);

            const sub = this.creditCardService.list()
                .pipe(finalize(() => {
                    sub.unsubscribe();
                    this.alertService.loading(false);
                }))
                .subscribe(data => this.creditCards = data);
        }
    }

    onInputValueChange(input: any, deposit: any) {
        const maxAmountLoad = 1000; // 5000
        const maxAmountLoadAsText = '' + maxAmountLoad;

        let inputValue = input.target.value;

        if (inputValue.indexOf('.') > -1) {
            const aux = inputValue.split('.');
            if (aux[0] === maxAmountLoadAsText) {
                inputValue = maxAmountLoadAsText;
            } else if (aux[1].length > 2) {
                inputValue = aux[0] + '.' + aux[1].slice(0, 2);
            }
        }

        let value = 0;
        try {
            value = parseInt(inputValue, 10);
        } catch (er) { }

        if (value > maxAmountLoad) {
            input.target.value = inputValue.slice(0, (inputValue.length - 1));
            deposit.input_value = input.target.value;

            try {
                deposit.value = parseInt(input.target.value, 10);
            } catch (er) { }
        } else {
            input.target.value = inputValue; // para nao exceder os 7 caracters
            deposit.value = value;
            deposit.input_value = inputValue;
        }
    }

    onCardRemove(i: number) {
        const card = this.deposit[i].card;
        this.deposit = this.deposit.filter((item, index) => index !== i);

        // adiciona a lista de cartoes disponiveis para adicionar
        this.listCards.push(card);
    }

    onAddCardsCallback(addedKeys: any) {
        for (let it in addedKeys) {
            if (addedKeys[it] == true) {
                this.deposit.push({
                    card: this.listCards[it],
                    value: 0,
                    input_value: '',
                });

                // ja o adicionou nao pode adicionar outra vez
                this.listCards = this.listCards.filter((item, index) => this.listCards[it].id != item.id);
            }
        }

        this.showListCards = false;
    }

    onConfirmPress() {
        if (this.paymenteMethod == -1 || this.total < this.minLimit) { // 20
            return;
        }

        this.transferDataService.data = {
            operation: 'transfer-popup',
            deposit: this.deposit,
            paymenteMethod: this.paymenteMethod,
            commission: this.fee,
            creditCard: this.creditCardIdSelected,
        };
    }
}
