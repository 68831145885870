import { Component, OnInit, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { finalize, first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { Card } from '../../models/card';

import { CardService } from '../../services/card.service';
import { CardPopupService } from '../../services/card-popup.service';
import { AlertService } from '../../services/alert.service';
import { TransferDataService } from '../../services/transferData.service';
import { CardPopupAddCardComponent } from './card-popup-add-card/card-popup-add-card.component';
import { Translate } from 'src/app/locales/translate';

@Component({
    selector: 'app-card-popup',
    templateUrl: './card-popup.component.html',
    styleUrls: ['./card-popup.component.css']
})
export class CardPopupComponent implements OnInit, OnDestroy {
    @ViewChild(CardPopupAddCardComponent)
    private cardPopupAddCardComponent: CardPopupAddCardComponent;

    private subscription: Subscription;

    showPopup = false;
    op = '';
    card: Card = new Card();

    constructor(
        private titleService: Title,
        private cardPopupService: CardPopupService,
        private cardService: CardService,
        private alertService: AlertService,
        private transferDataService: TransferDataService,
    ) { }

    ngOnInit() {
        this.subscription = this.cardPopupService.getOperation().subscribe(data => this.onDataChangeBySubscribe(data));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onDataChangeBySubscribe(data) {
        if ('op' in data) {
            this.showPopup = true;
            this.card = data.card;
            this.op = data.op;

            const translate = new Translate();
            switch (data.op) {
                case 'addcard':
                    this.titleService.setTitle(translate.transform('add_new_card'));
                    break;
                case 'changepin':
                    this.titleService.setTitle(translate.transform('consult_pin'));
                    break;
                case 'lockorunlockcard':
                    this.titleService.setTitle(
                        (data.card.status === 'unlocked')
                            ? translate.transform('lock_card')
                            : translate.transform('unlock_card')
                    );
                    break;
                case 'lost':
                    this.titleService.setTitle(translate.transform('lost_card'));
                    break;
                case 'stolen':
                    this.titleService.setTitle(translate.transform('stolen_card'));
                    break;
            }
        }
    }

    // @HostListener('window:keyup', ['$event'])
    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (this.showPopup && event.keyCode === 27) {
            this.showPopup = false;
        }
    }

    onClosePopup() {
        this.showPopup = false;
        this.transferDataService.data = { op: 'refreshMenu' };
    }

    onConfirm() {
        switch (this.op) {
            case 'changepin':
                this.showPopup = false;
                this.alertService.loading(true);
                this.cardService.pinReminder(this.card.id)
                    .pipe(first())
                    .pipe(finalize(() => this.alertService.loading(false)))
                    .subscribe(
                        data => this.alertService.success('send_pin_success'),
                        error => this.alertService.specialError() //this.alertService.error('api_internal_error')
                    );
                break;
            case 'lockorunlockcard':
                this.showPopup = false;
                if (this.card.status == 'locked') {
                    this.changeCardStatus('unlock');
                } else {
                    this.changeCardStatus('lock');
                }
                break;
            case 'lost':
                this.showPopup = false;
                this.changeCardStatus('lost');
                break;
            case 'stolen':
                this.showPopup = false;
                this.changeCardStatus('stolen');
                break;
            case 'addcard':
                if (this.cardPopupAddCardComponent) {
                    this.cardPopupAddCardComponent.onConfirmPress();
                }
                break;
            case 'removecard':
                this.showPopup = false;
                this.alertService.loading(true);
                this.cardService.hideCard(this.card.id)
                    // .pipe(finalize(() => this.alertService.loading(false)))
                    .subscribe(
                        data => {
                            this.alertService.success('removecard')
                            setTimeout(() => location.reload(), 500);
                        },
                        error => this.alertService.specialError() //this.alertService.error('api_internal_error')
                    );
                break;
            default:
                break;
        }
    }

    changeCardStatus(new_status) {
        this.alertService.loading(true);
        this.cardService.updateStatus(this.card.id, new_status)
            .pipe(first())
            .pipe(finalize(() => this.alertService.loading(false)))
            .subscribe(
                data => {
                    this.card.status = data as string;
                    this.alertService.success('update_card_status_success');
                },
                error => this.alertService.error(error.error)
            );
    }
}
