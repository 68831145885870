import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-helper-input',
  templateUrl: './helper-input.component.html',
  styleUrls: ['./helper-input.component.css']
})
export class HelperInputComponent implements OnInit {
    @Input() image = '';
    helperVisible = false;

    constructor() {}

    ngOnInit() {}
}