import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Card } from '../models/card';

@Injectable()
export class CardPopupService {
    private subject = new Subject<any>();

    constructor() {}

    showPopup(operation: string, card: Card) {
        this.subject.next({ op: operation, card: card });
    }

    getOperation(): Observable<any> {
        return this.subject.asObservable();
    }
}