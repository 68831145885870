import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

import { AuthenticationService } from '../../services/authentication.service';
import { CardPopupService } from '../../services/card-popup.service';
import { AlertService } from '../../services/alert.service';

import { UserService } from '../../services/user.service';
import { User } from '../../models/user';
import { Profile } from 'src/app/models/profile';
import { Translate } from 'src/app/locales/translate';
import { CARD_SELECTED } from 'src/app/helpers/localstorage-utils';
import { finalize } from 'rxjs/operators';
import { CardService } from 'src/app/services/card.service';

@Component({
    selector: 'app-layout-layout',
    templateUrl: './app-layout.component.html',
    styleUrls: ['./app-layout.component.css']
})
export class AppLayoutComponent implements OnInit, OnDestroy {
    private userSubscription: Subscription;

    showMenu = false;
    user: User = new User();
    selectedFlag = localStorage.getItem('language') || 'pt';

    loadCardEnabled = false;

    constructor(
        private titleService: Title,
        private router: Router,
        private userService: UserService,
        private authenticationService: AuthenticationService,
        private cardPopupService: CardPopupService,
        private alertService: AlertService,
        private cardService: CardService,
    ) {
    }

    ngOnInit() {
        if (!this.authenticationService.isJwtSaved()) {
            this.authenticationService.logout();
            this.router.navigate(['/login']);
        }

        this.loadUser();
        this.userSubscription = this.userService.onUserChange().subscribe(modified => this.loadUser());
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    // GETS

    get profile() { return (this.user.profile) ? this.user.profile : new Profile() }

    get canUseLoadCard() {
        // temos de verificar se no main profile ou nos outros existe algum cartao com kyc > 0
        return (this.user.permission == '1' && this.user.canLoadCards() && this.loadCardEnabled);
    }

    // METHODS

    loadUser() {
        this.user = this.userService.authenticated_user;

        if (this.user.status === 3) {
            // popup erro contact suporte
            location.href = '/contactsupport';
        } else if (this.user.status !== -1 && this.user.status === 2) {
            // this.router.navigate(['/verifyemail']);
            location.href = '/verifyemail'; // por algum motivo que tenho de perceber melhor o primeiro nao da
        } else {
            if (this.user.profiles && this.user.profiles.length > 0 && this.user.profiles[0].kyc < 1) {
                this.alertService.fixedMessage(
                    'kyc_not_complete_message',
                    'app/userinfo'
                );
            } else {
                this.alertService.disablefixedMessage();
            }
        }

        // const cardsu = this.user.cardsUnlocked;
        const selectedCardId = localStorage.getItem(CARD_SELECTED);

        if (selectedCardId) {
            const sub = this.cardService.availablePayments(selectedCardId)
                .pipe(finalize(() => sub.unsubscribe()))
                .subscribe(
                    data => this.loadCardEnabled = (data) ? data.length > 0 : false,
                    error => this.loadCardEnabled = false,
                );
        }
    }

    logout(): void {
        this.userService.clear();
        this.authenticationService.logout();
        this.router.navigate(['/login'])
            .then(() => {
                // Reload Angular to refresh components and prevent old data from loading up for a 
                // another user after login. This especially applies lazy loading cases. 
                location.reload();
            });
    }

    toggleMenu(): void {
        this.showMenu = !this.showMenu;
    }

    selectProfile(): void {
        this.showMenu = false;
    }

    goToUserInfo() {
        this.showMenu = false;
        this.router.navigate(['app/userinfo']);
    }

    onAddCardPress() {
        this.showMenu = false;
        this.cardPopupService.showPopup('addcard', null);
        const translate = new Translate();
        this.titleService.setTitle(translate.transform('add_new_card'));
    }

    goToCardMenu() {
        this.showMenu = false;
        this.router.navigate(['app/cards/transactions']);
    }

    goToOrdersList() {
        this.showMenu = false;
        this.router.navigate(['app/orders']);
    }

    goToMakeOrder() {
        this.router.navigate(['app/transfer']);
    }

    selectLanguage(lang) {
        if (this.selectedFlag != lang) {
            localStorage.setItem('language', lang);
            location.reload();
        }
    }
}