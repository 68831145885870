import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AlertService } from '../services/alert.service';
import { Translate } from '../locales/translate';

const CLOSE_TIMEOUT = 10000; // 6000

@Component({
    selector: 'alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['alert.component.css']
})
export class AlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;
    fixedMessage = {
        visible: false,
        text: '',
        link: ''
    };
    timeout = null;
    loadingVisible = false;
    type: number = 0;
    specialType: string = '';

    constructor(
        private alertService: AlertService,
        private router: Router,
    ) { }

    isSpecialType(): boolean {
        return this.type == 1;
    }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe(message => {
            if (!(message)) {
                return;
            }

            switch (message.type) {
                case 'special-error':
                    this.specialType = message.message || '';
                    this.type = 1;
                    break;
                case 'fixed':
                    this.type = 0;
                    this.fixedMessage = {
                        visible: true,
                        text: message.text,
                        link: message.link,
                    };
                    break;
                case 'rFixed':
                    this.type = 0;
                    this.fixedMessage = {
                        visible: false,
                        text: '',
                        link: '',
                    };
                    break;
                case 'loading':
                    this.loadingVisible = message.show || false;
                    break;
                default:
                    this.type = 0;
                    if (this.timeout) {
                        clearTimeout(this.timeout);
                    }

                    this.message = message;
                    this.timeout = setTimeout(this.onClosePress.bind(this), CLOSE_TIMEOUT);
                    break;
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    jumpToLink() {
        if (this.fixedMessage && this.fixedMessage.link) {
            this.router.navigate([this.fixedMessage.link]);
        }
    }

    onClosePress() {
        this.message = null;
    }

    displayMessage(message: string) {
        const translate = new Translate();
        let tMsg = translate.transform(message);

        if ('amount' in this.message) {
            tMsg = tMsg.replace('[AMOUNT]', this.message.amount);
        }

        if ('cardid' in this.message) {
            tMsg = tMsg.replace('[CARID]', this.message.cardid);
        }

        return (tMsg == '') ? translate.transform('cant save on external database') : tMsg;
    }
}