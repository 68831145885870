<div class="title">
  {{ 'info_about_card' | translate }}
</div>

<div class="list">
  <div class="list-item">
    <span class="list-item-title">{{ 'name' | translate }}</span>
    <div class="list-item-description">
      <span *ngIf="!editCardName">{{ card?.name }}</span>
      <div *ngIf="editCardName && isAdministrator"
        class="my-form-input {{ (card.name == '') ? 'my-form-input-error' : '' }}">
        <input #cardname type="text" id="cardname" placeholder="{{ 'name' | translate }}" value="{{ card.name }}"
          (keydown)="onCardNameInputKeydown($event)">
      </div>
    </div>
    <div *ngIf="isAdministrator" class="list-item-btn-container" (click)="changeCardNamePress()">
      <span *ngIf="!editCardName">{{ 'edit' | translate }}</span>
      <span *ngIf="editCardName">{{ 'save' | translate }}</span>
    </div>
  </div>

  <div class="list-item">
    <span class="list-item-title">{{ 'validate_date' | translate }}</span>
    <span class="list-item-description">{{ displayExpirationDate(card?.expirationDate) }}</span>
  </div>

  <div class="list-item">
    <span class="list-item-title">{{ 'currency' | translate }}</span>
    <span class="list-item-description">{{ currencyName }}</span>
  </div>
</div>

<div style="height: 20px;" *ngIf="isAdministrator"></div>

<div class="title" *ngIf="isAdministrator">
  <div>{{ 'card_credentials' | translate }}</div>
  <div *ngIf="!cardCredentialsExists" style="height: 7px;"></div>
  <button class="c-button" *ngIf="!cardCredentialsExists" mat-raised-button color="warn"
    (click)="cardCredentialsExists = true">
    {{ 'create' | translate }}
  </button>
</div>

<div class="list" *ngIf="isAdministrator && cardCredentialsExists">
  <div class="list-item">
    <span class="list-item-title">Username</span>
    <span class="list-item-description">{{ card.id }}</span>
  </div>

  <div class="list-item">
    <span class="list-item-title">Password</span>
    <div class="list-item-description">
      <div class="my-form-input">
        <input #password type="password" id="password" placeholder="Password">
      </div>
    </div>
  </div>

  <div class="list-item">
    <div class="list-item-btn-container">
      <button class="c-button" mat-raised-button color="warn"
        (click)="onPasswordSave()">{{ 'save' | translate }}</button>
    </div>
  </div>
</div>

<div style="height: 20px;" *ngIf="isAdministrator && cardCredentialsExists"></div>

<app-card-profile></app-card-profile>