<form class="formDataCollect" (ngSubmit)="verifyCollectDataForm()" [formGroup]="dataCollectForm" autocomplete="off">
    <!-- <div class="my-form-input">
      <label for="ssn">NIF / NIE <span class="red">*</span></label>
      <input id="ssn" type="text" placeholder="NIF / NIE" formControlName="ssn" required>
    </div> -->

    <div class="my-form-input">
        <label for="card_purpose">{{ 'Card Purpose' | translate }} <span class="red">*</span></label>
        <mat-select formControlName="card_purpose" required>
            <mat-option *ngFor="let s of cardPurpose" [value]="s">
                {{s | translate}}
            </mat-option>
        </mat-select>
    </div>

    <div class="my-form-input">
        <label for="employment_status">{{ 'Employment Status' | translate }} <span class="red">*</span></label>
        <mat-select formControlName="employment_status" required>
            <mat-option *ngFor="let s of employmentStatus" [value]="s">
                {{s | translate}}
            </mat-option>
        </mat-select>
    </div>

    <div class="my-form-input">
        <label for="occupation">{{ 'Occupation' | translate }} <span class="red">*</span></label>
        <mat-select formControlName="occupation" required>
            <mat-option *ngFor="let s of occupation" [value]="s">
                {{s | translate}}
            </mat-option>
        </mat-select>
    </div>

    <div class="my-form-input">
        <label for="monthly_income">{{ 'Monthly Income' | translate }} <span class="red">*</span></label>
        <mat-select formControlName="monthly_income" required>
            <mat-option *ngFor="let s of monthlyIncome" [value]="s">
                {{s | translate}}
            </mat-option>
        </mat-select>
    </div>

    <div class="my-form-input">
        <label for="estate">{{ 'Estate' | translate }} <span class="red">*</span></label>
        <mat-select formControlName="estate" required>
            <mat-option *ngFor="let s of estate" [value]="s">
                {{s | translate}}
            </mat-option>
        </mat-select>
    </div>

    <div class="my-form-input" style="margin-bottom: 5px;">
        <mat-checkbox formControlName="termsAndConditions" required>
            <a target="_blank" href="{{ linkTermsAndCondicions() }}">{{ 'terms_and_conditions' | translate }}</a>
            <span class="red">*</span>
        </mat-checkbox>
    </div>

    <div class="my-form-input" style="margin-bottom: 5px;">
        <mat-checkbox formControlName="privacy" required>
            <a target="_blank" href="{{ linkPriv() }}">{{ 'privacy_policy' | translate }}</a>
            <span class="red">*</span>
        </mat-checkbox>
    </div>

    <div class="my-form-input" style="margin-bottom: 5px;">
        <mat-checkbox formControlName="newsletter">
            {{ 'newsletter' | translate }}
            <span class="red">*</span>
        </mat-checkbox>
    </div>

    <div class="btn-container" *ngIf="showButton">
        <button class="app-button" mat-raised-button color="warn">{{ 'create_account' | translate }}</button>
    </div>
</form>