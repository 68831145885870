// --------------------------------------------------------------------------
// usamos esta opcao porque assim enquanto a pagina nao fizer reload
// os dados manteem no entanto ao fazer reload o scope desaparece assim como
// os dados
// 
// EX: quando passamos dados de cartoes e nao queremos que eles durem, apenas
// se matenham por tempo finito
// --------------------------------------------------------------------------

export class VolMem {
    private static _instance: VolMem;

    mem: any = null;

    public static get Instance() {
        // Do you need arguments? Make it a regular static method instead.
        return this._instance || (this._instance = new this());
    }
}