import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AlertService } from "src/app/services/alert.service";

import * as moment from "moment";
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { User } from "src/app/models/user";
import { copy_without_diacritics } from "src/app/helpers/string-utils";
import { Profile } from "src/app/models/profile";
import { nationalityList } from "src/app/models/countries";
import { UserService } from "src/app/services/user.service";
import { ReCaptchaV3Service } from "ngx-captcha";
import { environment } from "src/environments/environment";
import { finalize } from "rxjs/operators";
import { isEmpty as _isEmpty } from "lodash";

const MOMENT_DATE_FORMAT = "DD-MM-YYYY";
const MY_FORMATS2 = {
  parse: {
    dateInput: "DD-MM-YYYY",
  },
  display: {
    dateInput: "DD-MM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-form-user-step1",
  templateUrl: "./form-user-step1.component.html",
  styleUrls: ["./form-user-step1.component.css"],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS2 },
  ],
})
export class FormUserStep1Component implements OnInit {
  @Output() onUserDefined = new EventEmitter<User>();
  @Output() onNextPress = new EventEmitter<Profile>();
  @Input() typeKyc = "0";
  @Input() showCredentials = true;
  @Input() showButton = true;

  @Input() alreadyRegistered = false; // email ja existe na plataforma
  _loadedEmail = ""; // email registado no cartao, exista ou nao na plataforma

  namePattern = "[a-zA-Z ]{1,20}";
  name1Pattern = "[a-zA-Z ]{1,10}";
  emailPattern = "[a-zA-Z0-9-@_.]{1,40}";
  datePattern = "[0-9]{4}-(0[1-9]|1[012])-(0[1-9]|1[0-9]|2[0-9]|3[01])";
  phonePattern = "[0-9]{7,15}";

  @Input() set loadedEmail(loadedEmail: string) {
    this._loadedEmail = loadedEmail;
    if (this.userForm) {
      this.userForm.controls.email.setValue(this.loadedEmail);
    }
  }

  get loadedEmail(): string {
    return this._loadedEmail;
  }

  userForm: FormGroup;

  countries = nationalityList;

  wrongPassword = 0; // 0 - nada, 1 - red, 2 - ok
  wrongEmail = 0;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private userService: UserService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  ngOnInit() {
    const requiredArrayProps =
      this.typeKyc === "0"
        ? ["", [Validators.required, Validators.pattern(this.namePattern)]]
        : [""];

    const requiredArrayPropsLastName =
      this.typeKyc === "0"
        ? ["", [Validators.required, Validators.pattern(this.name1Pattern)]]
        : [""];

    const requiredArrayPropsD =
      this.typeKyc === "0"
        ? ["", [Validators.required]] // , Validators.pattern(this.datePattern)
        : [""];

    const requiredArrayPropsN =
      this.typeKyc === "0"
        ? // ? ['Spain', Validators.required]
          [""]
        : [""];

    const requiredArrayPropsP =
      this.typeKyc === "0"
        ? // ? ['', [Validators.required, Validators.pattern(this.phonePattern)]]
          ["", [Validators.pattern(this.phonePattern)]]
        : [""];

    if (!this.showCredentials) {
      this.userForm = this.formBuilder.group({
        email: [this._loadedEmail],
        password: [""],
        confirmpassword: [""],
        firstname: requiredArrayProps,
        middlename: [""],
        lastname: requiredArrayPropsLastName,
        nationaliry: requiredArrayPropsN,
        dob: requiredArrayPropsD,
        phone: requiredArrayPropsP,
      });
    } else {
      this.userForm = this.formBuilder.group({
        phone: requiredArrayPropsP,
        firstname: requiredArrayProps,
        middlename: [""],
        lastname: requiredArrayPropsLastName,
        nationaliry: requiredArrayPropsN,
        dob: requiredArrayPropsD,
        email: [this._loadedEmail, [Validators.required, Validators.email]],
        password: ["", [Validators.required, Validators.minLength(8)]],
        confirmpassword: ["", [Validators.required, Validators.minLength(8)]],
      });
    }
  }

  displayEmail(): string {
    const domain = this.loadedEmail.split("@");
    return this.loadedEmail.substring(0, 3) + "****@" + (domain.length > 1 ? domain[1] : "");
  }

  emailAlreadyComplete(): boolean {
    return !_isEmpty(this._loadedEmail);
  }

  get f() {
    return this.userForm.controls;
  }

  get firstname() {
    return this.userForm.get("firstname");
  }

  get middlename() {
    return this.userForm.get("middlename");
  }

  get lastname() {
    return this.userForm.get("lastname");
  }

  get dob() {
    return this.userForm.get("dob");
  }

  get phone() {
    return this.userForm.get("phone");
  }

  get userFromFormGroup(): User {
    const email = (this.f.email.value || "").trim().toLowerCase();

    return new User({
      phone: this.f.phone.value,
      username: email,
      email: email,
      password: this.f.password.value,
    });
  }

  get profileFromFormGroup(): Profile {
    let profile;

    if (this.typeKyc == "0") {
      const firstname = copy_without_diacritics(this.f.firstname.value);
      const lastname = copy_without_diacritics(this.f.lastname.value);
      const middlename = copy_without_diacritics(this.f.middlename.value);
      const dob = this.f.dob.value.format(MOMENT_DATE_FORMAT);
      const nationality = this.f.nationaliry.value;

      profile = new Profile({
        firstname: firstname,
        lastname: lastname,
        middlename: middlename,
        dob: dob,
        nationality: nationality,
        // nationality: 'Portuguesa',
        type: 0,
        email: (this.f.email.value || "").trim().toLowerCase(),
        phone: this.f.phone.value,
      });
    } else {
      profile = new Profile();
    }

    return profile;
  }

  onPasswordChange() {
    if (this.f.password.value == "") {
      this.wrongPassword = 0;
      return;
    }

    const verifySpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (!verifySpecialChar.test(this.f.password.value)) {
      this.wrongPassword = 1;
      return;
    }

    const format = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    if (!format.test(this.f.password.value)) {
      this.wrongPassword = 1;
      return;
    }

    this.wrongPassword = 2;
  }

  verifyUserSubmit() {
    if (this.showCredentials) {
      const verifySpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

      if (!verifySpecialChar.test(this.f.password.value)) {
        this.wrongPassword = 1;
        this.alertService.error("password_chards");
        return;
      }

      const format = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

      if (!format.test(this.f.password.value)) {
        this.wrongPassword = 1;
        this.alertService.error("password_chards");
        return;
      }

      if (this.f.password.value != this.f.confirmpassword.value) {
        this.alertService.error("wrong_password_and_confirm_password");
        this.f.confirmpassword.setErrors({ incorrect: true });
        return;
      }

      if (this.userForm.invalid) {
        if (!this.dob.valid) {
          this.alertService.error("invalid_dob");
        } else {
          this.alertService.error("form_error");
        }
        return;
      }

      this.wrongPassword = 2;
      this.onUserDefined.emit(this.userFromFormGroup);
    }

    if (this.typeKyc === "0") {
      // tem mais validacoes para fazer
      try {
        const years = parseInt(
          moment(this.f.dob.value, MOMENT_DATE_FORMAT)
            .month(0)
            .from(moment().month(0))
            .split(" ")[0],
          10
        );

        if (isNaN(years) || years < 18 || years > 100) {
          this.alertService.error("invalid_dob");
          return;
        }
      } catch (er) {
        this.alertService.error("invalid_dob");
        return;
      }
    }

    // verify if email exists
    this.reCaptchaV3Service.execute(
      environment.recaptcha,
      "login",
      (token) => {
        const user: User = this.userFromFormGroup;
        const sub = this.userService
          .verifyEmail(token, user.username)
          .pipe(finalize(() => sub.unsubscribe()))
          .subscribe(
            (data) => this.onNextPress.emit(this.profileFromFormGroup),
            (error) => {
              this.wrongEmail = 1;
              this.alertService.error("email_already_registered");
            }
          );
      },
      {
        useGlobalDomain: false,
      }
    );
  }
}
