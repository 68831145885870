import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertService } from 'src/app/services/alert.service';
import { Address } from 'src/app/models/address';
import { copy_without_diacritics } from 'src/app/helpers/string-utils';

@Component({
    selector: 'app-form-user-step2',
    templateUrl: './form-user-step2.component.html',
    styleUrls: ['./form-user-step2.component.css'],
})
export class FormUserStep2Component implements OnInit {
    @Output() onNextPress = new EventEmitter<Address>();
    @Input() showButton = true;
    addressForm: FormGroup;

    addressPattern = '[a-zA-Z0-9 ]{1,30}';
    statePattern = '[a-zA-Z]{1,8}';

    countries = ['Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Cyprus', 'Czech Republic', 'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hungary', 'Iceland', 'Ireland', 'Italy', 'Latvia', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands', 'Norway', 'Poland', 'Portugal', 'Romania', 'Slovakia', 'Slovenia', 'Spain', 'Sweden', 'United Kingdom'];

    constructor(
        private formBuilder: FormBuilder,
        private alertService: AlertService,
    ) { }

    ngOnInit() {
        const language = localStorage.getItem('language');
        const defaultCountry = (language == 'pt') ? 'Portugal' : 'Spain';

        this.addressForm = this.formBuilder.group({
            address: ['', Validators.required], // , Validators.pattern(this.addressPattern)
            zipcode: ['', Validators.required],
            country: [defaultCountry, Validators.required],
            city: ['', Validators.required],
            state: ['', [Validators.pattern(this.statePattern)]],
            // state: ['', [Validators.required, Validators.pattern(this.statePattern)]],
        });
    }

    get af() {
        return this.addressForm.controls;
    }

    get addressFromFormGroup(): Address {
        return new Address({
            address: copy_without_diacritics(this.af.address.value).replace(/[^a-zA-Z0-9 ]/g, "").trim(),
            city: copy_without_diacritics(this.af.city.value).replace(/’/g, "'").replace(/[^a-zA-Z0-9 ]/g, "").trim(),
            country: this.af.country.value,
            state: copy_without_diacritics(this.af.state.value).replace(/[^a-zA-Z]/g, "").trim(), // [^a-zA-Z ]
            zipcode: this.af.zipcode.value,
            main: 1
        });
    }

    verifyViewStep3DataForm() {
        if (this.addressForm.invalid) {
            this.alertService.error('form_error');
            return;
        }
        else {
            this.onNextPress.emit(this.addressFromFormGroup);
        }
    }
}