<app-form-user-step0 [showButton]="false" showName="true" *ngIf="steps == 0" (onValidCard)="onValidCard($event)">
</app-form-user-step0>

<app-form-user-step1 [showButton]="false" [showCredentials]="false" *ngIf="steps == 1" [typeKyc]="'0'"
    (onNextPress)="step1OnNextPress($event)">
</app-form-user-step1>

<app-form-user-step2 [showButton]="false" *ngIf="steps == 2" (onNextPress)="step2OnNextPress($event)">
</app-form-user-step2>

<app-form-user-step3 [showButton]="false" *ngIf="steps == 3" [typeKyc]="'0'" (onNextPress)="step3OnNextPress($event)">
</app-form-user-step3>