export class CardInfo {
    zip2: string;
    city: string;
    city2: string;
    sSN: string;
    countryOfIssuance: string;
    zip: string;
    securityField2: string;
    cardNumber: string;
    lastName: string;
    countyName: string;
    nationality: string;
    address4: string;
    firstName: string;
    secondaryAddress2: string;
    countyName2: string;
    phone2: string;
    countryName: string;
    address1: string;
    secondaryAddress1: string;
    countryName2: string;
    secondaryAddress4: string;
    documentNumber: string;
    userDefinedField1: string;
    documentExpiryDate: string;
    countryCode: string;
    documentType: string;
    address3: string;
    middleInitial: string;
    dOB: string;
    securityField1: string;
    secondaryAddress3: string;
    address2: string;
    userDefinedField3: string;
    embossName: string;
    userDefinedField4: string;
    cardHolderID: string;
    phone: string;
    state2: string;
    securityField3: string;
    countryCode2: string;
    userDefinedField2: string;
    emailAddr: string;
    uDFs: string;
    state: string;
    securityField4: string;

    constructor(obj?: any) {
        Object.assign(this, obj);
    }

    getFullName(): string {
        return this.firstName + ' ' + this.lastName;
    }
}