<form [formGroup]="rpForm" (ngSubmit)="onSubmit()" autocomplete="off">

    <div *ngIf="!isChangePasswordForm" class="my-form-input">
        <label for="email">{{ 'email / card_holder' | translate }} <span class="red">*</span></label>
        <input id="email" type="text" placeholder="{{ 'email / card_holder' | translate }}" formControlName="email"
            required>
    </div>

    <div *ngIf="isChangePasswordForm" class="my-form-input">
        <label for="password">{{ 'password' | translate }} <span class="red">*</span></label>
        <input id="password" type="password" placeholder="{{ 'password' | translate }}" formControlName="password"
            required>
        <div class="password-helper">
            {{ 'password_chards' | translate }}
        </div>
    </div>

    <div *ngIf="isChangePasswordForm" class="my-form-input">
        <label for="confirmpassword">{{ 'confirm_password' | translate }} *</label>
        <input id="confirmpassword" type="password" placeholder="{{ 'confirm_password' | translate }}"
            formControlName="confirmpassword" required>
    </div>

    <div class="btn-container">
        <button class="app-button" mat-raised-button color="warn">{{ 'recover' | translate }}</button>
    </div>
</form>