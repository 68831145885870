<div class="mcontainer cards-container">
    <div class="cards-container-content" *ngFor="let d of deposit">
        <app-simple-box-card title="{{ d.card.name }}" value="{{ d.value.toFixed(2) }}"></app-simple-box-card>
    </div>
</div>

<div class="mcontainer box-order-container" *ngIf="order"> 
    <div class="cards-container-left-column">
        <div class="cards-container-left-column-title"> {{ 'order_id' | translate }} </div>
        <div class="cards-container-left-column-descr">{{ orderID }}</div>
        <button class="export-button" (click)="exportPdf()"> {{ 'export_pdf' | translate }} </button>
    </div>

    <div class="cards-container-right-column-trans" *ngIf="paymenteMethod == 1">
        <div class="cards-container-right-column-trans-normal-text">
            Para realizar um carregamento através de transferência bancária em Portugal, efetue a transferência para a seguinte conta bancária:
        </div>

        <div>
            <b>SANTANDER TOTTA</b><br>
            <b>NIB: 0018 0003 4459 7235 0200 4</b><br>
            <b>IBAN: PT50 0018 0003 44 597235020 04</b>
        </div>

        <div class="cards-container-right-column-trans-normal-text">
            Para poder identificar corretamente o seu pagamento e para contactá-lo sempre que necessário, não se esqueça de colocar os seguintes dados:
        </div>
        
        <div class="cards-container-right-column-trans-normal-text">
            Titular da conta: Servicios de Prepago Integrales <br>
            Descritivo: <span class="cards-container-right-column-trans-normal-note">{{ orderID }}</span> fornecido na coluna ao lado
        </div>
            
        <div class="cards-container-right-column-trans-normal-text">
            O cartão será carregado quando a transferência se torna efetiva (24-72 horas). Receberá um SMS confirmando o saldo carregado.
        </div>
    </div>

    <div class="cards-container-right-column" *ngIf="paymenteMethod != 1">
        <div class="cards-container-right-column-row">
            <div class="cards-container-right-column-title" *ngIf="paymenteMethod == 0"> MbWay</div>
            <div class="cards-container-right-column-title" *ngIf="paymenteMethod == 2"> Multibanco</div>
            <div class="cards-container-right-column-title" *ngIf="paymenteMethod == 3"> Payshop</div>
        </div>
        <div class="cards-container-right-column-row">
            <div class="cards-container-right-column-label">{{ 'entity' | translate }}</div>
            <div class="cards-container-right-column-value">{{ order.entity }}</div>
        </div>
        <div class="cards-container-right-column-row">
            <div class="cards-container-right-column-label">{{ 'reference' | translate }}</div>
            <div class="cards-container-right-column-value">{{ order.ref }}</div>
        </div>
        <div class="cards-container-right-column-row">
            <div class="cards-container-right-column-label">{{ 'amount' | translate }}</div>
            <div class="cards-container-right-column-value">€ {{ order.amount }}</div>
        </div>
    </div>
</div>