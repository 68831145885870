<form [formGroup]="filterForm" (ngSubmit)="onFilterPress()" class="t-container" *ngIf="filterVisible">
  <div *ngIf="ordersStatus == 0" class="containerAlert">
    <div class="a-container a-warning">
      <div class="tag-img">
        <img src="assets//images//warning.png">
      </div>
      <div class="a-text">{{ 'sync_transactions_error' | translate }}</div>
    </div>
  </div>

  <div class="t-container-main">
    <a class="export_csv export_csv_normal" (click)="onExportPress()">{{ 'export' | translate }}</a>

    <div class="my-form-input">
      <label for="startDate">{{ 'start_date' | translate }} <span class="red">*</span></label>
      <div class="my-date-form">
        <input matInput [matDatepicker]="pickerStart" id="startDate" placeholder="{{ 'start_date' | translate }}"
          formControlName="startDate" required>
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
      </div>
    </div>

    <div class="my-form-input t-container-space">
      <label for="endDate">{{ 'end_date' | translate }} <span class="red">*</span></label>
      <div class="my-date-form">
        <input matInput [matDatepicker]="pickerEnd" id="endDate" placeholder="{{ 'end_date' | translate }}"
          formControlName="endDate" required>
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
      </div>
    </div>

    <button class="my-button" mat-raised-button color="warn">
      <img src="assets/images/search.png" alt="search">
    </button>
  </div>
</form>

<div class="btn-filter-mobile">
  <a class="export_csv export_csv_popup" (click)="onExportPress()">{{ 'export' | translate }}</a>
  <button class="my-button btn-filter" mat-raised-button color="warn" (click)="filterVisible = true">
    <img src="assets/images/filter.png" alt="filter">
  </button>
</div>

<div class="list-label">
  <div>
    <span>{{ 'updated_at' | translate }}:&nbsp;</span>
    <span class="list-label-date">{{ ordersLastUpdated | date : 'yyyy-MM-dd hh:mm:ss' }}</span>
  </div>
  <div>
    {{ 'order_list_label' | translate }}
  </div>
</div>
<div class="list">
  <app-order-line [disableExpand]="false" [order]="order" *ngFor="let order of orders"></app-order-line>
</div>

<!-- <div class="filter-container" *ngIf="exportPopup">
  <div class="filter-content">
    <button class="my-button my-button-export" mat-raised-button color="warn" (click)="exportAs('csv')">
      CSV
    </button>

    <br>

    <button class="my-button my-button-export" mat-raised-button color="warn" (click)="exportPdf()">
      PDF
    </button>
  </div>
</div> -->