<div class="container">
    <div class="c-row" (click)="expand = !expand">
        <div class="uploadFileContent" *ngIf="order.type == 1 && order.status == 0">
            <input id="uploadproof" type="file" accept="image/x-png,image/png,image/jpeg" style="display: none;"
                (change)="onUploadProof($event, order.id)">
            <div onclick="document.getElementById('uploadproof').click()">
                <img src="assets/images/upload.png" onclick="document.getElementById('uploadproof').click()">
            </div>
        </div>

        <div class="left-column">
            <div class="order-id">{{ paymentMessage || '----' }} {{ paymentType }}</div>
            <div class="date-updated">{{ order_id }} - {{ order.date }}</div>
            <!-- | date: 'dd/MM/yyyy hh:mm:ss' -->
        </div>

        <div class="right-column">
            <div class="amount">
                <span class="{{ (order.amount < 0) ? 'amount-neg' : '' }}"> €
                    {{ (order.amount) ? (order.amount - order.fee).toFixed(2) : 0.00 }} </span>
                /
                € {{ (order.status == 1) ? balance : '----' }}
            </div>
            <div class="date-updated">{{ 'fee' | translate }} € {{ fee }}</div>
        </div>

        <div class="marker marker-{{ order.status }}"></div>
    </div>

    <div class="card-container" *ngIf="expand && !expandDisabled">
        <app-simple-box-card title="{{ card.card_name }}" value="{{ (card.amount - card.fee).toFixed(2) }}"
            *ngFor="let card of order.cards"></app-simple-box-card>
    </div>

    <div class="export-btn-container" *ngIf="expand && !expandDisabled">
        <button class="app-button" mat-raised-button color="warn" *ngIf="isAvailableMyMoidPayment()"
            (click)="onPaymentPress()">{{ 'pay' | translate }}</button>
        <div style="width: 25px;"></div>
        <button class="app-button" mat-raised-button color="warn"
            (click)="exportPdfV1()">{{ 'export_pdf' | translate }}</button>
    </div>
</div>