<div class="m-container">
  <div *ngIf="isAlertVisible" class="m-container--alert-message">
    {{ errorReason | translate }}
  </div>

  <div class="m-container--content">
    <div class="m-container--content__title">{{ "qrcode_form_title" | translate }}</div>

    <div class="m-container--content__desc">
      {{ "qrcode_form_desc1" | translate }}

      <a
        (click)="hereClick()"
        style="font-weight: bold; text-decoration: underline; cursor: pointer"
        >{{ "here" | translate }}</a
      >
    </div>

    <div class="m-container--content__desc">{{ "qrcode_form_desc2" | translate }}</div>

    <div class="m-container--content__desc">{{ "qrcode_form_desc3" | translate }}</div>

    <div class="m-container--content__desc">{{ "qrcode_form_desc4" | translate }}</div>
  </div>

  <div class="m-container--content">
    <div class="m-container--content__title">{{ "qr_minimum_requirements_title" | translate }}</div>

    <div class="m-container--content__desc">{{ "qr_minimum_requirements_desc1" | translate }}</div>

    <div class="m-container--content__desc">{{ "qr_minimum_requirements_desc2" | translate }}</div>

    <div class="m-container--content__desc">{{ "qr_minimum_requirements_desc3" | translate }}</div>

    <div class="m-container--content__desc">{{ "qr_minimum_requirements_desc4" | translate }}</div>
  </div>

  <button
    [disabled]="isStartDisabled"
    class="c-button"
    mat-raised-button
    color="warn"
    (click)="handleOnPopupKycClick()"
  >
    {{ "start_validation_process" | translate }}
  </button>
</div>
