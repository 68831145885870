import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AlertService } from "src/app/services/alert.service";

import {
  EMPLOYMENTSTATUS,
  OCCUPATION,
  CARDPURPOSE,
  MONTHLYINCOME,
  ESTATE,
} from "src/app/utils/extra-fields";

@Component({
  selector: "app-form-user-step3",
  templateUrl: "./form-user-step3.component.html",
  styleUrls: ["./form-user-step3.component.css"],
})
export class FormUserStep3Component implements OnInit {
  @Output() onNextPress = new EventEmitter<FormGroup>();

  @Input() showButton = true;
  @Input() typeKyc = "0";
  dataCollectForm: FormGroup;

  employmentStatus = EMPLOYMENTSTATUS;
  occupation = OCCUPATION;
  cardPurpose = CARDPURPOSE;
  monthlyIncome = MONTHLYINCOME;
  estate = ESTATE;

  constructor(private formBuilder: FormBuilder, private alertService: AlertService) {}

  ngOnInit() {
    this.dataCollectForm = this.formBuilder.group({
      ssn: [""], // , Validators.required
      employment_status: ["", Validators.required],
      occupation: ["", Validators.required],
      card_purpose: ["", Validators.required],
      monthly_income: ["", Validators.required],
      estate: ["", Validators.required],
      termsAndConditions: [false, Validators.required],
      privacy: [false, Validators.required],
      newsletter: [false],
    });
  }

  verifyCollectDataForm() {
    // this.typeKyc === '0' &&
    if (!this.dataCollectForm.valid) {
      this.alertService.error("form_error");
      return;
    }

    this.onNextPress.emit(this.dataCollectForm);
  }

  linkTermsAndCondicions(): string {
    switch (localStorage.getItem("language")) {
      case "es":
        return "https://www.tarjetaspark.es/wp-content/uploads/2018/12/181217_booklet_esp_completo.pdf";
      case "en":
        return "https://www.tarjetaspark.es/wp-content/uploads/2019/01/181218_booklet_eng_full.pdf";
      default:
        return "https://www.tarjetaspark.es/wp-content/uploads/2019/01/181218_booklet_eng_full.pdf";
    }
  }

  linkPriv(): string {
    switch (localStorage.getItem("language")) {
      case "es":
        return "https://www.tarjetaspark.es/privacidad";
      case "en":
        return "https://www.tarjetaspark.es/en/privacy";
      default:
        return "https://www.tarjetaspark.es/en/privacy";
    }
  }
}
