import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';

import { ProfileService } from '../../services/profile.service';
import { UserService } from '../../services/user.service';
import { AlertService } from '../../services/alert.service';

import { Profile } from 'src/app/models/profile';
import { Address } from 'src/app/models/address';
import { first, finalize } from 'rxjs/operators';
import { Translate } from 'src/app/locales/translate';

// -----------
// 
// @Deprecated
// 
// -----------

@Component({
    selector: 'app-user-info',
    templateUrl: './information.component.html',
    styleUrls: ['./information.component.css']
})
export class InformationComponent implements OnInit, OnDestroy {
    @ViewChild('front_id') front_id: ElementRef;
    @ViewChild('back_id') back_id: ElementRef;
    @ViewChild('comercial_id') comercial_id: ElementRef;
    @ViewChild('inputEmail') inputEmail: ElementRef;

    private subscription: Subscription;

    profile = new Profile();
    address = new Address();
    editingEmail = false;
    files = { front_id: '', back_id: '', comercial_id: '', };
    filesResults = { front_id: null, back_id: null, comercial_id: null, };
    fileControlType0 = null;
    fileControlType1 = null;
    fileControlType2 = null;

    list_users = [];

    showAddUsers = false;

    selectedFlag = localStorage.getItem('language') || 'pt';

    kycUserProfile = 0;

    constructor(
        private titleService: Title,
        private userService: UserService,
        private profileService: ProfileService,
        private alertService: AlertService
    ) { }

    ngOnInit() {
        const translate = new Translate();
        this.titleService.setTitle(translate.transform('profile'));

        this.showAddUsers = false;

        this.loadUser();

        this.subscription = this.userService.onUserChange().subscribe(modified => this.loadUser());

        this.downloadAssociatedUsers();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    loadUser() {
        this.profile = this.userService.authenticated_user.profiles[0];
        this.address = this.profile.getMainAddress();
        this.kycUserProfile = this.profile.kyc;
        this.fileControlType0 = this.profile.getFileByType(0);
        this.fileControlType1 = this.profile.getFileByType(1);
        this.fileControlType2 = this.profile.getFileByType(2);
    }

    downloadAssociatedUsers() {
        this.userService.list_users_associated()
            .pipe(first())
            .subscribe(
                data => { this.list_users = data; },
                error => this.alertService.specialError() // this.alertService.error('api_internal_error')
            );
    }

    onEmailInputKeydown(event) {
        if (event.key === 'Enter') {
            this.onEmailOperationBtnPress();
        }
    }

    onEmailOperationBtnPress() {
        if (this.editingEmail) {
            // save email
            const email = this.inputEmail.nativeElement.value;

            if (email !== '' && this.profile.email !== email) {
                this.alertService.loading(true);

                this.profileService.update_email(email, null, '')
                    .pipe(first())
                    .pipe(finalize(() => {
                        this.alertService.loading(false);
                        this.editingEmail = false;
                    }))
                    .subscribe(
                        data => this.profile.email = email,
                        error => this.alertService.error(error.error),
                    );
            } else {
                this.editingEmail = false;
            }
        } else {
            this.editingEmail = true;
            setTimeout(() => this.inputEmail.nativeElement.focus(), 500);
        }
    }

    statusText(type: number) {
        switch (type) {
            case 1:
                return 'approved';
            case 2:
                return 'error';
            default:
                return 'waiting';
        }
    }

    displayKycLabel(): string {
        switch (this.kycUserProfile) {
            case 1:
                return 'Plus';
            case 2:
                return 'Premium';
            default:
                return '-';
        }
    }

    // KYC

    onUploadPress(type: number) {
        if (this.profile.kyc == 2) {
            return;
        }

        switch (type) {
            case 1:
                if (this.fileControlType1 && this.fileControlType1.status == 0) {
                    // em espera nao faz nada
                    return;
                }
                this.back_id.nativeElement.click();
                break;
            case 2:
                if (this.fileControlType2 && this.fileControlType2.status == 0) {
                    // em espera nao faz nada
                    return;
                }
                this.comercial_id.nativeElement.click();
                break;
            default:
                if (this.fileControlType0 && this.fileControlType0.status == 0) {
                    // em espera nao faz nada
                    return;
                }
                this.front_id.nativeElement.click();
                break;
        }
    }

    onUploadFile(event, type) {
        if (event.target.files.length <= 0) {
            return;
        }

        switch (type) {
            case 1:
                this.files.back_id = event.target.files[0];
                var reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (event: any) => {
                    this.filesResults.back_id = event.target.result;
                }
                break;
            case 2:
                this.files.comercial_id = event.target.files[0];
                var reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (event: any) => {
                    this.filesResults.comercial_id = event.target.result;
                }
                break;
            default:
                this.files.front_id = event.target.files[0];
                var reader = new FileReader();
                reader.readAsDataURL(event.target.files[0]);
                reader.onload = (event: any) => {
                    this.filesResults.front_id = event.target.result;
                }
                break;
        }
    }

    isValidToSubmit() {
        if (
            (
                this.files.front_id !== '' &&
                this.fileControlType0 == null ||
                (this.fileControlType0 != null && this.fileControlType0.status !== 0)
            ) ||
            (
                this.files.back_id !== '' &&
                this.fileControlType1 == null ||
                (this.fileControlType1 != null && this.fileControlType1.status !== 0)
            ) ||
            (
                this.files.comercial_id !== '' &&
                this.fileControlType2 == null ||
                (this.fileControlType2 != null && this.fileControlType2.status !== 0)
            )
        ) {
            return true;
        }
        return false;
    }

    onSubmitKyc() {
        if (!this.isValidToSubmit()) {
            return;
        }

        let files = [];

        // create response
        if (this.files.front_id !== '') {
            files.push({
                type: 0,
                file: this.files.front_id,
            });
        }

        if (this.files.back_id !== '') {
            files.push({
                type: 1,
                file: this.files.back_id,
            });
        }

        if (this.files.comercial_id !== '') {
            files.push({
                type: 2,
                file: this.files.comercial_id,
            });
        }

        if (files.length == 0) {
            this.alertService.error('new_to_upload_1_file');
        } else {
            const formData = new FormData();

            for (let it = 0; it < files.length; it++) {
                formData.append('files[' + it + '][type]', files[it].type);
                formData.append('files[' + it + '][file]', files[it].file);
            }

            this.alertService.loading(true);
            this.profileService.upload_files(formData, this.profile.id)
                .pipe(first())
                .pipe(finalize(() => this.alertService.loading(false)))
                .subscribe(
                    data => {
                        this.alertService.success('upload_success');
                        this.userService.refreshUser();
                        // this.loadUser();
                    },
                    error => this.alertService.error('upload_error')
                );
        }
    }

    onUserCreated() {
        this.showAddUsers = false;
        this.downloadAssociatedUsers();
    }

    selectLanguage(lang) {
        if (this.selectedFlag != lang) {
            localStorage.setItem('language', lang);
            location.reload();
        }
    }
}
