import { Component, OnInit, Input } from '@angular/core';

import { OrderService } from '../../services/order.service';
import { AlertService } from '../../services/alert.service';

import { Order } from 'src/app/models/order';

import { generate_order_pdf } from '../../pdf/genereta_pdf';
import { Translate } from 'src/app/locales/translate';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-order-line',
  templateUrl: './order-line.component.html',
  styleUrls: ['./order-line.component.css'],
  providers: [Translate],
})
export class OrderLineComponent implements OnInit {
  @Input() order: Order = new Order();
  @Input() expand = false;
  @Input() disableExpand = false;

  constructor(
    private orderService: OrderService,
    private alertService: AlertService,
    private translate: Translate,
    private userService: UserService,
  ) { }

  ngOnInit() { }

  get order_id() {
    let order_id = (this.order) ? '' + this.order.purchase_id : '';
    return order_id.slice(0, 3) + ' ' + order_id.slice(3, 6) + ' ' + order_id.slice(6, 9);
  }

  get fee() {
    return this.order.cards.map((v) => v.fee.toFixed(2)).join(', ');
  }

  get balance() {
    return (this.order.cards.length === 1) ? this.order.cards[0].balance.toFixed(2) : '';
  }

  get expandDisabled() {
    return this.disableExpand || this.order.amount <= 0;
  }

  get paymentMessage() {
    const TOKEN = 'Deposit to Card API'.toLocaleLowerCase();
    return (this.order.message.toLocaleLowerCase().indexOf(TOKEN) > -1) ? this.translate.transform('load_card_order') : this.order.message;
  }

  get paymentType() {
    const type = this.order.type;

    switch (type) {
      case 0:
        return '(MB Way)';
      case 1:
        return '(Transf.)';
      case 2:
        return '(Multibanco)';
      case 3:
        return '(Payshop)';
      case 4:
        return '(MyMoid)';
    }

    return '';
  }

  isAvailableMyMoidPayment() {
    return (this.order.type === 4) && (this.order.status === 0);
  }

  // ------
  // events
  // ------

  exportPdfV1() {
    if (!(this.order)) {
      return;
    }

    this.alertService.loading(true);
    generate_order_pdf(this.order, this.userService.authenticated_user);
    this.alertService.loading(false);
  }

  onPaymentPress() {
    const okUrl = window.location.origin + '/app/transferpayment?c=' + this.order.purchase_id;
    const koUrl = window.location.origin + '/app/transferpayment?e=true';
    window.location.href = environment.mymoid + '/tpv/?p=' + (this.order.phone || '') + '&urlok=' + okUrl + '&urlko=' + koUrl; // phone = shortcode
  }

  onUploadProof(event: any, id: any) {
    this.alertService.loading(true);
    if (event.target.files.length <= 0) {
      this.alertService.loading(false);
      return;
    }

    const proof = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (event: any) => {
      const proofResult = event.target.result;
    };

    const formData = new FormData();
    formData.append('proof', proof);

    this.orderService.upload_proof(id, formData)
      .pipe(finalize(() => this.alertService.loading(false)))
      .subscribe(
        data => this.alertService.success('upload_success'),
        error => this.alertService.error('upload_error')
      );
  }
}