import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidationService } from 'src/app/services/validation.service';
import { finalize } from 'rxjs/operators';
import { isEmpty as _isEmpty } from 'lodash';

@Component({
    selector: 'app-popup-code',
    templateUrl: './popup-code.component.html',
    styleUrls: ['./popup-code.component.css']
})
export class PopupCodeComponent implements OnInit {
    errorVisible = false;
    code = '';

    constructor(
        private validationService: ValidationService,
        private dialogRef: MatDialogRef<PopupCodeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        const sub = this.validationService.generateValidationCode(localStorage.getItem('language') || 'en')
            .pipe(finalize(() => sub.unsubscribe()))
            .subscribe(data => console.log(data));
    }

    // events

    okClick(): void {
        if (!_isEmpty(this.code)) {
            this.dialogRef.close(this.code);
        }
    }
}
