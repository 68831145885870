import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { TransferDataService } from 'src/app/services/transferData.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.css']
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  routeSubscription: Subscription;
  updateLinks: Subscription;

  title = '';
  bottomLinkText = '';
  bottomLink = '';
  selectedFlag = localStorage.getItem('language') || 'pt';
  showActivateAccount = false;

  constructor(
    private router: Router,
    private transfereDataService: TransferDataService,
  ) { }

  ngOnInit() {
    this.updateRouteInformation();

    this.routeSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.updateRouteInformation();
      }
    });

    this.updateLinks = this.transfereDataService.onDataUpdate().subscribe(
      modified => this.updateLinksByData(this.transfereDataService.data)
    );
  }

  isAboutCardRegister(): boolean {
    return this.router.url == '/aboutcardregister';
  }

  ngOnDestroy() {
    if (this.updateLinks) {
      this.updateLinks.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  updateLinksByData(data) {
    if (data.op === 'update_links') {
      this.title = data.title;
      this.bottomLinkText = data.bottomLinkText;
      this.bottomLink = data.bottomLink;
    }
  }

  updateRouteInformation() {
    const currentUrl = this.router.url;
    this.showActivateAccount = false;

    if (currentUrl.indexOf('/buycard') > -1) {
      this.title = 'buy card';
      this.bottomLinkText = 'back_to_login';
      this.bottomLink = '/login';
    } else if (currentUrl.indexOf('/createaccount') > -1 || currentUrl.indexOf('/requestpassword') > -1) {
      this.title = 'new_account_step_1';
      this.bottomLinkText = 'back_to_login';
      this.bottomLink = '/login';
    } else if (currentUrl.indexOf('/verifyemail') > -1) {
      this.title = 'verify_email';
      this.bottomLinkText = '';
      this.bottomLink = '';
    } else if (currentUrl.indexOf('/recoverypassword') > -1) {
      this.title = 'recovery_password';
      this.bottomLinkText = 'back_to_login';
      this.bottomLink = '/login';
    } else if (currentUrl.indexOf('/aboutcardregister') > -1) {
      this.title = 'card_activation_title';
      this.bottomLinkText = 'back_to_step_1';
      this.bottomLink = '/requestpassword';
    } else if (currentUrl.indexOf('/activatecard') > -1) {
      this.title = 'activate card';
      this.bottomLinkText = 'back_to_login';
      this.bottomLink = '/login';
    } else {
      this.title = 'login';
      this.bottomLinkText = 'create_account';
      this.bottomLink = '/requestpassword';
      this.showActivateAccount = currentUrl.indexOf('/login') > -1;
    }
  }

  selectLanguage(lang) {
    if (this.selectedFlag != lang) {
      localStorage.setItem('language', lang);
      location.reload();
    }
  }

  // para o sistema poder executar operacoes quando
  // o back é chamada, caso do registar
  onBackPress() {
    this.transfereDataService.data = {
      op: 'back_intro_press',
    };
  }

}