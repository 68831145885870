<div class="nav-bar-user">
  <div class="homeAndProfileContainer">
    <!-- <a class="homeContainer" routerLink="/app/cards/transactions">
      <img src="assets/images/house.png" />
    </a> -->
    <!-- (click)="toggleMenu()" -->
    <div class="nav-bar-user-row" routerLink="/app/cards/carddetails">
      <img src="assets/images/user_icon.png" alt="user icon">
      <div>
        <span class="nav-bar-user-name">{{ profile.firstname + ' ' + profile.lastname }}</span>
        <br>
        <span class="nav-bar-user-lastlogin">
          {{ 'last login' | translate }}:
          {{ user.lastlogin | date: 'dd/MM/yyyy HH:mm:ss' }}
        </span>
      </div>
      <!-- <img src="assets/images/chevron.png" style="width: 10px; height: 10px;"> -->
    </div>
  </div>

  <div class="profile-menu" *ngIf="showMenu">
    <div class="profile-menu-row" (click)="showMenu = false;">
      <div class="profile-menu-row-left">
        <img src="assets/images/user_icon.png" alt="user icon">
        <span class="nav-bar-user-name">{{ profile.firstname + ' ' + profile.lastname }}</span>
      </div>
    </div>
    <div class="profile-menu-row" (click)="goToCardMenu()">
      <span class="profile-menu-logout">{{ 'my_cards' | translate }}</span>
    </div>
    <!-- <div class="profile-menu-row" (click)="goToOrdersList()">
      <span class="profile-menu-logout">{{ 'history' | translate }}</span>
    </div> -->
    <div class="profile-menu-row" (click)="onAddCardPress()" *ngIf="user.isAdministrator()">
      <span class="profile-menu-logout">{{ 'add_new_card' | translate }}</span>
    </div>
    <!-- <div class="profile-menu-row" (click)="goToUserInfo()" *ngIf="user.isAdministrator()">
      <span class="profile-menu-logout">{{ 'profiles' | translate }}</span>
    </div> -->
    <div class="profile-menu-row">
      <a class="profile-menu-logout" (click)="logout()">{{ 'logout' | translate }}</a>
    </div>
    <div style="height: 20px;"></div>
  </div>

  <div class="nav-bar-user-btn-container" *ngIf="canUseLoadCard">
    <button class="app-button" mat-raised-button color="warn"
      (click)="goToMakeOrder()">{{ 'load_card' | translate }}</button>
  </div>
</div>

<div class="bottom-line"></div>

<router-outlet></router-outlet>

<div class="support-container">
  <div class="support-container-subcontainer">
    <div>
      Portugal +351 707 500 101
    </div>
    <div>
      España +34 91 29 39 789
    </div>
    <div>
      <a href="mailto:info@cartaospark.pt" target="_top">info@cartaospark.pt</a>
    </div>
    <div>
      <a href="mailto:info@tarjetaspark.es" target="_top">info@tarjetaspark.es</a>
    </div>
  </div>
  <div class="flag-container">
    <app-flag flag="es" [selected]="selectedFlag == 'es'" (onPress)="selectLanguage('es')"></app-flag>
    <div style="margin-right:15px;"></div>
    <app-flag flag="en" [selected]="selectedFlag == 'en'" (onPress)="selectLanguage('en')"></app-flag>
    <div style="margin-right:15px;"></div>
    <app-flag flag="pt" [selected]="selectedFlag == 'pt'" (onPress)="selectLanguage('pt')"></app-flag>
  </div>
</div>