import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogAppService {
  basePath: string;

  constructor(private http: HttpClient) {
    this.basePath = environment.baseUrl;
  }

  save(user_id: any, action: string, details: string) {
      const relPath = (user_id === null) ? 'logswu' : 'logs';
      
      return this.http.post(`${this.basePath}/${relPath}`, { log_app: { user_id: user_id, action: action, details: details } });
  }

  saveError(user_id: any, action: string, error: any) {
    const relPath = (user_id === null) ? 'logswu' : 'logs';
    const details = (typeof error === 'object') ? JSON.stringify(error) : error;
    
    return this.http.post(`${this.basePath}/${relPath}`, { log_app: { user_id: user_id, action: action, details: details } });
}
}
