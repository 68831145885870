import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isEmpty as _isEmpty } from 'lodash';
import { PopupPhoneCodeComponent } from 'src/app/modals/popup-phone-code/popup-phone-code.component';
import { CardService } from 'src/app/services/card.service';
import { finalize } from 'rxjs/operators';
import { AlertService } from 'src/app/services/alert.service';
import { PopupActivationCardComponent } from 'src/app/modals/popup-activation-card/popup-activation-card.component';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { environment } from 'src/environments/environment';
import { VolMem } from 'src/app/models/vol_mem';

@Component({
    selector: 'app-activate-card',
    templateUrl: './activate-card.component.html',
    styleUrls: ['../intro.component.css', './activate-card.component.css']
})
export class ActivateCardComponent implements OnInit {
    @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplete: MatAutocompleteTrigger;

    phonePattern = '[0-9]{7,15}';
    emailPattern = '[a-zA-Z0-9@._]{1,40}';
    form: FormGroup;
    isCountryValid = true;

    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    preferredCountries: CountryISO[] = [CountryISO.Portugal, CountryISO.Spain, CountryISO.UnitedKingdom];

    constructor(
        private formBuilder: FormBuilder,
        public dialog: MatDialog,
        private cardService: CardService,
        private alertService: AlertService,
        private router: Router,
        private reCaptchaV3Service: ReCaptchaV3Service,
    ) { }

    ngOnInit() {
        let cardholderid = '';
        let cardnumber = '';

        if (VolMem.Instance.mem) {
            cardholderid = VolMem.Instance.mem.cardholderid;
            cardnumber = VolMem.Instance.mem.cardnumber;

            VolMem.Instance.mem = null;
        }

        this.form = this.formBuilder.group({
            cardholderid: [cardholderid, [Validators.required, Validators.minLength(12)]],
            cardnumber: [cardnumber, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]],
            phoneNumber: [, [Validators.required]], // , Validators.pattern(this.phonePattern)
            email: ['', [Validators.required, Validators.email]],
        });
    }

    get cardholderid() {
        return this.form.get('cardholderid');
    }

    get phoneNumber() {
        return this.form.get('phoneNumber');
    }

    get cardNumber() {
        return this.form.get('cardnumber');
    }

    get cardEmail() {
        return this.form.get('email');
    }

    get finalPhoneNumber() {
        return (('e164Number' in this.phoneNumber.value) ? this.phoneNumber.value.e164Number || '' : '').replace('+', '00');
    }

    // ------
    // events
    // ------

    openPanel(ev: any): void {
        ev.stopPropagation();
        this.inputAutoComplete.openPanel();
    }

    cardAlreadyActive(data: any, reCaptchaToken: string): void {
        if ('kyc' in data && 'email' in data) {
            this.alertService.loading(false);
            this.alertService.warning('this card already active');

            const kyc = '' + data.kyc;
            const email = data.email;
            const alreadyRegistered = ('alreadyRegistered' in data) ? data.alreadyRegistered : false;

            // vai permitir poder passar o passo da verificacao de cartao no user-form
            const obj = { alreadyRegistered: alreadyRegistered, email: email, typeKyc: ('' + kyc), name: '', cardHolderId: this.cardholderid.value, cardNumber: this.cardNumber.value, phoneNumber: this.finalPhoneNumber };
            localStorage.setItem('tempObj', JSON.stringify(obj));

            this.router.navigate(['/createaccount'], { queryParams: { cv: 'true' } });
        }
    }

    activateCard(reCaptchaToken: string, alreadyRegistered: boolean): void {
        // countryCode: "PT"
        // dialCode: "+351"
        // e164Number: "+351911911911"
        // internationalNumber: "+351 911 911 911"
        // nationalNumber: "911 911 911"
        // number: "911 911 911"

        this.alertService.loading(false);

        const cCode = ('countryCode' in this.phoneNumber.value) ? this.phoneNumber.value.countryCode || '' : '';
        const number = ('number' in this.phoneNumber.value) ? this.phoneNumber.value.number || '' : '';

        const sub = this.cardService.requestPhoneConfirmationCode(number, cCode, reCaptchaToken)
            .pipe(finalize(() => {
                this.alertService.loading(false);
                sub.unsubscribe();
            }))
            .subscribe(
                resp => this.showPopupPhoneConfirmationCode(cCode, alreadyRegistered),
                err => this.alertService.error('cant request phone verification code')
            );

        // -----------
        // Para testes
        // -----------

        // const number = (('e164Number' in this.phoneNumber.value) ? this.phoneNumber.value.e164Number || '' : '').replace('+', '00');
        // const obj = { alreadyRegistered: alreadyRegistered, email: this.cardEmail.value, typeKyc: ('' + 0), name: '', cardHolderId: this.cardholderid.value, cardNumber: this.cardNumber.value, phoneNumber: number };
        // localStorage.setItem('tempObj', JSON.stringify(obj));

        // this.router.navigate(['/createaccount'], { queryParams: { cv: 'true' } });
    }

    onSubmit(): void {
        if (!this.form.invalid) {
            this.alertService.loading(true);

            this.reCaptchaV3Service.execute(environment.recaptcha, 'activatecard', (token) => {
                // verify if card exists and is not active
                const subVK = this.cardService.verifyAndReturnKyc(this.cardholderid.value, this.cardNumber.value, token, this.cardEmail.value)
                    .pipe(finalize(() => subVK.unsubscribe()))
                    .subscribe(
                        (data: any) => this.cardAlreadyActive(data, token),
                        error => {
                            const { code = -1, error: errorMessage = {} } = error || {};

                            switch (code) {
                                case 403: // 'card already registered';
                                    this.alertService.loading(false);
                                    const { extraText = null } = errorMessage;

                                    if (extraText) {
                                        const domain = extraText.split('@');

                                        this.alertService.errorWithExtraMessage('card_already_registered', extraText.substring(0, 3) + '****@' + ((domain.length > 1) ? domain[1] : ''));
                                    } else {
                                        this.alertService.error('card_already_registered');
                                    }
                                    this.router.navigate(['/login']);
                                    break;
                                case 501: // 'activate card';
                                    const { alreadyRegistered = false } = errorMessage;

                                    this.activateCard(token, alreadyRegistered);
                                    break;
                                default:
                                    this.alertService.loading(false);
                                    this.alertService.error('card id or card number not exist');
                                    break;
                            }
                        }
                    );
            });
        }
    }

    showPopupPhoneConfirmationCode(cCode: string, alreadyRegistered: boolean): void {
        const dialogRef = this.dialog.open(PopupPhoneCodeComponent, {
            width: '420px',
            data: {
                cardholderid: this.cardholderid.value,
                phoneNumber: this.finalPhoneNumber,
                countryCode: cCode
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.finalPopup(alreadyRegistered);
            }
        });
    }

    finalPopup(alreadyRegistered: boolean): void {
        const dialogRef = this.dialog.open(PopupActivationCardComponent, {
            width: '420px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === 'goToRegister') {
                const obj = { alreadyRegistered: alreadyRegistered, email: this.cardEmail.value, typeKyc: ('' + 0), name: '', cardHolderId: this.cardholderid.value, cardNumber: this.cardNumber.value, phoneNumber: this.finalPhoneNumber };
                localStorage.setItem('tempObj', JSON.stringify(obj));

                this.router.navigate(['/createaccount'], { queryParams: { cv: 'true' } });
            }
        });
    }
}
