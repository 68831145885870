import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { generate_history_pdf } from '../../pdf/genereta_pdf';

export const MY_FORMATS2 = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import { OrderService } from '../../services/order.service';
import { TransferDataService } from '../../services/transferData.service';
import { AlertService } from '../../services/alert.service';

import { Order } from '../../models/order';

import * as moment from 'moment';
import { first, finalize } from 'rxjs/operators';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Translate } from 'src/app/locales/translate';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserService } from 'src/app/services/user.service';
import { Card } from 'src/app/models/card';
import { MatDialog } from '@angular/material/dialog';
import { PopupExportComponent } from 'src/app/modals/popup-export/popup-export.component';

const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS2 },
  ],
})
export class TransactionsComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  card_id: string;
  orders: Order[] = [];
  filterForm: FormGroup;
  filterVisible = true;
  exportPopup = false;
  ordersStatus = 1;
  ordersLastUpdated = '';

  constructor(
    private formBuilder: FormBuilder,
    private titleService: Title,
    private orderService: OrderService,
    private transferDataService: TransferDataService,
    private alertService: AlertService,
    private userService: UserService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loadFilterVisibility();

    const translate = new Translate();
    this.titleService.setTitle(translate.transform('history'));

    const endDate: Date = new Date();
    const startDate: Date = new Date(endDate.getFullYear(), endDate.getMonth() - 1, endDate.getDate())

    this.filterForm = this.formBuilder.group({
      startDate: [moment(startDate), [Validators.required]], // .format(MOMENT_DATE_FORMAT)
      endDate: [moment(endDate), [Validators.required]] // .format(MOMENT_DATE_FORMAT)
    });

    this.loadOrders();
    this.subscription = this.transferDataService.onDataUpdate().subscribe(modified => this.loadOrders());
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.loadFilterVisibility();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.keyCode === 27) {
      if (this.exportPopup) {
        this.exportPopup = false;
      } else if (this.filterVisible) {
        this.filterVisible = false;
      }
    }
  }

  get f() { return this.filterForm.controls; }

  loadFilterVisibility() {
    this.filterVisible = (window.innerWidth > 540);
  }

  loadOrders() {
    if (this.transferDataService.data && this.transferDataService.data.card) {
      this.card_id = this.transferDataService.data.card.id || '';
      this.ordersStatus = ('statusdownloadtranscations' in this.transferDataService.data.card) ? this.transferDataService.data.card.statusdownloadtranscations : 1;
      this.ordersLastUpdated = this.transferDataService.data.card.lastdownloadtransactions || '';
      this.downloadOrders();
    }
  }

  onFilterPress() {
    const startDate = moment(this.f.startDate.value);
    const endDate = moment(this.f.endDate.value);

    if (!startDate.isValid() || !endDate.isValid()) {
      this.alertService.error('invalid_form_dates');
    } else {
      this.downloadOrders();
    }
  }

  // -----------------
  // private functions
  // -----------------

  onExportPress(): void {
    const dialogRef = this.dialog.open(PopupExportComponent, {
      width: '270px',
    });

    dialogRef.afterClosed().subscribe(result => {
      switch (result) {
        case 'csv':
          this.exportAs('csv');
          break;
        case 'pdf':
          this.exportPdf();
          break;
        default:
          break;
      }
    });
  }

  downloadOrders() {
    const startDate = this.f.startDate.value.format(MOMENT_DATE_FORMAT);
    const endDate = this.f.endDate.value.format(MOMENT_DATE_FORMAT);

    this.alertService.loading(true);

    (
      (this.f.startDate.valid && this.f.endDate.valid)
        ? this.orderService.listByCardIdBtweenDates(this.card_id, startDate, endDate)
        : this.orderService.listByCardId(this.card_id)
    )
      .pipe(first())
      .pipe(finalize(() => {
        setTimeout(() => this.alertService.loading(false), 250);
        this.loadFilterVisibility();
      }))
      .subscribe(
        data => this.orders = (data || []).filter(o => {
          if (o.type >= 0 && o.type <= 5 && o.status == 2) {
            return;
          }
          return o;
        }),
        error => this.alertService.error(error.error)
      );
  }

  exportAs(type) {
    if (this.orders.length == 0) {
      return;
    }
    const startDate = this.f.startDate.value.format(MOMENT_DATE_FORMAT);
    const endDate = this.f.endDate.value.format(MOMENT_DATE_FORMAT);

    this.alertService.loading(true);

    (
      (this.f.startDate.valid && this.f.endDate.valid)
        ? this.orderService.listByCardIdBtweenDatesExport(this.card_id, startDate, endDate, type)
        : this.orderService.listByCardIdExport(this.card_id, type)
    )
      .pipe(first())
      .pipe(finalize(() => this.alertService.loading(false)))
      .subscribe(
        x => {
          // It is necessary to create a new blob object with mime-type explicitly set
          // otherwise only Chrome works like it should
          const newBlob = new Blob([x], {
            type: (type == 'csv') ? 'text/csv' : 'application/pdf'
          });

          // IE doesn't allow using a blob object directly as link href
          // instead it is necessary to use msSaveOrOpenBlob
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob);
            return;
          }

          // For other browsers: 
          // Create a link pointing to the ObjectURL containing the blob.
          const data = window.URL.createObjectURL(newBlob);

          var link = document.createElement('a');
          link.href = data;
          link.download = (type == 'csv') ? 'spark.csv' : 'application/pdf';
          // this is necessary as link.click() does not work on the latest firefox
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);

          this.exportPopup = false;
        },
        error => this.alertService.error(error.error)
      );
  }

  exportPdf() {
    const startDate = this.f.startDate.value.format(MOMENT_DATE_FORMAT);
    const endDate = this.f.endDate.value.format(MOMENT_DATE_FORMAT);
    const card = (this.transferDataService.data && this.transferDataService.data.card) ? this.transferDataService.data.card : new Card();

    generate_history_pdf(this.orders, this.userService.authenticated_user, card, startDate, endDate);
  }

}
