import { Component, OnInit, Input } from '@angular/core';
import { Card } from 'src/app/models/card';

@Component({
    selector: 'app-transfer-card-list',
    templateUrl: './transfer-card-list.component.html',
    styleUrls: ['./transfer-card-list.component.css']
})
export class TransferCardListComponent implements OnInit {
    @Input() cards: Card[];
    @Input() onCardSelectCallback: Function;
    selectedCards = {};

    constructor(){}

    ngOnInit(){}

    isCardSelected(i: number) {
        if (i in this.selectedCards) {
            return this.selectedCards[i] === true;
        }
        return false;
    }

    onCardSelect(i: number) {
        if (i in this.selectedCards) {
            if (this.selectedCards[i]) {
                delete this.selectedCards[i];
            } else {
                this.selectedCards[i] = true;
            }
        } else {
            this.selectedCards[i] = true;
        }
    }

    addCards() {
        if (this.onCardSelectCallback) {
            this.onCardSelectCallback(this.selectedCards);
        }
    }

    close() {
        if (this.onCardSelectCallback) {
            this.onCardSelectCallback({});
        }
    }
}